<template>
  <tr
    :class="{
      'job-details-info-block': true,
      'text-area-block': newLine
    }"
  >
    <td class="label-cell">
      <div class="label-container" :class="{ isSelect }">
        <div class="label-icon" v-if="icon">
          <v-icon :name="icon" scale="0.8" />
        </div>
        {{ label }}:
        <job-details-new-note v-if="dataType === 'notes' && !disabled" />
      </div>
    </td>
    <td v-if="dataType === 'tech-select'" class="text-cell">
      <el-select
        v-model="techValue"
        value-key="name"
        :multiple="true"
        class="no-border-select small"
        :disabled='disabled'
        :placeholder="placeholder"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.name"
          :value="item"
        >
        </el-option>
      </el-select>
    </td>
    <td v-else-if="dataType === 'bay-select'" class="text-cell">
      <el-select
        v-model="bayValue"
        value-key="name"
        class="no-border-select small"
        :disabled='disabled'
        :placeholder="placeholder"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.name"
          :value="item"
        >
        </el-option>
      </el-select>
    </td>
    <td v-else-if="dataType === 'phone'" class="text-cell">
      <a :href="`tel:${text}`">{{ text }}</a>
      <span
        v-if="hasAccessToTextMessaging && text !== '' && customerId && !disabled"
        @click="startTextMessagingChat()"
        class="pl-3"
      >
        <v-icon class="textMessagingButton" name="comment-dots" />
      </span>
    </td>
    <td v-else-if="dataType === 'address'" class="text-cell">
      <div class="address-row">
        <a
          :href="`https://maps.google.com/?q=${text.address.full_address}`"
          target="_blank"
          v-if="text.address"
        >
          <div v-if="text">{{ text.address.address }}</div>
          <div v-if="text">
            {{ text.address.city }}, {{ text.address.state }}
          </div>
        </a>
        <job-details-address-edit
          v-if='!disabled'
          v-model="value"
          @on-save="saveAddress"
          :shopId="shopId"
        />
      </div>
    </td>
    <td v-else-if="dataType === 'array'" class="text-cell">
      <div v-for="row in text" v-bind:key="row" v-if="row">{{ row }}</div>
    </td>
    <td v-else-if="dataType === 'date'" class="text-cell">
      <el-date-picker
        class="no-border-select small"
        v-model="dateValue"
        type="date"
        placeholder="Pick a day"
        :disabled='disabled'
        :clearable="false"
      />
    </td>
    <td v-else-if="dataType === 'time'" class="text-cell">
      <div class="time-range-block">
        <el-time-select
          v-model="startTimeValue"
          v-bind="timeSelectOptions"
          placeholder="Start"
          :disabled='disabled'
          :picker-options="{
            start: '07:00',
            step: '00:15',
            end: endTimeString || '20:00'
          }"
        />
        <el-time-select
          v-model="endTimeValue"
          :disabled='disabled'
          v-bind="timeSelectOptions"
          placeholder="End"
          :picker-options="{
            start: startTimeString || '07:00',
            step: '00:15',
            end: '20:00'
          }"
        />
      </div>
    </td>
    <td v-else-if="dataType === 'textarea'" class="text-cell">
      <div class="textarea-container">
        <b-form-textarea
          v-model="value"
          :rows="3"
          :max-rows="3"
          :disabled='disabled'
        />
        <div class="textarea-save" v-if="value !== text" @click="saveText">
          <v-icon name="save" />
        </div>
      </div>
    </td>
    <td v-else-if="dataType === 'checkbox'" class="text-cell">
      <el-switch
        :disabled='disabled'
        :value="text || 0"
        :active-value="1"
        :inactive-value="0"
        @change="updateCheckbox"
      />
    </td>
    <td v-else-if="dataType === 'install_context'" class="text-cell">
      <gb-tag-select
        :disabled='disabled'
        :value="text"
        :options="contextOptions"
        slot="title"
        @input="updateInstallContext"
        style="width:min-content;"
      />
    </td>
    <td v-else-if="dataType === 'tags'" class="text-cell">
      <job-details-tags :propJob='propJob' :disabled='disabled === true'/>
    </td>
    <td v-else-if="dataType === 'documents'" class="text-cell">
      <a
        v-for="document in text"
        :key="document.id"
        :href="document.url"
        target="_blank"
        class="document-tag"
      >
        <el-tag
          :color="document.tagColor"
          size="small"
          :style="{ color: getBlackOrWhite(document.tagColor) }"
          ><v-icon name="file" scale="0.5" />{{ document.tagName }}</el-tag
        >
      </a>
    </td>
    <td v-else-if="dataType === 'notes'" class="text-cell">
      <div class="comment" v-for="note in comments" :key="note.id">
        <div>
          <span class="username" :style="{ color: note.color || '#000' }"
            >{{ note.user_name }}: </span
          >{{ note.note_text }}
        </div>
        <div class="footer">
          <span>{{ noteDate(note.note_date) }}</span>
        </div>
      </div>
      <el-button v-if="text.length > 1" size="mini" @click="toggleShowAll">{{
        showAll ? 'Show less' : 'Show more'
      }}</el-button>
    </td>
    <td v-else-if="dataType === 'commission'" class="text-cell">
      <job-details-commission :input="text" :disabled='disabled' @update-tech-commission="updateTechCommission"/>
    </td>
    <td v-else-if="dataType === 'parts'">
      <div v-for="item in text" :key="item.part_number">
        <div v-if='item[0]'>{{item[0]}}</div>
        <div v-if='item[1]' class="t-text-xs t-italic">{{item[1]}}</div>
      </div>
    </td>
    <td v-else class="text-cell">
      <a v-if="label === 'Customer'" href="javascript:void(0)" @click="openConsumerDialog">{{ text }}</a>
      <span v-else>{{ text }}</span>
    </td>
  </tr>
</template>

<script>
import { prop, contains, take } from 'ramda'
import { parse } from 'date-fns'
import {
  formatToDb,
  formatTimeToDb,
  parseTimeMinutes,
  parseTimeDate
} from '@/scripts/helpers/date.helpers'
import JobDetailsCommission from './JobDetailsCommission'
import { isValidTime, isInvalidDate } from '@/scripts/helpers/job.helpers'
import { getBlackOrWhite } from '@/scripts/helpers/utilities'
import { call } from 'vuex-pathify'
import actions from '@/store/modules/scheduler_v3/types'
import JobDetailsAddressEdit from './JobDetailsAddressEdit'
import JobDetailsTags from './JobDetailsTags'
import JobDetailsNewNote from './JobDetailsNewNote'
import moment from 'moment'

export default {
  data() {
    return {
      editing: false,
      timeSelectOptions: {
        class: 'no-border-select small',
        clearable: false
      },
      showAll: false,
      value: '',
      contextOptions: [
        {
          label: 'In Shop',
          value: 'inShop',
          color: 'purple'
        },
        {
          label: 'Mobile',
          value: 'mobile',
          color: 'teal'
        },
        {
          label: 'Delivery',
          value: 'delivery',
          color: 'brown'
        },
        {
          label: 'Will Call',
          value: 'willCall',
          color: 'blue'
        }
      ]
    }
  },
  props: [
    'icon',
    'text',
    'label',
    'dataType',
    'placeholder',
    'selectKey',
    'options',
    'updateKey',
    'date',
    'start',
    'end',
    'newLine',
    'actionIcon',
    'action',
    'customerId',
    'customerName',
    'shopId',
    'disabled',
    'propJob'
  ],
  computed: {
    isSelect() {
      return contains(this.dataType)(['select', 'date', 'time'])
    },
    techValue: {
      get() {
        return this.text
      },
      set(value) {
        this.$emit('update-tech', value)
      }
    },
    bayValue: {
      get() {
        return prop(this.selectKey)(this.text)
      },
      set(value) {
        this.updateJobDetailsValues({
          bay_id: value.id
        })
      }
    },
    dateValue: {
      get() {
        return isInvalidDate(this.text) ? null : parse(this.text)
      },
      set(value) {
        this.updateJobValue(formatToDb(value))
      }
    },
    startTimeString() {
      return isValidTime(this.start)
        ? parseTimeMinutes(this.start, this.date)
        : null
    },
    endTimeString() {
      return isValidTime(this.end)
        ? parseTimeMinutes(this.end, this.date)
        : null
    },
    startTimeValue: {
      get() {
        return this.startTimeString
      },
      set(value) {
        this.updateTime(value, 'scheduled_time_start')
      }
    },
    endTimeValue: {
      get() {
        return this.endTimeString
      },
      set(value) {
        this.updateTime(value, 'scheduled_time_end')
      }
    },
    comments() {
      return this.showAll ? this.text : take(1)(this.text)
    },
    hasAccessToTextMessaging() {
      return this.$store.state.user.has_access_to_twilio_phone_numbers
    }
  },
  methods: {
    updateJobDetailsValue: call(actions.updateJobDetailsValue),
    updateJobDetailsValues: call(actions.updateJobDetailsValues),
    updateTime(value, key) {
      this.updateJobDetailsValue({
        key,
        value: formatTimeToDb(parseTimeDate(value, this.date)),
        id: this.getJobDetailsId
      })
    },
    setEditing(value) {
      this.editing = value
    },
    updateJobValue(value) {
      if (this.updateKey === 'scheduled_date') {
        this.updateJobDetailsValues({
          scheduled_date: value,
          install_date: value
        })
      } else {
        this.updateJobDetailsValue({
          key: this.updateKey,
          value,
          id: this.getJobDetailsId
        })
      }
      this.setEditing(false)
    },
    updateCheckbox(value) {
      if (this.updateKey === 'customer_reschedule') {
        this.updateJobDetailsValues({
          customer_reschedule: value,
          customer_cancel: false
        })
      } else if (this.updateKey === 'customer_cancel') {
        this.updateJobDetailsValues({
          customer_reschedule: false,
          customer_cancel: value
        })
      } else {
        this.updateJobValue(value)
      }
    },
    updateInstallContext(value) {
      this.updateJobValue(value)
    },
    getBlackOrWhite,
    toggleShowAll() {
      this.showAll = !this.showAll
    },
    setText() {
      if (this.dataType === 'textarea') {
        this.value = this.text
      }
    },
    setAddress() {
      if (this.dataType === 'address') {
        this.value = Object.assign({}, this.text)
      }
    },
    saveText() {
      this.updateJobDetailsValue({
        key: this.updateKey,
        value: this.value,
        id: this.getJobDetailsId
      })
    },
    startTextMessagingChat() {
      this.$root.$emit('startTextMessagingChat', {
        model: 'customer',
        id: this.customerId,
        name: this.customerName,
        phoneNumber: this.text
      })
    },
    saveAddress() {
      this.updateJobDetailsValues({
        install_g_address: this.value.address,
        install_location_id: this.value.install_location_id
      })
    },
    noteDate(d) {
      if (!d || d === 'Date Missing') return null
      return moment(d, 'YYYY-DD-MM hh:mm a').format('MMM D, YYYY h:mm a')
    },
    openConsumerDialog() {
      this.$emit('openConsumerDialog')
    },
    updateTechCommission(val) {
      this.$emit('update-tech-commission', val)
    }
  },
  components: {
    JobDetailsCommission,
    JobDetailsAddressEdit,
    JobDetailsTags,
    JobDetailsNewNote
  },
  watch: {
    text(text, old) {
      if (text !== old) this.setText()
    }
  },
  mounted() {
    this.setText()
    this.setAddress()
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/JobDetailsInfoBlock';
</style>

<style lang="scss">
.time-picker {
  .mx-calendar-header {
    display: none;
  }
}

.hide-icon {
  .mx-input-append {
    display: none;
  }
}
</style>
