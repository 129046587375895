var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "t-flex t-py-2" }, [
    _c(
      "div",
      { staticClass: "t-pt-1" },
      [
        _c(
          "el-badge",
          {
            staticClass: "commission-row-badge",
            attrs: {
              type: "info",
              hidden: _vm.commissionType === "flat" || !_vm.total,
              value: "$" + _vm.total
            }
          },
          [_c("span", [_vm._v(_vm._s(_vm.vendor.name))])]
        )
      ],
      1
    ),
    _c("div", { staticClass: "t-flex-grow t-min-w-[2rem]" }),
    _c(
      "div",
      {},
      [
        _c(
          "input-with-label",
          {
            staticClass: "commission-type-input multi-commission-row-input",
            attrs: { type: "money", autoFocus: true, lazy: "" },
            model: {
              value: _vm.commission,
              callback: function($$v) {
                _vm.commission = $$v
              },
              expression: "commission"
            }
          },
          [
            _c(
              "el-select",
              {
                staticClass: "commission-type-select t-w-24",
                attrs: { slot: "append", size: "mini", placeholder: "" },
                slot: "append",
                model: {
                  value: _vm.commissionType,
                  callback: function($$v) {
                    _vm.commissionType = $$v
                  },
                  expression: "commissionType"
                }
              },
              [
                _c("el-option", { attrs: { value: "flat", label: "$" } }),
                _c("el-option", {
                  attrs: { value: "percent", label: "% Total" }
                }),
                _c("el-option", {
                  attrs: { value: "percent_subtotal", label: "% Subtotal" }
                }),
                _c("el-option", {
                  attrs: { value: "percent_margin", label: "% Margin" }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }