<template>
  <div class="scheduler-tech-header">
    <div class="top-row">
      <div class="indicator" v-if="route && route.techside_username">
        <tech-location-indicator
          :username="route.techside_username"
          :userdata="route.ts_user"
          :color="route.color"
        />
      </div>
      <template v-if="route.techs">
        <div class="header-text">
          {{ getTitle }}
        </div>
        <div class="expand-icon" @click="expanded = !expanded">
          <v-icon :name="expanded ? 'caret-up' : 'caret-down'" />
        </div>
      </template>
      <template v-else>
        <div class="header-text">
          <div>
            {{ getTitle }}
          </div>
          <div class="t-text-gray-400 t-text-xs">
            {{ getOrganization }}
          </div>
        </div>
        <scheduler-tech-header-menu
          v-if="route.tech && route.tech.id"
          :tech="route.tech"
          :route="route"
          @open-tech-dialog="onViewVendor"
          @open-pdf="pdfDialog = true"
          @open-bulk-reschedule-dialog="bulkRescheduleDialog = true"
          @open-bulk-reassign-dialog="bulkReassignDialog = true"
        />
      </template>
    </div>
    <template v-if="expanded && route.techs">
      <div class="divider" />

      <div class="top-row" v-for="tech in route.techs" :key="tech.id">
        <v-icon
          name="circle"
          :color="tech.color"
          scale="0.5"
          class="single-tech-icon"
        />
        <div class="header-text">
          {{ namecase(tech.name) }}
        </div>
        <scheduler-tech-header-menu
          v-if="tech && tech.id"
          :tech="tech"
          :route="route"
          @open-pdf="pdfDialog = true"
        />
      </div>
    </template>
    <scheduler-tech-totals :route="route" v-if="showTotals" />
    <scheduler-tech-time-slots :route="route" v-if="showAvailability" />
    <scheduler-pdf-dialog
      :job_ids="jobIds"
      v-model="pdfDialog"
      :date="date"
      :tech="route.tech.name"
      v-if="route.tech"
      :shop="route.shop || null"
    />
    <scheduler-bulk-reschedule-dialog
      v-model="bulkRescheduleDialog"
      :date="date"
      :job_ids="jobIds"
      :tech="route.tech"
    />
    <scheduler-bulk-reassign-dialog
      :tech="route.tech"
      :visible="bulkReassignDialog"
      :jobIds="jobIds"
      @close="bulkReassignDialog = false"
    />
  </div>
</template>

<script>
import * as R from 'ramda'
import namecase from 'namecase'
import { mapActions } from 'pinia'
import { sync, get } from 'vuex-pathify'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'
import TechLocationIndicator from './TechLocationIndicator'
import SchedulerTechHeaderMenu from './SchedulerTechHeaderMenu'
import SchedulerPdfDialog from './SchedulerPDFDialog'
import SchedulerTechTotals from '../SchedulerTechTotals'
import SchedulerTechTimeSlots from '../SchedulerTechTimeSlots'
import SchedulerBulkRescheduleDialog from './SchedulerBulkRescheduleDialog'
import SchedulerBulkReassignDialog from './SchedulerBulkReassignDialog'

export default {
  components: {
    TechLocationIndicator,
    SchedulerTechHeaderMenu,
    SchedulerTechTotals,
    SchedulerTechTimeSlots,
    SchedulerPdfDialog,
    SchedulerBulkRescheduleDialog,
    SchedulerBulkReassignDialog
  },
  data() {
    return {
      expanded: false,
      pdfDialog: false,
      bulkRescheduleDialog: false,
      bulkReassignDialog: false,
    }
  },
  props: ['route'],
  computed: {
    showTotals: get('sch_v3/filters@showTotals'),
    date: get('sch_v3/filters@date'),
    showAvailability: get('sch_v3/filters@showAvailability'),
    getTitle() {
      return R.cond([
        [
          R.path(['route', 'tech', 'name']),
          R.pipe(R.path(['route', 'tech', 'name']), namecase)
        ],
        [
          R.path(['route', 'techside_username']),
          R.path(['route', 'techside_username'])
        ],
        [R.T, () => 'Unassigned']
      ])(this)
    },
    getOrganization() {
      if (this?.route?.organization?.name) {
        return this.route.organization.name
      }

      return ''
    },
    jobIds() {
      return R.pipe(
        R.prop('route_entries'),
        R.map(R.path(['job', 'id'])),
        R.reject(R.isNil)
      )(this.route)
    },
    route_data: sync('sch_v3/data@:route_id')
  },
  methods: {
    ...mapActions(useVendorStore, { viewVendor: 'openView' }),
    namecase(v) {
      return namecase(v)
    },
    onViewVendor(data) {
      this.viewVendor(data.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/SchedulerTechHeader.scss";
</style>
