// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".multi-commission-row-input .el-input .el-input__inner {\n  width: 100px;\n}\n.commission-row-badge {\n  z-index: 1;\n}\n.comm-suffix {\n  line-height: 30px;\n}", "",{"version":3,"sources":["/app/src/components/JobPageV2/helpers/JobAssignments/CommissionRow.vue"],"names":[],"mappings":"AAgFI;EACE,YAAA;AApEN;AAyEA;EACE,UAAA;AAtEF;AAyEA;EACE,iBAAA;AAtEF","file":"CommissionRow.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.multi-commission-row-input .el-input .el-input__inner {\n  width: 100px;\n}\n\n.commission-row-badge {\n  z-index: 1;\n}\n\n.comm-suffix {\n  line-height: 30px;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
