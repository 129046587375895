// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "@media screen and (max-width: 503px) {\n.qbo-mapping-popper {\n    max-width: 100vw !important;\n    min-width: 100vw !important;\n}\n}", "",{"version":3,"sources":["/app/src/components/modals/QuickBooksCommissionValidationDialog/QuickBooksVendorSelect.vue"],"names":[],"mappings":"AAmGE;AADF;IAEI,2BAAA;IACA,2BAAA;AAtFF;AACF","file":"QuickBooksVendorSelect.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n@media screen and (max-width: 503px) {\n  .qbo-mapping-popper {\n    max-width: 100vw !important;\n    min-width: 100vw !important;\n  }\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
