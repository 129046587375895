import { make } from 'vuex-pathify'
import RightPart from '@/scripts/objects/right_part'

const state = {
  isOpen: false,
  vin: null,
  kind: 'Windshield',
  loading: false,
  details: null,
  detailsLoading: false,
  glassTypes: [],
  glassOptions: [],
  requestDetailsIsOpen: false
}

const mutations = make.mutations(state)

const actions = {
  open ({ commit }, { vin }) {
    commit('SET_VIN', vin)
    commit('SET_IS_OPEN', true)
  },

  close ({ commit }) {
    commit('SET_IS_OPEN', false)
  },

  async getDetails ({ commit }, params) {
    commit('SET_DETAILS', null)
    commit('SET_DETAILS_LOADING', true)
    const res = await RightPart.getDetails(params)
    if (res) {
      commit('SET_DETAILS', res)
    }
    commit('SET_DETAILS_LOADING', false)
  },

  async search ({ commit, state }, form) {
    const res = await RightPart.search({
      ...form,
      vin: state.vin
    })
  },

  async getGlassTypes ({ commit }) {
    const types = await RightPart.getGlassTypes()
    commit('SET_GLASS_TYPES', types)
  },

  async getGlassOptions ({ commit }) {
    const allOptions = await RightPart.getGlassOptions()
    const options = allOptions.filter(i => {
      return i.id !== 8 && i.id !== 15 && i.id !== 16
    })
    commit('SET_GLASS_OPTIONS', options)
  },

  async openRequestDetails ({ commit }, id) {
    commit('SET_DETAILS_LOADING', true)
    commit('SET_REQUEST_DETAILS_IS_OPEN', true)
    const res = await RightPart.getRequestDetails(id)
    if (res !== '') {
      commit('SET_DETAILS', res)
    }
    commit('SET_DETAILS_LOADING', false)
  },

  closeRequestDetails ({ commit }) {
    commit('SET_REQUEST_DETAILS_IS_OPEN', false)
    commit('SET_DETAILS', null)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
