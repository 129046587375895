<template>
  <div class="comments">
    <div v-for="(event, index) in data" :key="index" class="mb-3">
      <el-row :gutter="16" type="flex" align="middle">
        <el-col :span="!event.gb_user_id ? 2 : 1" justify="center">
          <el-avatar
            v-if="!event.gb_user_id"
            :src="logoImg"
            :size="40"
          ></el-avatar>
        </el-col>
        <el-col :span="20">
          <el-card class="mb-1">
            <div
              v-if="event.payload.contentType.includes('html')"
              v-html="content(event.gb_user_id, event.payload.content)"
            ></div>
            <div v-else>{{ event.payload.content }}</div>
            <div
              class="job-documents"
              v-for="(attachment, index) in event.support_ticket_attachments"
              :key="index"
            >
              <div class="document-list-item">
                <document-tag
                  @click.native="
                    openDocPreview({
                      ...attachment,
                      document_name: attachment.name,
                      document_type: 'support_event'
                    })
                  "
                  :doc="{ ...doc, tag: attachment.name }"
                ></document-tag>
              </div>
            </div>
          </el-card>
          <div
            :class="{
              subtitle: true,
              is_left: event.gb_user_id,
              is_right: !event.gb_user_id
            }"
          >
            {{ title(event) }} {{ date(event.payload.commentedTime) }}
          </div>
        </el-col>
        <el-col :span="event.gb_user_id ? 2 : 1" class="user-icon">
          <el-avatar
            v-if="
              event.gb_user_id &&
                event.gb_user_id == $store.state.user.id &&
                avatarUrl
            "
            :src="avatarUrl"
            class="float-right"
          />
          <i
            v-if="
              event.gb_user_id &&
                (event.gb_user_id != $store.state.user.id || !avatarUrl)
            "
            class="material-icons md-account_circle t-text-white t-px-3 t-cursor-pointer t-text-3xl"
          />
        </el-col>
      </el-row>
    </div>
    <file-preview-dialog
      v-model="previewDialog"
      :doc="preview"
      :noDelete="true"
      :gcp="true"
      :dateFunc="dateFunc"
    />
  </div>
</template>

<script>
import moment from 'moment'
import FilePreviewDialog from '@/components/modals/FilePreviewDialog/Index'
import DocumentTag from '@/components/JobPageV2/JobPageSideDrawer/JobDocuments/DocumentTag'
import { documentsConfig } from '@/constants/documents_v2.config'
import { fromDate } from '@/scripts/helpers/date.helpers'

export default {
  props: ['data'],
  components: {
    FilePreviewDialog,
    DocumentTag
  },
  data() {
    return {
      logoImg: require('@/components/inc/SideBarV2/img/glassbiller_logo_small_v2.svg'),
      previewDialog: false,
      preview: null,
      avatarUrl: null
    }
  },
  computed: {
    doc() {
      return documentsConfig.support_event
    },
    dateFunc() {
      return fromDate
    }
  },
  async created() {
    const url = `https://storage.googleapis.com/gb-auth-files/glassbiller-staging%2Fgb%7C${this.$store.state.user.id}.png`
    const isValid = await this.isValidImageUrl(url)
    this.avatarUrl = isValid ? url : null
  },
  methods: {
    async isValidImageUrl(url) {
      return new Promise(resolve => {
        const img = new Image()
        img.onload = () => resolve(true)
        img.onerror = () => resolve(false)
        img.src = url
      })
    },
    date(d) {
      return moment(d).format('MM/DD/YY hh:mm a')
    },
    title(t) {
      if (t.event_type === 'Ticket_Comment_Add') return 'comment added'
      if (t.payload?.channel === 'EMAIL') return 'email sent'
      if (t.payload?.channel === 'FEEDBACK') return 'user provided feedback'
    },
    content(u, c) {
      if (u && u === this.$store.state.user.id) {
        const removeLength = (this.$store.state.user.username + ':').length
        return c.substring(removeLength)
      }
      return c
    },
    openDocPreview(doc) {
      this.preview = doc
      this.previewDialog = true
    }
  },
  watch: {
    previewDialog(v) {
      if (!v) {
        this.preview = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.comments {
  .el-card {
    border-radius: 14px;
  }
  .subtitle {
    line-height: 9px;
    font-style: italic;
    color: darkgrey;
    &.is_right {
      margin-right: 20px;
      float: right;
    }
    &.is_left {
      margin-left: 20px;
    }
  }

  .user-icon {
    .el-avatar {
      height: 40px;
      width: 40px;
    }
    i {
      font-size: 40px;
    }
  }
}
.job-documents {
  display: flex;
  flex-wrap: wrap;
}
.document-list-item {
  margin-bottom: $padding-xss;
  margin-right: $padding-xss;
  cursor: pointer;
  &.expanded {
    padding: $padding-sm $padding-md;
    border: 1px solid $grayscale-3;
    border-radius: 3px;
    font-size: 14px;
    color: $grayscale-8;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 0;
  }
}
</style>
