var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    {
      class: {
        "job-details-info-block": true,
        "text-area-block": _vm.newLine
      }
    },
    [
      _c("td", { staticClass: "label-cell" }, [
        _c(
          "div",
          { staticClass: "label-container", class: { isSelect: _vm.isSelect } },
          [
            _vm.icon
              ? _c(
                  "div",
                  { staticClass: "label-icon" },
                  [_c("v-icon", { attrs: { name: _vm.icon, scale: "0.8" } })],
                  1
                )
              : _vm._e(),
            _vm._v(" " + _vm._s(_vm.label) + ": "),
            _vm.dataType === "notes" && !_vm.disabled
              ? _c("job-details-new-note")
              : _vm._e()
          ],
          1
        )
      ]),
      _vm.dataType === "tech-select"
        ? _c(
            "td",
            { staticClass: "text-cell" },
            [
              _c(
                "el-select",
                {
                  staticClass: "no-border-select small",
                  attrs: {
                    "value-key": "name",
                    multiple: true,
                    disabled: _vm.disabled,
                    placeholder: _vm.placeholder
                  },
                  model: {
                    value: _vm.techValue,
                    callback: function($$v) {
                      _vm.techValue = $$v
                    },
                    expression: "techValue"
                  }
                },
                _vm._l(_vm.options, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.name, value: item }
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm.dataType === "bay-select"
        ? _c(
            "td",
            { staticClass: "text-cell" },
            [
              _c(
                "el-select",
                {
                  staticClass: "no-border-select small",
                  attrs: {
                    "value-key": "name",
                    disabled: _vm.disabled,
                    placeholder: _vm.placeholder
                  },
                  model: {
                    value: _vm.bayValue,
                    callback: function($$v) {
                      _vm.bayValue = $$v
                    },
                    expression: "bayValue"
                  }
                },
                _vm._l(_vm.options, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.name, value: item }
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm.dataType === "phone"
        ? _c("td", { staticClass: "text-cell" }, [
            _c("a", { attrs: { href: "tel:" + _vm.text } }, [
              _vm._v(_vm._s(_vm.text))
            ]),
            _vm.hasAccessToTextMessaging &&
            _vm.text !== "" &&
            _vm.customerId &&
            !_vm.disabled
              ? _c(
                  "span",
                  {
                    staticClass: "pl-3",
                    on: {
                      click: function($event) {
                        return _vm.startTextMessagingChat()
                      }
                    }
                  },
                  [
                    _c("v-icon", {
                      staticClass: "textMessagingButton",
                      attrs: { name: "comment-dots" }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm.dataType === "address"
        ? _c("td", { staticClass: "text-cell" }, [
            _c(
              "div",
              { staticClass: "address-row" },
              [
                _vm.text.address
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://maps.google.com/?q=" +
                            _vm.text.address.full_address,
                          target: "_blank"
                        }
                      },
                      [
                        _vm.text
                          ? _c("div", [
                              _vm._v(_vm._s(_vm.text.address.address))
                            ])
                          : _vm._e(),
                        _vm.text
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.text.address.city) +
                                  ", " +
                                  _vm._s(_vm.text.address.state) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  : _vm._e(),
                !_vm.disabled
                  ? _c("job-details-address-edit", {
                      attrs: { shopId: _vm.shopId },
                      on: { "on-save": _vm.saveAddress },
                      model: {
                        value: _vm.value,
                        callback: function($$v) {
                          _vm.value = $$v
                        },
                        expression: "value"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm.dataType === "array"
        ? _c(
            "td",
            { staticClass: "text-cell" },
            _vm._l(_vm.text, function(row) {
              return row
                ? _c("div", { key: row }, [_vm._v(_vm._s(row))])
                : _vm._e()
            }),
            0
          )
        : _vm.dataType === "date"
        ? _c(
            "td",
            { staticClass: "text-cell" },
            [
              _c("el-date-picker", {
                staticClass: "no-border-select small",
                attrs: {
                  type: "date",
                  placeholder: "Pick a day",
                  disabled: _vm.disabled,
                  clearable: false
                },
                model: {
                  value: _vm.dateValue,
                  callback: function($$v) {
                    _vm.dateValue = $$v
                  },
                  expression: "dateValue"
                }
              })
            ],
            1
          )
        : _vm.dataType === "time"
        ? _c("td", { staticClass: "text-cell" }, [
            _c(
              "div",
              { staticClass: "time-range-block" },
              [
                _c(
                  "el-time-select",
                  _vm._b(
                    {
                      attrs: {
                        placeholder: "Start",
                        disabled: _vm.disabled,
                        "picker-options": {
                          start: "07:00",
                          step: "00:15",
                          end: _vm.endTimeString || "20:00"
                        }
                      },
                      model: {
                        value: _vm.startTimeValue,
                        callback: function($$v) {
                          _vm.startTimeValue = $$v
                        },
                        expression: "startTimeValue"
                      }
                    },
                    "el-time-select",
                    _vm.timeSelectOptions,
                    false
                  )
                ),
                _c(
                  "el-time-select",
                  _vm._b(
                    {
                      attrs: {
                        disabled: _vm.disabled,
                        placeholder: "End",
                        "picker-options": {
                          start: _vm.startTimeString || "07:00",
                          step: "00:15",
                          end: "20:00"
                        }
                      },
                      model: {
                        value: _vm.endTimeValue,
                        callback: function($$v) {
                          _vm.endTimeValue = $$v
                        },
                        expression: "endTimeValue"
                      }
                    },
                    "el-time-select",
                    _vm.timeSelectOptions,
                    false
                  )
                )
              ],
              1
            )
          ])
        : _vm.dataType === "textarea"
        ? _c("td", { staticClass: "text-cell" }, [
            _c(
              "div",
              { staticClass: "textarea-container" },
              [
                _c("b-form-textarea", {
                  attrs: { rows: 3, "max-rows": 3, disabled: _vm.disabled },
                  model: {
                    value: _vm.value,
                    callback: function($$v) {
                      _vm.value = $$v
                    },
                    expression: "value"
                  }
                }),
                _vm.value !== _vm.text
                  ? _c(
                      "div",
                      {
                        staticClass: "textarea-save",
                        on: { click: _vm.saveText }
                      },
                      [_c("v-icon", { attrs: { name: "save" } })],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm.dataType === "checkbox"
        ? _c(
            "td",
            { staticClass: "text-cell" },
            [
              _c("el-switch", {
                attrs: {
                  disabled: _vm.disabled,
                  value: _vm.text || 0,
                  "active-value": 1,
                  "inactive-value": 0
                },
                on: { change: _vm.updateCheckbox }
              })
            ],
            1
          )
        : _vm.dataType === "install_context"
        ? _c(
            "td",
            { staticClass: "text-cell" },
            [
              _c("gb-tag-select", {
                staticStyle: { width: "min-content" },
                attrs: {
                  slot: "title",
                  disabled: _vm.disabled,
                  value: _vm.text,
                  options: _vm.contextOptions
                },
                on: { input: _vm.updateInstallContext },
                slot: "title"
              })
            ],
            1
          )
        : _vm.dataType === "tags"
        ? _c(
            "td",
            { staticClass: "text-cell" },
            [
              _c("job-details-tags", {
                attrs: { propJob: _vm.propJob, disabled: _vm.disabled === true }
              })
            ],
            1
          )
        : _vm.dataType === "documents"
        ? _c(
            "td",
            { staticClass: "text-cell" },
            _vm._l(_vm.text, function(document) {
              return _c(
                "a",
                {
                  key: document.id,
                  staticClass: "document-tag",
                  attrs: { href: document.url, target: "_blank" }
                },
                [
                  _c(
                    "el-tag",
                    {
                      style: { color: _vm.getBlackOrWhite(document.tagColor) },
                      attrs: { color: document.tagColor, size: "small" }
                    },
                    [
                      _c("v-icon", { attrs: { name: "file", scale: "0.5" } }),
                      _vm._v(_vm._s(document.tagName))
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        : _vm.dataType === "notes"
        ? _c(
            "td",
            { staticClass: "text-cell" },
            [
              _vm._l(_vm.comments, function(note) {
                return _c("div", { key: note.id, staticClass: "comment" }, [
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticClass: "username",
                        style: { color: note.color || "#000" }
                      },
                      [_vm._v(_vm._s(note.user_name) + ": ")]
                    ),
                    _vm._v(_vm._s(note.note_text) + " ")
                  ]),
                  _c("div", { staticClass: "footer" }, [
                    _c("span", [_vm._v(_vm._s(_vm.noteDate(note.note_date)))])
                  ])
                ])
              }),
              _vm.text.length > 1
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: { click: _vm.toggleShowAll }
                    },
                    [_vm._v(_vm._s(_vm.showAll ? "Show less" : "Show more"))]
                  )
                : _vm._e()
            ],
            2
          )
        : _vm.dataType === "commission"
        ? _c(
            "td",
            { staticClass: "text-cell" },
            [
              _c("job-details-commission", {
                attrs: { input: _vm.text, disabled: _vm.disabled },
                on: { "update-tech-commission": _vm.updateTechCommission }
              })
            ],
            1
          )
        : _vm.dataType === "parts"
        ? _c(
            "td",
            _vm._l(_vm.text, function(item) {
              return _c("div", { key: item.part_number }, [
                item[0] ? _c("div", [_vm._v(_vm._s(item[0]))]) : _vm._e(),
                item[1]
                  ? _c("div", { staticClass: "t-text-xs t-italic" }, [
                      _vm._v(_vm._s(item[1]))
                    ])
                  : _vm._e()
              ])
            }),
            0
          )
        : _c("td", { staticClass: "text-cell" }, [
            _vm.label === "Customer"
              ? _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: { click: _vm.openConsumerDialog }
                  },
                  [_vm._v(_vm._s(_vm.text))]
                )
              : _c("span", [_vm._v(_vm._s(_vm.text))])
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }