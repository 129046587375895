import Vue from 'vue'
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  split
} from '@apollo/client/core'
import VueApollo from '@vue/apollo-option'
import { Message } from 'element-ui'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import cfg from '@/config'

import { onError } from 'apollo-link-error'

import { cleanTypenameLink } from '@/scripts/helpers/apollo.helpers'
import typePolicies from './type-policies'

Vue.use(VueApollo)

export const cache = new InMemoryCache({
  addTypename: true,
  typePolicies
})

const httpLink = createUploadLink({
  uri: `${cfg.uri}/apollo/job-details`
})

const wsLink = new WebSocketLink({
  uri: `${cfg.wsUri}/apollo/job-details`,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      const auth = Vue.prototype.$auth

      return {
        user: auth.getIdentity()?.name
      }
    }
  }
})

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

const authLink = setContext(async (_, { headers }) => {
  const auth = Vue.prototype.$auth

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'Visitor-Fingerprint-Id': auth.getVisitorFingerprintId(),
      authorization: auth.getAuthorizationHeader()
    }
  }
})

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      Message.error(`${operation.operationName}: ${error.message}`)
    })
  } else if (networkError) {
    Message.error(`${operation.operationName}: ${networkError.message}`)
  }
})

const apolloClient = new ApolloClient({
  link: ApolloLink.from([cleanTypenameLink, authLink, errorLink, link]),
  cache,
  resolvers: {
    csr: (root, _, { client }) => {}
  }
})

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

export const generateIdQuery = query => id => ({
  query,
  variables: { id: +id },
  skip: !id
})

export default apolloClient
