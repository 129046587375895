<template>
  <transition name="slide">
    <div class="scheduler-job-details" v-show="jobDetails">
      <a
        size="mini"
        :style="{
          height: '12px',
          'font-size': '12px',
          'padding-left': '14px',
          cursor: 'pointer',
        }"
        @click="saveComplete"
      >
        Save as {{ jobCompleteStatus }}
      </a>
      <div class="header">
        <h4>
          <span>Job Details </span>
          <a v-if="jobDetails" target="_blank" :href="`/jobs/${job.id}`">{{
            orderNumber
          }}</a>
        </h4>
        <div @click="closeWindow">
          <v-icon name="times" scale="0.8" />
        </div>
      </div>
      <div class="content" v-if="jobDetails">
        <table v-for="(rows, index) in infoRows" v-bind:key="index">
          <tbody>
            <tr v-if="rows === 'editAddress'">
              <td v-if="editAddress">
                <places-autocomplete
                  class="autocomplete"
                  :initialValue="addressString"
                  :full="true"
                  @place-selected="placeSelected"
                />
              </td>
            </tr>
            <job-details-info-block
              v-else
              v-for="row in rows"
              v-bind:key="row.label"
              v-bind="row"
              @edit-address="editAddress = !editAddress"
              @openConsumerDialog="openConsumerDialog"
              :shopId="jobDetails.shop_id"
              @update-tech="updateTech"
              @update-tech-commission="updateTechCommission"
            />
          </tbody>
        </table>
        <job-confirmation-details
          v-if="job"
          :details="job.getConfirmationDetails()"
          :job="{...job, vehicle: job.job_vehicle}"
        />
        <consumer-dialog
          v-model="consumerDialogOpen"
          :consumer="consumer"
          @on-save="onSaveConsumer"
        />
      </div>
      <div class="floating-button" v-if="job.techside_sent">
        <el-badge type="success" value="TechSIDE" class="techside-badge">
          <el-button
            @click="completeJob"
            :type="job.techside_job_completed ? 'success' : 'default'"
            size="small"
          >
            <v-icon
              :name="completing ? 'spinner' : 'check'"
              :spin="completing"
              scale="0.6"
            />
            {{ job.techside_job_completed ? "Completed" : "Complete" }}
          </el-button>
        </el-badge>
      </div>
    </div>
  </transition>
</template>

<script>
import JobDetailsInfoBlock from './helpers/JobDetailsInfoBlock'
import { JobWrapperV2 } from '@/scripts/helpers/job.helpers'
import PlacesAutocomplete from './helpers/PlacesAutocomplete'
import { get, sync, call } from 'vuex-pathify'
import * as R from 'ramda'
import JobConfirmationDetails from './JobConfirmationDetails'

import actions from '@/store/modules/scheduler_v3/types'
import JobDetailsItemsMixin from './helpers/JobDetailsItemsMixin'

import { UtilityMixin } from '@/scripts/helpers/utilities'
import { formatToDb } from '@/scripts/helpers/date.helpers'
import { removeDisabled } from '@/scripts/helpers/scheduler_v3.helpers.js'
import Job from '@/scripts/objects/job'

import ConsumerDialog from '../modals/ConsumerDialog/Index.vue'
import { processTech } from './helpers/gb-vendor.helper'

export default {
  components: {
    JobDetailsInfoBlock,
    PlacesAutocomplete,
    JobConfirmationDetails,
    ConsumerDialog
  },
  mixins: [JobDetailsItemsMixin, UtilityMixin],
  data() {
    return {
      completing: false,
      editAddress: false,
      consumer: {},
      consumerDialogOpen: false
    }
  },
  computed: {
    jobDetails: sync('sch_v3/jobDetails'),
    techs: get('sch_v3/techs'),
    bays: get('sch_v3/bays'),
    job() {
      return new JobWrapperV2(this.jobDetails)
    },
    shopTechs() {
      return removeDisabled(this.job.gb_tech_id)(this.orgTechs(this.jobDetails?.organization_id))
    },
    shopBays() {
      return [
        { name: 'UNASSIGNED', id: '0' },
        ...this.bays.filter(R.propEq('shop_id', this.job.shop_id))
      ]
    },
    orderNumber() {
      return `${this.job.shop_id}-${this.job.id}`
    },
    addressString() {
      const addr = this.job.getAddress()
      return addr ? `${addr[0]}, ${addr[1]}` : ''
    },
    jobCompleteStatus() {
      const readableCompleteStatusObj = {
        workOrder: 'Invoice',
        warranty: 'Warranty Complete',
        recal: 'Recal Complete',
        callBack: 'Call Back Complete',
        rAndI: 'R & I Complete',
        tint: 'Tint Complete',
        pickUp: 'Pick Up Complete',
        dropOff: 'Drop Off Complete'
      }
      return readableCompleteStatusObj[this.job.status]
    },
    orgTechs: get('sch_v3/orgTechs')
  },
  created () {
    this.$root.$on('closeSchedulerJobDetails', this.closeWindow)
  },
  beforeDestroy: function () {
    this.$root.$off('closeSchedulerJobDetails', this.closeWindow)
    window.removeEventListener('returnCustomerData', this.hydrateConsumerFromVue3)
  },
  mounted() {
    window.addEventListener('returnCustomerData', this.hydrateConsumerFromVue3)
  },
  methods: {
    hydrateConsumerFromVue3(event) {
      if (event.detail.field !== 'job.scheduler' || event.detail.isCreated) return
      this.onSaveConsumer()
    },
    closeWindow() {
      this.jobDetails = null
    },
    updateJobDetailsValues: call(actions.updateJobDetailsValues),
    completeTechsideJob: call(actions.completeTechsideJob),
    updateJobAddressByPlaceId: call(actions.updateJobAddressByPlaceId),
    getSchedulerData: call(actions.getSchedulerData),
    viewJobDetails: call(actions.getJobDetails),
    async completeJob() {
      // var self = this
      // // get shop default
      // let shop = this.getShopFromId(self, self.job.shop_id)
      // if (!self.job.techside_job_completed) {
      //   self.completing = true
      //   if (shop.version_pdf_default === 'legacy') {
      //     await self.completeTechsideJob(self.job.id)
      //     // #Audit Log - TechSide Completed
      //     AuditLog.createAuditLog(self.job.id, 'TSCOMPLETED')
      //     self.completing = false
      //   } else {
      //     pdf.handleTechsideEvent(
      //       'complete',
      //       self.job.id,
      //       shop.tsign_version,
      //       function (res) {
      //         if (res.data.status === 'success') {
      //           self.job.techside_job_completed = 1
      //           AuditLog.createAuditLog(self.job.id, 'TSCOMPLETED')
      //           self.completing = false
      //         }
      //       }
      //     )
      //   }
      // }
    },
    saveComplete() {
      const completeStatusObj = {
        workOrder: 'invoice',
        warranty: 'warrantyComplete',
        recal: 'recalComplete',
        callBack: 'callBackComplete',
        rAndI: 'rAndIComplete',
        tint: 'tintComplete',
        pickUp: 'pickUpComplete',
        dropOff: 'dropOffComplete'
      }

      const completeStatusDateObj = {
        workOrder: 'invoice_date',
        warranty: 'warranty_complete_date',
        recal: 'recal_complete_date',
        callBack: 'call_back_complete_date',
        rAndI: 'r_and_i_complete_date',
        tint: 'tint_complete_date',
        pickUp: 'pick_up_complete_date',
        dropOff: 'drop_off_complete_date'
      }

      const firstInvoice = completeStatusObj[this.job.status] === 'invoice'

      this.updateJobDetailsValues({
        status: completeStatusObj[this.job.status],
        [completeStatusDateObj[this.job.status]]: formatToDb(new Date())
      })

      if (firstInvoice && this.job.shop.podium_location_id && this.job.consumer.phone?.number) {
        this.inviteCustomerReview()
      }

      this.closeWindow()
    },
    async placeSelected({ place_id }) {
      const { id } = this.job
      this.updateJobAddressByPlaceId({ place_id, id })
      this.editAddress = false
    },
    inviteCustomerReview() {
      const { id, shop_id, consumer } = this.job

      const customerName = consumer.is_commercial ? consumer.company_name
        : `${consumer.full_name.first} ${consumer.full_name.last}`

      this.$messageBox.confirm(
        `Send Podium review invitation to ${customerName}?`,
        'Customer Review', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning'
        }).then(() => {
        Job.sendCustomerReview(id, shop_id, ({ data }) => {
          this.$message({ type: data.result, message: data.message })
        })
      })
    },
    async openConsumerDialog() {
      const newEvent = new CustomEvent('openHydrateCustomerDialog', { 
        detail: { 
          id: this.job.consumer_id,
          field: 'job.scheduler'
        }
      })
      window.dispatchEvent(newEvent)
    },
    onSaveConsumer() {
      this.getSchedulerData()
      this.viewJobDetails(this.job.id)
    },
    async updateTech(value) {
      await this.$unum.vendor().post(`/job/${this.job.id}/set-job-technicians`, {
        techs: value.map(v => processTech(v, this.jobDetails.parts, this.job.id))
      })
      
      this.getSchedulerData()
      this.viewJobDetails(this.job.id)
    },
    async updateTechCommission(value) {
        await this.$unum.vendor().patch(
          `/scheduler/${this.job.id}/commission`,
          [value]
        )
    
        this.getSchedulerData()
        this.viewJobDetails(this.job.id)
    }
  }
}
</script>

<style lang="scss">
.floating-button {
  .el-badge__content.is-fixed {
    transform: translateY(-50%) translateX(40%);
  }
}
</style>

<style lang="scss" scoped>
@import "./styles/SchedulerJobDetails";
</style>
