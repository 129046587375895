<template>
  <div class="header-v2-user">
    <div class="header-v2-user-icons">
      <header-v-2-notifications class="header-v2-user-icons-item" />

      <el-badge
        class="header-v2-user-icons-item"
        :max="99"
        :value="cartCount"
        :hidden="!cartCount"
      >
        <div class="gb-icon-white-button" @click="openShoppingCart">
          <i class="material-icons-outline md-shopping_cart" />
        </div>
      </el-badge>
    </div>

    <div class="header-v2-user-avatar">
      <el-popover
        placement="bottom-end"
        trigger="click"
        v-model="visibleUserMenu"
        popper-class="header-v2-user-popover"
      >
        <div slot="reference">
          <el-avatar v-if="avatarUrl" :src="avatarUrl" />
          <i v-else class="material-icons md-account_circle t-text-white t-cursor-pointer t-text-3xl" />
        </div>
        <header-v-2-user-menu
          @menu-select="onSelect"
          :allow-quick-books="allowQuickBooks"
          :file-picker-key="filePickerKey"
        />
      </el-popover>
    </div>

    <header-v-1
      class="header-v2-hide-header"
      ref="oldHeader"
      :allow-quick-books="allowQuickBooks"
      :file-picker-key="filePickerKey"
    />
    <draft-warning-dialog
      :visible.sync="draftWarningVisible"
      @discardDraft="discardDraft"
      @leaveDraft="leaveDraft"
      @publishDraft="publishDraft"
    />
  </div>
</template>

<script>
import { shoppingCartActions } from '@/store/modules/shopping-cart/types'
import DraftWarningDialog from '../../JobPageV2/helpers/dialogs/DraftWarningDialog.vue'
import HeaderV2UserMenu from './HeaderV2UserMenu'
import HeaderV2Notifications from './HeaderV2Notifications'
import HeaderV1 from '../Header'
import { sync } from 'vuex-pathify'

export default {
  props: ['allowQuickBooks', 'filePickerKey'],
  components: {
    HeaderV2UserMenu,
    HeaderV1,
    DraftWarningDialog,
    HeaderV2Notifications
  },
  data() {
    return {
      visible: false,
      draftWarningSelections: ['help', 'logout'],
      draftWarningVisible: false,
      draftWarningItemAction: null,
      visibleUserMenu: false,
      visibleNotifications: true,
      avatarUrl: null
    }
  },
  computed: {
    statusDialog: sync('jobDetails/actionDialogs@status'),
    adminUtilsDialog: sync('adminUtilsOpen'),
    // notificationsCount() {
    //   return this.$store.state.shoppingCart.items.totalItemsCount
    // },
    cartCount() {
      return this.$store.state.shoppingCart.items.totalItemsCount
    }
    // notificationsCount() {
    //   return this.$store.state.notifications.count
    // }
  },

  async created() {
    const url = `https://storage.googleapis.com/gb-auth-files/glassbiller-staging%2Fgb%7C${this.$store.state.user.id}.png`
    const isValid = await this.isValidImageUrl(url)
    this.avatarUrl = isValid ? url : null
  },

  methods: {
    async isValidImageUrl(url) {
      return new Promise(resolve => {
        const img = new Image()
        img.onload = () => resolve(true)
        img.onerror = () => resolve(false)
        img.src = url
      })
    },
    // onStorageUpdate(event) {
    //   if (event.key === "unreadNotificationsCount") {
    //     this.$store.dispatch(notificationsActions.updateUnreadNotificationsCount, event.newValue)
    //   }
    // },
    // getNotificationCount() {
    //   this.$store.dispatch(notificationsActions.getUnreadNotificationsCount)
    // },
    // openNotifications() {
    //   return this.$store.dispatch(notificationsActions.open)
    // },
    openShoppingCart() {
      return this.$store.dispatch(shoppingCartActions.open)
    },
    async discardDraft() {
      try {
        await this.$messageBox.confirm(
          'This will permanently delete the draft. Continue?',
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        )
        this.draftWarningVisible = false
        this.$unum.job().delete(`/draft/${this.$route.params.draft_id}`)
        this.$refs.oldHeader[this.draftWarningItemAction]()
      } catch {}
    },
    leaveDraft() {
      this.draftWarningVisible = false
      this.$refs.oldHeader[this.draftWarningItemAction]()
    },
    publishDraft() {
      this.draftWarningVisible = false
      this.statusDialog = true
    },
    async onSelect(item) {
      if (item.action && this.$refs.oldHeader[item.action]) {
        this.visible = false
        if (
          this.$job?.isDraft &&
          this.$job?.shop?.id &&
          this.draftWarningSelections.includes(item.key)
        ) {
          this.draftWarningItemAction = item.action
          this.draftWarningVisible = true
        } else {
          this.$refs.oldHeader[item.action]()
        }
        this.visibleUserMenu = false
        this.visibleNotifications = false
      } else if (item.link) {
        window.open(item.key, '_blank')
      }
    }
    // setNotificationsCount(count) {
    //   this.notificationsCount = count
    // }
  }
}
</script>
<style lang="scss" scoped>
.header-v2-user {
  display: flex;
  align-items: center;

  &-icons {
    list-style: none;
    gap: $padding-sm;
    height: 32px;
    display: flex;
    align-items: center;

    &-item {
      display: flex;
    }
  }

  &-avatar {
    margin-left: $padding-sm;
    .el-popover__reference {
      display: flex;

      .el-avatar {
        height: 32px;
        width: 32px;
      }
    }
  }

  .header-v2-hide-header {
    position: fixed;
    bottom: -1000px;
  }
}
</style>

<style lang="scss">
.header-v2-user {
  .el-badge__content {
    font-size: 10px;
    height: 16px;
    line-height: 15px;
    padding: 0 4px;
    background: $jade-green;
  }
}
.header-v2-user-popover {
  background: $grayscale-9;
  border: none;
  border-radius: 0;
  padding: 0 0 $padding-sm;
  .popper__arrow {
    display: none;
  }
}
</style>
