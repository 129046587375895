import axios from 'axios'
import cfg from '@/config'
import Vue from 'vue'

const VendorsAPI = axios.create({
  baseURL: `${cfg.uri}/unum/vendor`
})

VendorsAPI.interceptors.request.use(async request => {
  const auth = Vue.prototype.$auth

  request.headers.Authorization = auth.getAuthorizationHeader()
  request.headers['Visitor-Fingerprint-Id'] = auth.getVisitorFingerprintId()

  return request
})

VendorsAPI.interceptors.response.use(
  response => response.data,
  error => {
    const auth = Vue.prototype.$auth

    if (error.response?.status === 401) {
      if (error.response?.data?.type === 'suspended_visitor_fingerprint_id') {
        auth.handleSuspendedFingerprint()
      } else {
        auth.logout()
      }
    }
  }
)

export { VendorsAPI }
