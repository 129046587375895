import Vue from 'vue'
import axios from 'axios'
import { Message } from 'element-ui'
import cfg from '@/config'

let instance

export const useUnum = () => {
  if (instance) return instance

  instance = {
    consumer: () => createClient('consumer'),
    job: () => createClient('job-details'),
    vendor: () => createClient('vendor'),
    charts: () => createClient('charts'),
    catalogs: () => createClient('catalogs'),
    scheduler: () => createClient('scheduler'),
    sch: () => createClient('scheduler'),
    support: () => createClient('support'),
    documents: () => createClient('documents'),
    inventory: () => createClient('inventory'),
    warehouse: () => createClient('warehouse'),
    confirmation: () => createClient('consumer-confirmation'),
    insurance: () => createClient('insurance')
  }

  return instance
}

const createClient = path => {
  const client = axios.create({
    baseURL: `${cfg.uri}/unum/${path}`
  })

  client.interceptors.request.use(async request => {
    const auth = Vue.prototype.$auth

    // add auth token
    request.headers.Authorization = auth.getAuthorizationHeader()
    request.headers['Visitor-Fingerprint-Id'] = auth.getVisitorFingerprintId()

    return request
  })

  client.interceptors.response.use(
    response => response,
    error => {
      const auth = Vue.prototype.$auth

      if (error.response?.status === 401) {
        if (error.response?.data?.type === 'suspended_visitor_fingerprint_id') {
          auth.handleSuspendedFingerprint()
          return
        } else {
          auth.logout()
          return
        }
      }

      if (axios.isCancel(error)) {
        return
      }

      Message.error(error.response?.data?.error)
      return Promise.reject(error)
    }
  )

  return client
}

export default {
  install(Vue, options) {
    Vue.prototype.$unum = useUnum()
  }
}
