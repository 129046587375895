<template>
  <div>
    <b-modal
      @show="dialogAboutToAppear"
      @shown="dialogAppeared"
      @hide="dialogAboutToDisappear"
      no-close-on-backdrop
      v-bind:title="'Generate Pdf'"
      id="schedulerPdfDialog"
      header-bg-variant="primary"
      header-text-variant="light"
      size="lg"
    >
      <div>
        <p>Choose a PDF to generate:</p>
        <p>
          * Generating Pdfs will take longer if you are generating for multiple
          jobs or if you are including a shop logo.
        </p>
        <label for="pdfVersionSelect">Version&nbsp;</label>
        <div v-if="generating" class="float-right">
          <img src="./../../assets/img/spinner.gif" />
        </div>
        <span>
          <b-form-select
            id="pdfVerisonSelect"
            v-model="pdfVersion"
            :options="pdfVersionOptions"
            size="sm"
          ></b-form-select>
        </span>
        <b-form-checkbox
          id="collatedCB"
          v-model="collated"
          value="1"
          unchecked-value="0"
          >Collated (1 1, 2 2, 3 3...)</b-form-checkbox
        >
      </div>
      <div class="row">
        <div class="form-group col-md-12 mt-2">
          <b-form-group class="float-right">
            <b-form-radio-group v-model="pdfVariant" id="">
              <b-form-radio value="withPrices">With Prices</b-form-radio>
              <b-form-radio value="noPrices">Without Prices</b-form-radio>
              <b-form-radio value="customerCopy"
                >Copy For Customer</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <div slot="modal-footer" class="w-100">
        <div class="float-right">
          <button
            type="button"
            class="btn btn-primary"
            @click="generateClicked()"
            variant="primary"
          >
            Generate
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            @click="cancelClicked"
            variant="primary"
          >
            Cancel
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      :ref="id + '-spinner'"
      hide-footer
      hide-header
      size="sm"
      header-bg-variant="primary"
    >
      <div
        style="display: block; margin-left: auto; margin-right: auto; width: 20%;"
      >
        <img src="./../../assets/img/spinner.gif" />
      </div>
      <div class="mx-auto" style="width: 150px">
        Generating PDF...
      </div>
    </b-modal>
  </div>
</template>

<script>
import { UtilityMixin } from '@/scripts/helpers/utilities'
import LegacyApi from '@/services/LegacyApi'
import Pdf from '@/scripts/objects/pdf'

export default {
  props: ['tech', 'id'],
  data() {
    return {
      jobs: [],
      jobs_string: '',
      condensed: 0,
      collated: 0,
      pdfVersion: 'legacy',
      pdfVersionOptions: [
        { value: 'legacy', text: 'Legacy' },
        { value: 'legacy_condensed', text: 'Legacy Condensed' },
        { value: 'redesign', text: 'Redesign' }
      ],
      pdfVariant: 'withPrices',
      generating: false
    }
  },
  methods: {
    dialogAboutToAppear: function() {
      var self = this
      this.condensed = this.tech.condensed_pdf_default
      Pdf.getShopDefault(this.tech.shop_id, function(res) {
        if (res.data.tsign) {
          self.pdfVariant = res.data.tsign
        }
        if (res.data.version === 'legacy') {
          if (res.data.condensed.toString() === '1') {
            self.pdfVersion = 'legacy_condensed'
          } else {
            self.pdfVersion = 'legacy'
          }
        } else if (res.data.version === 'redesign') {
          self.pdfVersion = 'redesign'
        }
      })
    },
    dialogAppeared: function() {
      for (var i = 0; i < this.tech.jobs.length; i++) {
        this.jobs.push(this.tech.jobs[i].id)
        if (i === 0) {
          this.jobs_string = this.tech.jobs[i].id
        } else {
          this.jobs_string += '-' + this.tech.jobs[i].id
        }
      }
    },
    dialogAboutToDisappear: function() {
      this.collated = 0
      this.condensed = 0
      this.generating = false
      this.jobs_string = ''
      this.pdfVersion = 'legacy'
    },
    generateClicked: async function() {
      var self = this
      self.generating = true
      if (self.pdfVersion.includes('legacy')) {
        var url = '/pdf/job/invoice/' + self.jobs_string
        if (self.pdfVariant !== 'withPrices') {
          url += '?noprices=1'
          if (self.pdfVariant === 'customerCopy') {
            url += '&customercopy=1'
          }
          if (self.collated === 1 || self.collated === '1') {
            url += '&collate=1'
          }
          if (self.pdfVersion.includes('condensed')) {
            url += '&condensed=1'
          }
        } else {
          if (
            self.pdfVersion.includes('condensed') &&
            (self.collated === 1 || self.collated === '1')
          ) {
            url += '?condensed=1&collate=1'
          } else if (
            self.pdfVersion.includes('condensed') &&
            self.collated !== 1 && self.collated !== '1'
          ) {
            url += '?condensed=1'
          } else if (
            (self.collated === 1 || self.collated === '1') &&
            !self.pdfVersion.includes('condensed')
          ) {
            url += '?collate=1'
          }
        }
        const symbol = url.indexOf('?') >= 0 ? '&' : '?'
        url += `${symbol}token=${this.$auth.getAuthorizationHeader()}`
        const res = await LegacyApi.get(url, { responseType: 'arraybuffer' })
        self.handlePdf(res.data)
      } else if (self.pdfVersion === 'redesign') {
        var currentUser = self.$store.state.user
        // jobs_string: 567898-567810 (separator: -)
        Pdf.renderSchedulerPdfs(
          self.tech.shop_id,
          currentUser.id,
          currentUser.username,
          self.pdfVariant,
          self.jobs_string,
          self.collated,
          function(res) {
            self.handlePdf(res.data)
            self.generating = false
            self.$root.$emit('bv::hide::modal', 'schedulerPdfDialog')
          }
        )
      }
    },
    handlePdf(data) {
      this.$root.$emit('bv::hide::modal', this.id)
      var newBlob = new Blob([data], { type: 'application/pdf' })
      var url = URL.createObjectURL(newBlob)
      const link = document.createElement('a')
      link.href = url
      const filename = `${this.tech.name}-schedule.pdf`
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      this.generating = false
    },
    cancelClicked: function() {
      this.$root.$emit('bv::hide::modal', 'schedulerPdfDialog')
    },
    getCurrentDate: function() {
      const date = new Date()
      const day = date.getDate()
      const month = date.getMonth() + 1
      let stringMonth = ''
      if (JSON.stringify(month).length === 1) {
        stringMonth = JSON.stringify(0) + JSON.stringify(month)
      } else {
        stringMonth = month
      }
      const year = date.getFullYear()
      return stringMonth + '/' + day + '/' + year
    }
  },
  mixins: [UtilityMixin]
}
</script>

<style scoped>
.pdfButtonCol {
  text-align: center;
}
</style>
