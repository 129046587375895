var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-flex" },
    [
      _c(
        "el-scrollbar",
        {
          staticClass: "t-flex-grow t-h-[700px] t-my-4 t-mr-2",
          attrs: {
            "wrap-class": "t-overflow-x-hidden t-flex-shrink-0 t-py-2 t-px-8"
          }
        },
        [
          _c("div", { staticClass: "qbo-mapping-dialog-body" }, [
            _vm.missingQboAccount.length
              ? _c(
                  "div",
                  [
                    _c("el-divider", { staticClass: "t-my-6" }, [
                      _vm._v("Vendor Types")
                    ]),
                    _c("p", { staticClass: "t-my-6" }, [
                      _vm._v(
                        "Please map each of the following vendor types to an expense account in QBO"
                      )
                    ]),
                    _vm._l(_vm.missingQboAccount, function(type) {
                      return _c("quick-books-account-select", {
                        staticClass: "qbo-account-select",
                        attrs: {
                          label: _vm.gbExportItemLabel[type.id],
                          type: type
                        }
                      })
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm.missingQboVendor.length
              ? _c(
                  "div",
                  [
                    _c("el-divider", { staticClass: "t-my-6" }, [
                      _vm._v("Vendors")
                    ]),
                    _c("p", { staticClass: "t-my-6" }, [
                      _vm._v(
                        "Please map each of the following vendors to a vendor in QBO"
                      )
                    ]),
                    _vm._l(_vm.missingQboVendor, function(vendor) {
                      return _c("quick-books-vendor-select", {
                        staticClass: "qbo-account-select",
                        attrs: { vendor: vendor, options: _vm.options }
                      })
                    })
                  ],
                  2
                )
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }