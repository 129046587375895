var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "scheduler-map-tech-filters",
      class: { expanded: _vm.expanded }
    },
    [
      _c(
        "div",
        {
          staticClass: "mobile-expand",
          on: {
            click: function() {
              _vm.expanded = !_vm.expanded
            }
          }
        },
        [
          _c("v-icon", {
            class: { "fa-rotate-180": _vm.expanded },
            attrs: { name: "angle-up" }
          })
        ],
        1
      ),
      _vm.routeStates.length > 1
        ? _c("div", [
            _c("h4", [_vm._v("States:")]),
            _c(
              "div",
              { staticClass: "tech-state-filters" },
              _vm._l(_vm.routeStates, function(state) {
                return _c(
                  "el-button",
                  {
                    key: state,
                    attrs: {
                      round: "",
                      type: state === _vm.bounds ? "primary" : "",
                      size: _vm.isMobile ? "small" : "default"
                    },
                    on: {
                      click: function($event) {
                        return _vm.setBounds(state)
                      }
                    }
                  },
                  [_vm._v(_vm._s(state))]
                )
              }),
              1
            )
          ])
        : _vm._e(),
      _c(
        "el-collapse",
        { attrs: { accordion: "" } },
        [
          _c(
            "el-collapse-item",
            { attrs: { name: "1" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "div",
                  { staticClass: "t-flex t-justify-between" },
                  [
                    _c("h4", [_vm._v("Map Layers")]),
                    _c(
                      "el-tag",
                      {
                        staticClass: "t-ml-1",
                        attrs: {
                          size: "small",
                          type:
                            _vm.selectedLayersCount === _vm.layerList.length
                              ? ""
                              : "warning"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.selectedLayersCount +
                                "/" +
                                _vm.layerList.length
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                _vm._l(_vm.layerList, function(l) {
                  return _c(
                    "el-checkbox",
                    {
                      key: l,
                      staticClass: "tech-checkbox",
                      attrs: { checked: _vm.layerFilter[l] },
                      on: {
                        change: function($event) {
                          return _vm.handleLayerChange($event, l)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(l.toUpperCase()) + " ")]
                  )
                }),
                1
              )
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { name: "2" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "div",
                  { staticClass: "t-flex t-justify-between" },
                  [
                    _c("h4", [_vm._v("Shops")]),
                    _c(
                      "el-tag",
                      {
                        staticClass: "t-ml-1",
                        attrs: {
                          size: "small",
                          type:
                            _vm.selectedShopsCount === _vm.shops.length
                              ? ""
                              : "warning"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.selectedShopsCount + "/" + _vm.shops.length
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                _vm._l(_vm.shops, function(shop) {
                  return _c(
                    "el-checkbox",
                    {
                      key: shop.id,
                      staticClass: "tech-checkbox",
                      attrs: { checked: shop.checked },
                      on: {
                        change: function($event) {
                          return _vm.handleShopChange($event, shop)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(shop.name.toUpperCase()) + " ")]
                  )
                }),
                1
              )
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { name: "3" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "div",
                  { staticClass: "t-flex t-justify-between" },
                  [
                    _c("h4", [_vm._v("Tags")]),
                    _c(
                      "el-tag",
                      {
                        staticClass: "t-ml-1",
                        attrs: {
                          size: "small",
                          type:
                            _vm.selectedTagsCount === _vm.tags.length
                              ? ""
                              : "warning"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.selectedTagsCount + "/" + _vm.tags.length
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.selectedTags,
                        callback: function($$v) {
                          _vm.selectedTags = $$v
                        },
                        expression: "selectedTags"
                      }
                    },
                    _vm._l(_vm.tags, function(tag) {
                      return _c(
                        "el-checkbox",
                        {
                          key: tag.id,
                          staticClass: "tech-checkbox",
                          style: { color: tag.color },
                          attrs: { label: tag.id }
                        },
                        [_vm._v(" " + _vm._s(tag.text.toUpperCase()) + " ")]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { name: "4" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "div",
                  { staticClass: "t-flex t-justify-between" },
                  [
                    _c("h4", [_vm._v("Technician routes")]),
                    _c(
                      "el-tag",
                      {
                        staticClass: "t-ml-1",
                        attrs: {
                          size: "small",
                          type:
                            _vm.selectedRoutesCount === _vm.routes.length
                              ? ""
                              : "warning"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.selectedRoutesCount + "/" + _vm.routes.length
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                _vm._l(_vm.routes, function(route) {
                  return _c(
                    "el-checkbox",
                    {
                      key: route.id,
                      staticClass: "tech-checkbox",
                      style: { color: route.color },
                      attrs: {
                        value: !route.hidden && !route.error,
                        disabled: !!route.error
                      },
                      on: {
                        change: function($event) {
                          return _vm.handleSelectionChange($event, route)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "tech-checkbox-label" },
                        [
                          _vm._v(" " + _vm._s(_vm.getName(route)) + " "),
                          _c("tech-location-indicator", {
                            attrs: {
                              username: route.techside_username,
                              color: route.color
                            },
                            on: { click: _vm.onIndicatorClick }
                          })
                        ],
                        1
                      )
                    ]
                  )
                }),
                1
              )
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { name: "5" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "div",
                  { staticClass: "t-flex t-justify-between" },
                  [
                    _c("h4", [_vm._v("Time Frames")]),
                    _c(
                      "el-tag",
                      {
                        staticClass: "t-ml-1",
                        attrs: {
                          size: "small",
                          type:
                            _vm.selectedTimeFramesCount ===
                            _vm.timeFrameList.length
                              ? ""
                              : "warning"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.selectedTimeFramesCount +
                                "/" +
                                _vm.timeFrameList.length
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                _vm._l(_vm.timeFrameList, function(tf) {
                  return _c(
                    "el-checkbox",
                    {
                      key: tf,
                      staticClass: "tech-checkbox",
                      attrs: { checked: _vm.timeFrameFilter[tf] },
                      on: {
                        change: function($event) {
                          return _vm.handleTimeFrameChange($event, tf)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(tf.toUpperCase()) + " ")]
                  )
                }),
                1
              )
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { name: "6" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "div",
                  { staticClass: "t-flex t-justify-between" },
                  [
                    _c("h4", [_vm._v("Warehouses")]),
                    _c(
                      "el-tag",
                      {
                        staticClass: "t-ml-1",
                        attrs: {
                          size: "small",
                          type:
                            _vm.selectedWarehousesCount ===
                            _vm.warehouseList.length
                              ? ""
                              : "warning"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.selectedWarehousesCount +
                                "/" +
                                _vm.warehouseList.length
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                _vm._l(_vm.warehouseList, function(wh) {
                  return _c(
                    "el-checkbox",
                    {
                      key: wh,
                      staticClass: "tech-checkbox",
                      style: { color: _vm.warehouseColors[wh] },
                      attrs: { checked: _vm.warehouseFilter[wh] },
                      on: {
                        change: function($event) {
                          return _vm.handleWarehouseChange($event, wh)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(wh.toUpperCase()) + " ")]
                  )
                }),
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }