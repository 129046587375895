import Vue from 'vue'
import Pusher from 'pusher-js'
import store from '@/store/store'
import { baseApiUrl } from './Api'
import { Notification, MessageBox } from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import cfg from '@/config'
import User from '@/scripts/objects/user'
import { globalActions } from '@/store/modules/notifications/types'
import { parseRow } from '@/store/modules/notifications/helpers'
import GBNotification from '@/components/Notifications/Notification'

const ChannelType = {
  CHANNEL_TYPE_WEB: 'web',
  CHANNEL_TYPE_POPUPP: 'pop-up'
}

const playNotificationSound = () => {
  const audio = new Audio(require('@/assets/sounds/notification.ogg'))
  audio.play()
}

/**
 * Load vue template for short notificatin and compile it to static HTML
 */
const loadNotificationHTML = data => {
  const component = new (Vue.extend(GBNotification))({
    propsData: {
      data: parseRow(data)
    }
  })

  component.$mount()

  // create parent div
  const div = document.createElement('div')
  div.appendChild(component.$el)

  return div.innerHTML
}

class PusherService {
  init = async () => {
    this.pusher = new Pusher(cfg.pusher.key, {
      cluster: cfg.pusher.cluster,
      disableStats: true,
      authorizer: (channel, options) => ({
        authorize: (socketId, callback) => {
          // Collect channel authentication requests
          batchAuthRequests(channel.name, socketId, options, callback)
        }
      })
    })

    this.pusher.connection.bind('connected', this.onConnected)
    this.subscribeToNotifications()
  }

  onConnected = () => {
    store.dispatch(globalActions.getUnreadCount)
  }

  subscribeToSquareDeviceNotifications = async (shopId, deviceId) => {
    const channel = this.pusher.subscribe(
      'square-shop-' + shopId + '-device-' + deviceId
    )

    channel.bind('checkout-updated', payload => {
      store.dispatch('square/checkout/update', payload)
    })
  }

  unsubscribeToSquareDeviceNotifications = async (shopId, deviceId) => {
    this.pusher.unsubscribe('square-shop-' + shopId + '-device-' + deviceId)
  }

  subscribeToNotifications = async () => {
    const channel = this.pusher.subscribe(
      `private-notifications-${store.state.user.id}`
    )

    channel.bind(ChannelType.CHANNEL_TYPE_POPUPP, ({ data, sound }) => {
      if (sound) {
        playNotificationSound()
      }

      MessageBox({
        dangerouslyUseHTMLString: true,
        message: loadNotificationHTML(data),
        showCancelButton: false,
        confirmButtonText: 'OK',
        customClass: 'notification-message-box',
        onClose: () => {
          data.is_read = true
          store.dispatch(globalActions.receive, { data })
        },
        locale
      })
    })

    channel.bind(ChannelType.CHANNEL_TYPE_WEB, ({ data, sound }) => {
      if (sound) {
        playNotificationSound()
      }

      const duration = 4500
      const receivedTime = Date.now()

      Notification({
        dangerouslyUseHTMLString: true,
        customClass: 'notification-push',
        message: loadNotificationHTML(data),
        duration: duration,
        showClose: true,
        onClose: () => {
          const elapsedTime = Date.now() - receivedTime
          // buffer time we consider was spent on
          // notification initialisation in ms
          const bufferTime = 5

          // check if we closed notifiation manually
          data.is_read = duration - elapsedTime - bufferTime > 0

          store.dispatch(globalActions.receive, { data })
        }
      })
    })
  }

  subscribeToRelatedShops = async () => {
    const shopIds = await User.getShopIdsWithActiveTwilio()
    for (const { id } of shopIds) {
      const channel = this.pusher.subscribe('private-shop-' + id)

      channel.bind(
        'new-incoming-text-message',
        async ({ threadId, phoneNumberId, recipientType }) => {
          const usersPhoneNumbers = await User.getSelectedTwilioPhoneNumbers(
            store.state.user.id
          )
          let hasAccess = false
          for (const phoneNumber of usersPhoneNumbers) {
            if (phoneNumber.id === phoneNumberId) {
              hasAccess = true
            }
          }
          if (hasAccess) {
            if (store.state.twilio.textMessages.visible) {
              store.dispatch(
                'twilio/textMessages/setNewMessageThreadId',
                threadId
              )
            } else {
              await User.hasUnreadTextMessage({ hasUnreadTextMessage: 1 })
              store.commit('hasUnreadTextMessage', 1)
            }
            store.dispatch('twilio/textMessages/getUnreadThreadsCount')
          }
        }
      )

      channel.bind('unread-thread-opened', () => {
        store.dispatch('twilio/textMessages/getUnreadThreadsCount')
      })

      channel.bind('text-message-delivery-failed', payload => {
        if (store.state.twilio.textMessages.visible) {
          store.dispatch('twilio/textMessages/setUndeliveredMessage', payload)
        }
      })
    }
  }
}

const authQueue = []
let authTimer = null

function batchAuthRequests(channelName, socketId, options, callback) {
  authQueue.push({ channelName, socketId, callback })

  const auth = Vue.prototype.$auth

  if (!authTimer) {
    authTimer = setTimeout(async () => {
      const channels = [...authQueue]
      authQueue.length = 0
      authTimer = null

      try {
        const params = new URLSearchParams()
        params.append('socket_id', socketId)
        channels.forEach((channel, index) => {
          params.append(`channel_name[${index}]`, channel.channelName)
        })

        const response = await fetch(`${baseApiUrl}/api/pusher/auth`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: auth.getAuthorizationHeader(),
            'Visitor-Fingerprint-Id': auth.getVisitorFingerprintId()
          },
          body: params.toString()
        })

        const data = await response.json()

        channels.forEach(channel => {
          const authData = data[channel] || null
          callback(null, authData)
        })
      } catch (error) {
        channels.forEach(channel => callback(error, null))
      }
    }, 200) // Adjust delay as needed
  }
}

export default new PusherService()
