import { defineStore } from 'pinia'
import Api from '@/services/Api'
import { VendorsAPI } from '@/services/VendorsAPI'

export const gbExportItems = {
  'CSR Bill': 19,
  'Sale Source Bill': 20,
  'Sales Rep Bill': 21,
  'Tech Bill': 22,
  'Location Bill': 23
}

export const useQboCommissionStore = defineStore('qboCommission', {
  state: () => {
    return {
      saving: false,
      saved: false,
      shopId: null,
      visible: false,
      fetching: false,
      missingQboAccount: [],
      missingQboVendor: []
    }
  },

  getters: {
    continue: (state) => {
      const vendorsNotSet = state.missingQboVendor.filter((v) => !v.default)
      const typesNotSet = state.missingQboAccount.filter((v) => !v.default)
      return !vendorsNotSet.length && !typesNotSet.length
    }
  },

  actions: {
    openView(shopId, missingQboAccount, missingQboVendor) {
      this.shopId = shopId
      this.missingQboAccount = missingQboAccount
      this.missingQboVendor = missingQboVendor
      this.visible = true
    },

    async updateShopQboMapping(gbExportItem, qboAccountId, qboAccountName, shopId) {
      try {
        this.saving = true

        const update = {
          gb_export_item: gbExportItem,
          qbo_account_id: qboAccountId,
          qbo_account_name: qboAccountName
        }

        await Api.post(
          `api/shop/${shopId}/qboMapping/updateShopQboMapping`,
          update
        ).then(res => {
          console.log('res:', res)
          this.missingQboAccount = [...this.missingQboAccount.map((i) => {
            if (i.id === gbExportItem) {
              return {
                ...i,
                default: qboAccountId
              }
            }
  
            return i
          })]
          this.saved = true
          this.getAllQboDefaultsForShop()
        })
      } catch (e) {
        throw new Error('Failed to update default QuickBooks Online account')
      } finally {
        this.saving = false
      }
    },

    async getShopAccountsFromQbo(shopId) {
      try {
        this.fetching = true

        const { data } = await Api.get(`api/shop/${shopId}/qboMapping/getQboBillAccounts`)

        return data
      } catch (e) {
        throw new Error('Failed to retrieve accounts from QuickBooks Online')
      } finally {
        this.fetching = false
      }
    },

    async getShopDefaultQboAccount(shopId, accountType) {
      try {
        const { data } = await Api.get(
          `api/shop/${shopId}/qboMapping/getShopDefault/item/${gbExportItems[accountType]}`
        )
        return data
      } catch (e) {
        throw new Error(`Failed to retrieve default QuickBooks Online account for type: ${accountType}`)
      }
    },

    async getAllQboDefaultsForShop() {
      try {
        const { data } = await Api.get(
          `/api/shop/${this.shopId}/qboMapping/getShopDefault/all`
        )

        this.defaults = data
      } catch (e) {
        throw new Error('Failed to retrieve shop default QuickBooks accounts')
      }
    },

    async getAllQboVendorsForShop() {
      try {
        this.fetching = true
        const { data } = await Api.get(
          `/api/shop/${this.shopId}/qboMapping/getQboVendors`
        )

        return data
      } catch (e) {
        throw new Error('Failed to retrieve shop default QuickBooks accounts')
      } finally {
        this.fetching = false
      }
    },

    async updateShopVendorQboDefault(id, qboVendorId, shopId) {
      try {
        this.saving = true
        const idk = await VendorsAPI.post(`${id}/qbo-default`, {qboVendorId, shopId})
        this.saved = true

        this.missingQboVendor = [...this.missingQboVendor.map((v) => {
          if (v.id === id) {
            return {
              ...v,
              default: qboVendorId
            }
          }

          return v
        })]

        return idk
      } catch (e) {
        throw new Error(e.response.data)
      } finally {
        this.saving = false
      }
    },

    close() {
      this.$reset()
    }
  }
})
