var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        title: "User Details",
        id: _vm.modalName ? _vm.modalName : "userDialog",
        "modal-class": "usersAdminDialog",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "header-bg-variant": "primary",
        "header-text-variant": "light",
        size: "lg",
        "hide-header-close": ""
      },
      on: { show: _vm.dialogAboutToAppear }
    },
    [
      _c("div", { staticClass: "form-row" }, [
        _c("div", { staticClass: "form-group col-md-12" }, [
          _vm._v(" ID: "),
          _c("strong", [
            _vm._v(_vm._s(_vm.user && _vm.user.id > 0 ? _vm.user.id : "NEW"))
          ])
        ])
      ]),
      _vm.adminCheck()
        ? _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-md-4" }, [
              _c("label", { attrs: { for: "statusSelect" } }, [
                _vm._v("STATUS")
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.status,
                      expression: "user.status"
                    }
                  ],
                  staticClass: "form-control form-control-sm autocomplete-off",
                  attrs: { id: "statusSelect" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.user,
                        "status",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "active" } }, [
                    _vm._v("Active")
                  ]),
                  _c("option", { attrs: { value: "disabled" } }, [
                    _vm._v("Disabled")
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "form-group col-md-8" }, [
              _c("div", { staticClass: "float-right" }, [
                _c("div", { staticClass: "form-row" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.account_manager,
                        expression: "user.account_manager"
                      }
                    ],
                    staticClass: "form-control-sm autocomplete-off",
                    attrs: { id: "accountManager", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.user.account_manager)
                        ? _vm._i(_vm.user.account_manager, null) > -1
                        : _vm.user.account_manager
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.user.account_manager,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.user,
                                "account_manager",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.user,
                                "account_manager",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.user, "account_manager", $$c)
                        }
                      }
                    }
                  }),
                  _c("label", { attrs: { for: "accountManger" } }, [
                    _vm._v(" Account Manager")
                  ])
                ]),
                _c("div", { staticClass: "form-row" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.allow_concurrent_logins,
                        expression: "user.allow_concurrent_logins"
                      }
                    ],
                    staticClass: "form-control-sm autocomplete-off",
                    attrs: { id: "concurrentLogins", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.user.allow_concurrent_logins)
                        ? _vm._i(_vm.user.allow_concurrent_logins, null) > -1
                        : _vm.user.allow_concurrent_logins
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.user.allow_concurrent_logins,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.user,
                                "allow_concurrent_logins",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.user,
                                "allow_concurrent_logins",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.user, "allow_concurrent_logins", $$c)
                        }
                      }
                    }
                  }),
                  _c("label", { attrs: { for: "concurrentLogins" } }, [
                    _vm._v(" Allow Concurrent Logins")
                  ])
                ])
              ])
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "form-row" }),
      _c("div", { staticClass: "form-row" }, [
        _c("div", { staticClass: "form-group col-md-6" }, [
          _c("label", { attrs: { for: "email" } }, [_vm._v("Email")]),
          _c(
            "div",
            [
              _vm.user && _vm.user.id > 0
                ? _c("b-input", {
                    ref: "verifiedEmail",
                    staticClass:
                      "form-control form-control-sm autocomplete-off",
                    attrs: {
                      id: "email",
                      disabled: true,
                      state: !!_vm.user.verification_finished
                    },
                    on: { blur: _vm.emailCheck },
                    model: {
                      value: _vm.user.email,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "email", $$v)
                      },
                      expression: "user.email"
                    }
                  })
                : _vm._e(),
              !_vm.user || _vm.user.id < 0
                ? _c("b-input", {
                    ref: "verifiedEmail",
                    staticClass:
                      "form-control form-control-sm autocomplete-off",
                    attrs: { id: "email", disabled: false },
                    model: {
                      value: _vm.user.email,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "email", $$v)
                      },
                      expression: "user.email"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form-group col-md-6" }, [
          _c("label", { attrs: { for: "userUsername" } }, [_vm._v("Username")]),
          _vm.user && _vm.user.id > 0
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.username,
                    expression: "user.username"
                  }
                ],
                ref: "username",
                staticClass: "form-control form-control-sm autocomplete-off",
                attrs: { id: "userUsername", disabled: true, type: "text" },
                domProps: { value: _vm.user.username },
                on: {
                  blur: _vm.checkUsername,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.user, "username", $event.target.value)
                  }
                }
              })
            : _vm._e(),
          !_vm.user || _vm.user.id < 0
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.username,
                    expression: "user.username"
                  }
                ],
                ref: "username",
                staticClass: "form-control form-control-sm autocomplete-off",
                attrs: { id: "userUsername", disabled: false, type: "text" },
                domProps: { value: _vm.user.username },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.user, "username", $event.target.value)
                  }
                }
              })
            : _vm._e()
        ]),
        _vm.user && _vm.user.id > 0 && _vm.adminCheck()
          ? _c("div", { staticClass: "form-group col-md-6" }, [
              _c("div", { staticClass: "float-right" }, [
                _c("span", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.overwritePassword,
                        expression: "overwritePassword"
                      }
                    ],
                    staticClass: "form-control-sm autocomplete-off",
                    attrs: { id: "overwritePass", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.overwritePassword)
                        ? _vm._i(_vm.overwritePassword, null) > -1
                        : _vm.overwritePassword
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.overwritePassword,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.overwritePassword = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.overwritePassword = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.overwritePassword = $$c
                        }
                      }
                    }
                  }),
                  _c("label", { attrs: { for: "overwritePass" } }, [
                    _vm._v(" OVERWRITE PASSWORD")
                  ])
                ])
              ]),
              _c("div", [
                _vm.overwritePassword
                  ? _c("span", [
                      _c("label", { attrs: { for: "newPass" } }, [
                        _vm._v("NEW PASSWORD")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newPassword,
                            expression: "newPassword"
                          }
                        ],
                        staticClass:
                          "form-control form-control-sm autocomplete-off",
                        attrs: { id: "newPass", type: "text" },
                        domProps: { value: _vm.newPassword },
                        on: {
                          blur: _vm.showPasswordWarning,
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.newPassword = $event.target.value
                          }
                        }
                      })
                    ])
                  : _vm._e()
              ])
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "form-row" }, [
        _c(
          "div",
          { staticClass: "form-group col-md-6" },
          [
            _c("label", { attrs: { for: "timezoneSelect" } }, [
              _vm._v("TIMEZONE")
            ]),
            _c("MultiSelectDropdown", {
              attrs: {
                objects: _vm.timezones,
                selectedValues: _vm.selectedTimeZone,
                title: "Select Timezone",
                singleSelection: true,
                closeOnSelect: true
              },
              on: {
                "update:selectedValues": function($event) {
                  _vm.selectedTimeZone = $event
                },
                "update:selected-values": function($event) {
                  _vm.selectedTimeZone = $event
                }
              }
            })
          ],
          1
        )
      ]),
      !_vm.user || _vm.user.id < 0
        ? _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-md-6" }, [
              _c("label", { attrs: { for: "password" } }, [_vm._v("Password")]),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.password,
                      expression: "user.password"
                    }
                  ],
                  ref: "newPassword",
                  staticClass: "form-control form-control-sm autocomplete-off",
                  attrs: { id: "newPassword", type: "text" },
                  domProps: { value: _vm.user.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "password", $event.target.value)
                    }
                  }
                }),
                _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v(_vm._s(_vm.passwordStrengthErrorMessage))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.adminCheck() && _vm.user.id > 0
        ? _c(
            "div",
            {
              staticClass: "form-row",
              staticStyle: { "margin-top": "5px", "margin-left": "2px" }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.nags_user,
                    expression: "user.nags_user"
                  }
                ],
                staticClass: "form-control-sm autocomplete-off",
                attrs: { id: "nagsUser", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.user.nags_user)
                    ? _vm._i(_vm.user.nags_user, null) > -1
                    : _vm.user.nags_user
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.user.nags_user,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.user, "nags_user", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.user,
                            "nags_user",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.user, "nags_user", $$c)
                    }
                  }
                }
              }),
              _c("label", { attrs: { for: "nagsUser" } }, [
                _vm._v(" Nags User")
              ])
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "form-row" }, [
        _vm.adminCheck() && _vm.user.id >= 0
          ? _c("div", { staticClass: "form-group col-md-6" }, [
              _c("label", [_c("strong", [_vm._v("NAGS")])]),
              _c("br"),
              _vm.user.id < 0
                ? _c("span", [
                    _vm._v(
                      "Save this user first in order to add a NAGS License..."
                    )
                  ])
                : _vm._e(),
              _vm.user.id >= 0 && _vm.loadingNagsLicense
                ? _c("span", [_vm._v("Loading...")])
                : _vm._e(),
              _vm.user.id >= 0 &&
              !_vm.loadingNagsLicense &&
              _vm.nagsLicense.length > 0
                ? _c(
                    "span",
                    _vm._l(_vm.nagsLicense, function(license, index) {
                      return _c("span", { key: index }, [
                        _c(
                          "a",
                          {
                            staticClass: "nags-license-badge",
                            staticStyle: {
                              color: "white",
                              background: "#299fd7",
                              cursor: "pointer"
                            },
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.openNagsLicense(license)
                              }
                            }
                          },
                          [
                            _c("flag", {
                              attrs: {
                                squared: false,
                                iso: license.nags_license_type.flag_iso
                              }
                            }),
                            _vm._v(" License #: " + _vm._s(license.id) + " ")
                          ],
                          1
                        )
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _vm.user.id >= 0 &&
              !_vm.loadingNagsLicense &&
              _vm.nagsLicense.length === 0
                ? _c("span", [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.openNagsLicense(null)
                          }
                        }
                      },
                      [_vm._v("Create New License")]
                    )
                  ])
                : _vm._e()
            ])
          : _vm.user.id < 0
          ? _c("div", { staticClass: "form-group col-md-6" }, [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-md-5" }, [
                  _c("label", [_c("strong", [_vm._v("NAGS User")])]),
                  _c("br"),
                  _c("div", { staticClass: "form-check form-check-inline" }, [
                    _c("label", { staticClass: "form-check-label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.createNagsLicense,
                            expression: "createNagsLicense"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: { type: "radio", value: "yes" },
                        domProps: {
                          checked: _vm._q(_vm.createNagsLicense, "yes")
                        },
                        on: {
                          change: function($event) {
                            _vm.createNagsLicense = "yes"
                          }
                        }
                      }),
                      _vm._v(" Yes ")
                    ])
                  ]),
                  _c("div", { staticClass: "form-check form-check-inline" }, [
                    _c("label", { staticClass: "form-check-label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.createNagsLicense,
                            expression: "createNagsLicense"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: { type: "radio", value: "no" },
                        domProps: {
                          checked: _vm._q(_vm.createNagsLicense, "no")
                        },
                        on: {
                          change: function($event) {
                            _vm.createNagsLicense = "no"
                          }
                        }
                      }),
                      _vm._v(" No ")
                    ])
                  ])
                ]),
                _vm.createNagsLicense === "yes"
                  ? _c("div", { staticClass: "form-group col-md-7" }, [
                      _c("label", [_c("strong", [_vm._v("NAGS Type")])]),
                      _c("br"),
                      _c(
                        "div",
                        { staticClass: "form-check form-check-inline" },
                        [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.nagsLicenseTypeId,
                                  expression: "nagsLicenseTypeId"
                                }
                              ],
                              staticClass: "form-control form-control-sm",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.nagsLicenseTypeId = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            _vm._l(_vm.nagsLicenseTypes, function(licenseType) {
                              return _c(
                                "option",
                                {
                                  key: licenseType.id,
                                  domProps: { value: licenseType.id }
                                },
                                [_vm._v(_vm._s(licenseType.name))]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ])
            ])
          : _c("div", { staticClass: "form-group col-md-6" }),
        _c("div", { staticClass: "form-group col-md-6" }, [
          _c(
            "div",
            { staticClass: "float-right" },
            [
              _c("label", [_vm._v("Note color")]),
              _c("br"),
              _c("Sketch", {
                attrs: { id: "sketch-picker" },
                model: {
                  value: _vm.userColor,
                  callback: function($$v) {
                    _vm.userColor = $$v
                  },
                  expression: "userColor"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "form-row" }, [
        _c("div", { staticClass: "form-group col-md-12" }, [
          _c("label", { attrs: { for: "userNotes" } }, [_vm._v("Notes")]),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.notes,
                expression: "user.notes"
              }
            ],
            staticClass: "form-control form-control-sm autocomplete-off",
            attrs: { id: "userNotes", rows: "4" },
            domProps: { value: _vm.user.notes },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "notes", $event.target.value)
              }
            }
          })
        ])
      ]),
      _c("div", { staticClass: "form-row" }, [
        _c(
          "div",
          { staticClass: "form-group col-md-12" },
          [
            _vm.attachedShops
              ? _c("user-dialog-shop-transfer", {
                  attrs: {
                    options: _vm.localMasterList,
                    attached: _vm.attachedShops,
                    userId: _vm.user.id
                  },
                  on: {
                    "update:attached": function($event) {
                      _vm.attachedShops = $event
                    },
                    changed: _vm.handleUserShopsChange
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-4" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "defaultShop" } }, [
              _vm._v("Default Shop")
            ]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.shop_id,
                    expression: "user.shop_id"
                  }
                ],
                staticClass: "form-control form-control-sm",
                attrs: { id: "defaultShop" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.user,
                      "shop_id",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c("option", { attrs: { value: "" } }, [
                  _vm._v("Select shop...")
                ]),
                _vm._l(_vm.attachedShops, function(shop) {
                  return _c(
                    "option",
                    {
                      key: "default-shop" + shop.id,
                      domProps: { value: shop.id }
                    },
                    [_vm._v(_vm._s(shop.name))]
                  )
                })
              ],
              2
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "form-group col-md-8" },
          [
            _c("multi-select-with-group-dropdown", {
              attrs: {
                objects: _vm.csrs,
                selectedValues: _vm.selectedCSRs,
                title: "Default CSR",
                trackBy: "shopIdCombo"
              },
              on: {
                "update:selectedValues": function($event) {
                  _vm.selectedCSRs = $event
                },
                "update:selected-values": function($event) {
                  _vm.selectedCSRs = $event
                }
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "form-row" }, [
        _c(
          "div",
          { staticClass: "form-group col-md-12" },
          [
            _c("MultiSelectDropdown", {
              attrs: {
                objects: _vm.relatedTwilioPhoneNumbers,
                selectedValues: _vm.selectedTwilioPhoneNumbers,
                title: "Select text messaging phone numbers"
              },
              on: {
                "update:selectedValues": function($event) {
                  _vm.selectedTwilioPhoneNumbers = $event
                },
                "update:selected-values": function($event) {
                  _vm.selectedTwilioPhoneNumbers = $event
                }
              }
            })
          ],
          1
        )
      ]),
      _vm.adminCheck()
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("label", { attrs: { for: "anniversaryDate" } }, [
                  _vm._v("Anniversary Date")
                ]),
                _c("DatePickerShortcut", {
                  attrs: {
                    id: "anniversaryDate",
                    value: _vm.user.anniversary_date,
                    displayFormat: "MM/dd/yyyy",
                    saveFormat: "yyyy-MM-dd"
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(_vm.user, "anniversary_date", $event)
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("label", { attrs: { for: "reportedDate" } }, [
                  _vm._v("Reported Date")
                ]),
                _c("DatePickerShortcut", {
                  attrs: {
                    id: "reportedDate",
                    value: _vm.user.reported_date,
                    displayFormat: "MM/dd/yyyy",
                    saveFormat: "yyyy-MM-dd"
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(_vm.user, "reported_date", $event)
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("label", { attrs: { for: "chargeAmount" } }, [
                  _vm._v("Charge Amount")
                ]),
                _c("FloatInput", {
                  ref: "userChargeAmount",
                  attrs: {
                    value: _vm.user.charge,
                    minimumDecimalPlaces: 2,
                    maximumDecimalPlaces: 2,
                    formattedPrefix: "$ ",
                    textAlign: "left"
                  },
                  on: {
                    "update:value": function($event) {
                      return _vm.$set(_vm.user, "charge", $event)
                    }
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.adminCheck()
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-6 mt-3" },
              [
                _c("label", { attrs: { for: "faxAddButton" } }, [
                  _vm._v("Fax Numbers")
                ]),
                _c(
                  "b-button",
                  {
                    staticClass: "label label-gray label-sm",
                    attrs: { id: "faxAddButton", size: "sm" },
                    on: { click: _vm.addFaxClicked }
                  },
                  [_vm._v("+ NEW")]
                )
              ],
              1
            ),
            _vm.faxNumbers.length > 1
              ? _c("div", { staticClass: "col-md-6" }, [
                  _c("label", [_vm._v("Default Outgoing Fax")]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.userFaxNumber,
                          expression: "userFaxNumber"
                        }
                      ],
                      staticClass:
                        "form-control form-control-sm autocomplete-off",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.userFaxNumber = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.faxNumbers, function(faxNumber) {
                      return _c(
                        "option",
                        {
                          key: faxNumber.id,
                          domProps: { value: faxNumber.id }
                        },
                        [_vm._v(_vm._s(_vm.phoneFormat(faxNumber.fax)))]
                      )
                    }),
                    0
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm.adminCheck()
        ? _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.faxNumbers, function(fax, index) {
              return _c(
                "div",
                { key: index, staticClass: "form-group col-md-4 mt-2" },
                [
                  _c("InputWithDelete", {
                    attrs: { type: "phone", parentValue: fax.fax },
                    on: {
                      "update:parentValue": function($event) {
                        return _vm.$set(fax, "fax", $event)
                      },
                      "update:parent-value": function($event) {
                        return _vm.$set(fax, "fax", $event)
                      },
                      remove: function($event) {
                        return _vm.removeFax(index)
                      }
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "form-group col-md-4 mt-2" },
          [
            _c("CheckBoxLabel", {
              attrs: {
                label: "Auto Search Nags",
                value: _vm.user.auto_redo_nags_search
              },
              on: {
                "update:value": function($event) {
                  return _vm.$set(_vm.user, "auto_redo_nags_search", $event)
                }
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-4 mt-2" }, [
          _c("label", { attrs: { for: "userRecentJobCount" } }, [
            _vm._v("Recent Job Count (Set to 0 to disable)")
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.recent_job_count,
                expression: "user.recent_job_count"
              }
            ],
            ref: "userRecentJobCount",
            staticClass: "form-control form-control-sm",
            staticStyle: { width: "100%" },
            attrs: { type: "number", min: "0", max: "30" },
            domProps: { value: _vm.user.recent_job_count },
            on: {
              blur: _vm.recentJobCountCheck,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "recent_job_count", $event.target.value)
              }
            }
          })
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12 mt-2" },
          [
            _c("label", [_vm._v("User Permissions")]),
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.showPermissions = !_vm.showPermissions
                  }
                }
              },
              [
                !_vm.showPermissions
                  ? _c("i", {
                      staticClass: "fa fa-caret-left fa-2x",
                      staticStyle: { color: "dodgerblue" }
                    })
                  : _vm._e(),
                _vm.showPermissions
                  ? _c("i", {
                      staticClass: "fa fa-caret-down fa-2x",
                      staticStyle: { color: "dodgerblue" }
                    })
                  : _vm._e()
              ]
            ),
            _c(
              "b-collapse",
              {
                attrs: { id: "permissions-collapse" },
                model: {
                  value: _vm.showPermissions,
                  callback: function($$v) {
                    _vm.showPermissions = $$v
                  },
                  expression: "showPermissions"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "p-2",
                    staticStyle: {
                      border: "2px solid lightgray",
                      "border-radius": "5px"
                    }
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "job" },
                          domProps: { checked: _vm.isPermissionChecked("job") },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "jobPermission" } }, [
                          _vm._v("Job")
                        ])
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "jobmanager" },
                          domProps: {
                            checked: _vm.isPermissionChecked("jobmanager")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "jobmanagerPermission" } },
                          [_vm._v("Job Manager")]
                        )
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "managecsrs" },
                          domProps: {
                            checked: _vm.isPermissionChecked("managecsrs")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "managecsrsPermission" } },
                          [_vm._v("CSR")]
                        )
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "managesalesreps" },
                          domProps: {
                            checked: _vm.isPermissionChecked("managesalesreps")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "managesalesrepsPermission" } },
                          [_vm._v("Sales Rep")]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "managetechs" },
                          domProps: {
                            checked: _vm.isPermissionChecked("managetechs")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "managetechsPermission" } },
                          [_vm._v("Tech")]
                        )
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "managesalesources" },
                          domProps: {
                            checked: _vm.isPermissionChecked(
                              "managesalesources"
                            )
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "managesalesourcesPermission" } },
                          [_vm._v("Sale Source")]
                        )
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "managelocations" },
                          domProps: {
                            checked: _vm.isPermissionChecked("managelocations")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "managelocationsPermission" } },
                          [_vm._v("Location")]
                        )
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "manageinsurance" },
                          domProps: {
                            checked: _vm.isPermissionChecked("manageinsurance")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "manageinsurancePermission" } },
                          [_vm._v("Insurance/Fleet")]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: {
                            type: "checkbox",
                            id: "managecommercialaccounts"
                          },
                          domProps: {
                            checked: _vm.isPermissionChecked(
                              "managecommercialaccounts"
                            )
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            attrs: { for: "managecommercialaccountsPermission" }
                          },
                          [_vm._v(" Comm. Acct")]
                        )
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "managetaxes" },
                          domProps: {
                            checked: _vm.isPermissionChecked("managetaxes")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "managetaxesPermission" } },
                          [_vm._v("Shop Taxes")]
                        )
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "ar_ap" },
                          domProps: {
                            checked: _vm.isPermissionChecked("ar_ap")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "ar_apPermission" } }, [
                          _vm._v("AR/AP")
                        ])
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "reports" },
                          domProps: {
                            checked: _vm.isPermissionChecked("reports")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "reportsPermission" } }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0)" },
                              on: { click: _vm.openReportPermissions }
                            },
                            [
                              _vm._v("Reports "),
                              _c("v-icon", {
                                attrs: { name: "cog", scale: "0.6" }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "accounts" },
                          domProps: {
                            checked: _vm.isPermissionChecked("accounts")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "accountsPermission" } }, [
                          _vm._v("Accounts")
                        ])
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "shops" },
                          domProps: {
                            checked: _vm.isPermissionChecked("shops")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "shopsPermission" } }, [
                          _vm._v("Shops")
                        ])
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "users" },
                          domProps: {
                            checked: _vm.isPermissionChecked("users")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "usersPermission" } }, [
                          _vm._v("Users")
                        ])
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "jobvoid" },
                          domProps: {
                            checked: _vm.isPermissionChecked("jobvoid")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "jobvoid" } }, [
                          _vm._v("Void Job")
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "graph" },
                          domProps: {
                            checked: _vm.isPermissionChecked("graph")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "graphPermission" } }, [
                          _vm._v("Revenue Dashboard")
                        ])
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "pricing" },
                          domProps: {
                            checked: _vm.isPermissionChecked("pricing")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "pricingPermission" } }, [
                          _vm._v("Pricing")
                        ])
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "commission" },
                          domProps: {
                            checked: _vm.isPermissionChecked("commission")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "commissionPermission" } },
                          [_vm._v("View Commissions")]
                        )
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "jobunvoid" },
                          domProps: {
                            checked: _vm.isPermissionChecked("jobunvoid")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "jobunvoid" } }, [
                          _vm._v("Unvoid Job")
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "viewparts" },
                          domProps: {
                            checked: _vm.isPermissionChecked("viewparts")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "viewpartsPermission" } }, [
                          _vm._v("View Parts")
                        ])
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "accountingpackages" },
                          domProps: {
                            checked: _vm.isPermissionChecked(
                              "accountingpackages"
                            )
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "accountingpackagesPermission" } },
                          [_vm._v("Accounting Packages")]
                        )
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "rightpart" },
                          domProps: {
                            checked: _vm.isPermissionChecked("rightpart")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "rightpart" } }, [
                          _vm._v("Right Part")
                        ])
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "jobviewonly" },
                          domProps: {
                            checked: _vm.isPermissionChecked("jobviewonly")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "jobviewonly" } }, [
                          _vm._v("Job View Only")
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "warehouse" },
                          domProps: {
                            checked: _vm.isPermissionChecked("warehouse")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "warehousePermission" } }, [
                          _vm._v("Warehouse")
                        ])
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "scheduler" },
                          domProps: {
                            checked: _vm.isPermissionChecked("scheduler")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "scheduler" } }, [
                          _vm._v("Scheduler")
                        ])
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "setjobdate" },
                          domProps: {
                            checked: _vm.isPermissionChecked("setjobdate")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "setjobdate" } }, [
                          _vm._v("Set Job Date")
                        ])
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "discarddraft" },
                          domProps: {
                            checked: _vm.isPermissionChecked("discarddraft")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "discarddraft" } }, [
                          _vm._v("Discard Draft")
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "datatab" },
                          domProps: {
                            checked: _vm.isPermissionChecked("datatab")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "datatabPermission" } }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0)" },
                              on: { click: _vm.openDatatabPermissions }
                            },
                            [
                              _vm._v("Data "),
                              _c("v-icon", {
                                attrs: { name: "cog", scale: "0.6" }
                              })
                            ],
                            1
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: {
                            type: "checkbox",
                            id: "notificationsettings"
                          },
                          domProps: {
                            checked: _vm.isPermissionChecked(
                              "notificationsettings"
                            )
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "notificationsettings" } },
                          [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: _vm.openNotificationSettingsPermissions
                                }
                              },
                              [
                                _vm._v("Notification Settings "),
                                _c("v-icon", {
                                  attrs: { name: "cog", scale: "0.6" }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("input", {
                          attrs: { type: "checkbox", id: "jobmargin" },
                          domProps: {
                            checked: _vm.isPermissionChecked("jobmargin")
                          },
                          on: { change: _vm.changePermission }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "jobmargin" } }, [
                          _vm._v("Job Margin")
                        ])
                      ])
                    ])
                  ]
                )
              ]
            )
          ],
          1
        )
      ]),
      _vm.user.id < 0
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12 mt-2" }, [
              _c("label", [_vm._v("Billing")]),
              _vm.loadingBillingDetails
                ? _c("p", [_vm._v("Loading...")])
                : _vm._e(),
              !_vm.loadingBillingDetails
                ? _c("p", [
                    _c("b", [_vm._v("Account:")]),
                    _vm._v(" " + _vm._s(_vm.account_name))
                  ])
                : _vm._e(),
              !_vm.loadingBillingDetails
                ? _c("p", [
                    _c("b", [_vm._v("Current Fee:")]),
                    _vm._v(" " + _vm._s(_vm.current_fee))
                  ])
                : _vm._e(),
              !_vm.loadingBillingDetails
                ? _c("p", [
                    _c("b", [_vm._v("Price for New User:")]),
                    _vm._v(" " + _vm._s(_vm.new_user_fee))
                  ])
                : _vm._e(),
              !_vm.loadingBillingDetails
                ? _c("p", [
                    _c("b", [_vm._v("New Fee:")]),
                    _vm._v(" " + _vm._s(_vm.new_fee))
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "w-100",
          attrs: { slot: "modal-footer" },
          slot: "modal-footer"
        },
        [
          _vm.saving || _vm.loading
            ? _c("div", { staticClass: "float-left" }, [
                _c("i", {
                  staticClass: "fa fa-spinner fa-spin",
                  staticStyle: { "font-size": "24px" }
                }),
                _vm._v(" " + _vm._s(_vm.spinnerTitle) + " ")
              ])
            : _vm._e(),
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button", variant: "primary" },
                on: { click: _vm.saveClicked }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.user && _vm.user.id >= 0 ? "Save" : "Confirm & Create"
                  )
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button", variant: "primary" },
                on: { click: _vm.cancelClicked }
              },
              [_vm._v("Cancel")]
            )
          ])
        ]
      ),
      _c("report-permissions-dialog", {
        attrs: {
          visible: _vm.reportPermissionsOpen,
          userId: _vm.user.id,
          userPermissions: _vm.userPermissions
        },
        on: {
          "update:visible": function($event) {
            _vm.reportPermissionsOpen = $event
          },
          refreshPermissions: _vm.refreshPermissions
        }
      }),
      _c("data-tab-permissions-dialog", {
        attrs: {
          visible: _vm.datatabPermissionsOpen,
          userId: _vm.user.id,
          userPermissions: _vm.userPermissions
        },
        on: {
          "update:visible": function($event) {
            _vm.datatabPermissionsOpen = $event
          },
          refreshPermissions: _vm.refreshPermissions
        }
      }),
      _c("notification-setting-permissions-dialog", {
        attrs: {
          visible: _vm.notificationSettingPermissionsOpen,
          userId: _vm.user.id,
          userPermissions: _vm.userPermissions
        },
        on: {
          "update:visible": function($event) {
            _vm.notificationSettingPermissionsOpen = $event
          },
          refreshPermissions: _vm.refreshPermissions
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }