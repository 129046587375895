var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isJobsPage
    ? _c(
        "div",
        [
          _c("JobtagDialog", {
            attrs: {
              tags: _vm.editTagTags,
              jobtags: _vm.editTagJobTags,
              editTag: _vm.editTag,
              job_id: _vm.editTagJobId,
              shop_id: _vm.editTagShopId
            },
            on: {
              "update:editTag": function($event) {
                _vm.editTag = $event
              },
              "update:edit-tag": function($event) {
                _vm.editTag = $event
              }
            }
          }),
          _c("TagDialog", {
            attrs: { editTag: _vm.editTag, shopId: _vm.editTagShopId }
          }),
          _c("LoadingDialog", {
            attrs: {
              id: "'jobslist-action'",
              isOpen: _vm.showLoadingDialog,
              message: _vm.loadingDialogMessage
            }
          }),
          _c("EdiStatusDialog", {
            attrs: {
              modalId: "jobslistEdiStatusModal",
              ediStatusChangeModal: "jobslistEdiStatusChangeModal",
              ediqueue: _vm.selectedEdiqueue,
              job_id: _vm.selectedEdiJobId,
              job_shop_id: _vm.selectedEdiShopId
            }
          }),
          _c("EdiStatusChangeDialog", {
            attrs: {
              modalId: "jobslistEdiStatusChangeModal",
              model_to_return_to: "jobslistEdiStatusModal",
              job_id: _vm.selectedEdiJobId
            }
          }),
          _c("DocumentDialog", {
            attrs: {
              documents: _vm.selectedDocuments,
              filePickerKey: _vm.filePickerKey,
              shop_id: _vm.selectedDocumentShopId,
              job_id: _vm.selectedDocumentJobId
            }
          }),
          _c("NotesDialog", {
            attrs: {
              notes: _vm.selectedNotesNotes,
              users: _vm.selectedNotesUsers,
              job_id: _vm.selectedNotesJobId,
              shop_id: _vm.selectedNotesShopId
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }