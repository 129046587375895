var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scheduler-map-container" },
    [
      _c("scheduler-map-tech-filters", {
        on: { "center-map": _vm.centerMapTo }
      }),
      _c(
        "gmap-map",
        _vm._b({ ref: "mapRef" }, "gmap-map", _vm.defaultValues, false),
        [
          _vm._l(_vm.polylines, function(polyline) {
            return _vm.layerFilter.polylines
              ? _c(
                  "gmap-polyline",
                  _vm._b({ key: polyline.id }, "gmap-polyline", polyline, false)
                )
              : _vm._e()
          }),
          _vm._l(_vm.visibleWarehouses, function(marker) {
            return _vm.layerFilter.warehouses
              ? _c(
                  "gmap-marker",
                  _vm._b(
                    {
                      key: marker.id + "warehouse",
                      attrs: { visible: marker.visible, "z-index": 10 },
                      on: {
                        mouseover: function($event) {
                          return _vm.showInfowindow(marker)
                        },
                        mouseout: function($event) {
                          return _vm.showInfowindow(null)
                        }
                      }
                    },
                    "gmap-marker",
                    _vm.warehouseMarkerOptions(marker),
                    false
                  )
                )
              : _vm._e()
          }),
          _vm._l(_vm.combinedStops, function(values) {
            return [
              _vm._l(values, function(stop) {
                return _c("gmap-marker", {
                  key: stop.type + "_" + stop.details.id + "_icon",
                  attrs: {
                    position: {
                      lat: stop.details.g_address.lat,
                      lng: stop.details.g_address.lng
                    },
                    icon: _vm.getMapStopIcon(stop),
                    "z-index": 15
                  },
                  on: {
                    mouseover: function($event) {
                      return _vm.showInfowindow(stop)
                    },
                    mouseout: function($event) {
                      return _vm.showInfowindow(null)
                    }
                  }
                })
              }),
              _vm.map
                ? _vm._l(values, function(stop) {
                    return _c(
                      "scheduler-map-route-stop-overlay",
                      _vm._b(
                        {
                          key: stop.type + "_" + stop.details.id + "_content",
                          attrs: { map: _vm.map }
                        },
                        "scheduler-map-route-stop-overlay",
                        stop,
                        false
                      )
                    )
                  })
                : _vm._e()
            ]
          }),
          _vm._l(_vm.data, function(route) {
            return [
              !route.hidden
                ? _vm._l(_vm.getRouteMarkers(route), function(marker) {
                    return _vm.layerFilter.jobs
                      ? _c(
                          "gmap-marker",
                          _vm._b(
                            {
                              key: marker.id + "route_entry",
                              ref: marker.id,
                              refInFor: true,
                              attrs: { draggable: "", "z-index": 20 },
                              on: {
                                click: function($event) {
                                  return _vm.openDetails(marker)
                                },
                                mouseover: function($event) {
                                  return _vm.showInfowindow(marker)
                                },
                                mouseout: function($event) {
                                  return _vm.showInfowindow(null)
                                },
                                drag: function($event) {
                                  return _vm.dragmarker(marker, route, $event)
                                },
                                dragstart: function($event) {
                                  _vm.dragging = true
                                },
                                dragend: function($event) {
                                  return _vm.dragmarkerend(
                                    marker,
                                    route,
                                    $event
                                  )
                                }
                              }
                            },
                            "gmap-marker",
                            marker,
                            false
                          )
                        )
                      : _vm._e()
                  })
                : _vm._e()
            ]
          }),
          _vm.map && _vm.infowindow && !_vm.dragging
            ? _c("scheduler-map-infowindow", {
                attrs: { map: _vm.map, marker: _vm.infowindow }
              })
            : _vm._e(),
          _vm._l(_vm.techLocations, function(loc, username) {
            return [
              _c(
                "gmap-marker",
                _vm._b(
                  {
                    key: "username + " + username,
                    attrs: { "z-index": 30 },
                    on: {
                      mouseover: function($event) {
                        _vm.techInfowindow = {
                          location: loc,
                          username: username
                        }
                      },
                      mouseout: function($event) {
                        _vm.techInfowindow = null
                      }
                    }
                  },
                  "gmap-marker",
                  _vm.getTechMarkerOptions(loc, _vm.techsideColors[username]),
                  false
                )
              )
            ]
          }),
          _vm.map && _vm.techInfowindow && !_vm.dragging
            ? _c(
                "scheduler-tech-location-infowindow",
                _vm._b(
                  { attrs: { map: _vm.map } },
                  "scheduler-tech-location-infowindow",
                  _vm.techInfowindow,
                  false
                )
              )
            : _vm._e()
        ],
        2
      ),
      _c("scheduler-map-notifications"),
      _c(
        "div",
        { staticClass: "center-map-button-container" },
        [
          _c(
            "el-button",
            { attrs: { plain: "" }, on: { click: _vm.centerMap } },
            [_vm._v("Reset map to initial view")]
          )
        ],
        1
      ),
      _c("scheduler-unmapped-jobs", {
        on: { "item-clicked": _vm.openDetails }
      }),
      _c("map-tech-live-locations")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }