var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-v2-user-menu" },
    [
      _c(
        "div",
        { staticClass: "header-v2-user-title" },
        [
          _vm._v(" " + _vm._s(_vm.user.username) + " "),
          _vm.isNagsUser
            ? _c("gb-tag", { attrs: { color: "blue" } }, [_vm._v("NAGS")])
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-menu",
        {
          attrs: {
            "default-active": "2",
            "background-color": "#272727",
            "text-color": "#fff",
            "active-text-color": "#ffd04b"
          }
        },
        _vm._l(_vm.menuConfig, function(item) {
          var _obj
          return _c(
            "el-menu-item",
            {
              key: item.key,
              attrs: { index: item.key },
              on: {
                click: function($event) {
                  return _vm.menuSelect(item)
                }
              }
            },
            [
              item.icon
                ? _c("i", {
                    staticClass: "material-icons",
                    class: ((_obj = {}), (_obj["md-" + item.icon] = true), _obj)
                  })
                : _vm._e(),
              item.image ? _c("img", { attrs: { src: item.image } }) : _vm._e(),
              _vm._v(" " + _vm._s(item.label) + " ")
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }