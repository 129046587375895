<template>
  <div id="app">
    <loading-screen v-if="showLoading" />
    <template v-else>
      <JobDialogsForVue3 :filePickerKey="filePickerKey" />

      <div
        class="glassbiller-container"
        :class="{ 'glassbiller-fixed-layout': $route.meta.fixedLayout }"
      >
        <PageHeader
          v-if="!isPublicRoute"
          :title="headerTitle"
          :allowQuickBooks="true"
          :changeDetected="isChangesDetected"
          :filePickerKey="filePickerKey"
        />

        <div class="glassbiller-content-wrapper">
          <side-bar-v2 v-if="!isPublicRoute && !hasTechCommissionPermission" />
          <div class="glassbiller-content">
            <div class="glassbiller-page-wrapper">
              <router-view
                v-if="userAccountIsActive"
                :key="$route.fullPath"
                :filePickerKey="filePickerKey"
                @changesDetected="changesDetected"
                @updateHeaderTitle="updateHeaderTitle"
                @loading="loading"
              />
            </div>
          </div>
        </div>
      </div>

      <template v-if="!isPublicRoute">
        <NotificationDialog />
        <UnsavedChangesDialog :url="url" />
        <GlobalNotification />
        <scheduler-dialog />
        <DocumentDialogDynamic :filePickerKey="filePickerKey" />
        <DocumentPopover
          :showPopover="showDocumentPopover"
          :filePickerKey="filePickerKey"
          @popoverShown="showDocumentPopover = false"
        />
      </template>

      <shop-rates-dialog
        v-if="shopRateData"
        v-model="showShopRateDialog"
        :rate="shopRateData.rate"
        :shopRates="shopRateData.shopRates"
        :shop_id="shopRateData.shopId"
        :defaultName="shopRateData.defaultName"
        :applyRateMethod="shopRateData.applyRateMethod"
        :autoApply="true"
      />
      <AlertDialog />
      <nielsen-moller-alert-dialog
        :visible.sync="nmAlertVisible"
        :shopId.sync="nmAlertShopId"
        :syncShopId="true"
      />

      <!-- We need this here so we can target the popover header more easily -->
      <!-- <div id="warehouseMygrantWrapper"></div>
      <div id="warehousePilkingtonWrapper"></div> -->
      <!-- <acknowledgement-alert
        :messages="componentsToShowInAcknowledgeDialog"
        :visible='showAcknowledgmentDialog'
        :required='true'
        @acknowledged='acknowledgeTermsAndConditions'
        button_text='Accept Policies, Terms and Conditions'
        :title="'IMPORTANT: Updated Terms of Use Agreement' + ($store.state.user.nags_price_increase_acknowledged !== 1 ? ' and Notification of price increase.' : '')"
      /> -->
    </template>
  </div>
</template>

<script>
import PageHeader from '@/components/inc/HeaderV2/Index.vue'
import GlobalNotification from '@/components/GlobalNotification/GlobalNotification.vue'
import User from '@/scripts/objects/user'
import AlertDialog from '@/components/modals/AlertDialog.vue'
import UnsavedChangesDialog from '@/components/modals/UnsavedChangesDialog.vue'
import API from '@/services/Api'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import NotificationDialog from '@/components/modals/NotificationDialog.vue'
import {
  schedulerNamespacedActions,
  schedulerNamespacedMutations
} from '@/store/modules/scheduler/scheduler.types'
import SchedulerDialog from '@/components/modals/SchedulerDialog'
import ShopRatesDialog from '@/components/JobPageV2/helpers/dialogs/ShopRatesDialog'
import DocumentDialogDynamic from '@/components/DynamicDocument/DocumentDialogDynamic.vue'
import DocumentPopover from '@/components/DynamicDocument/DocumentPopover.vue'
import VueCookies from 'vue-cookies'
import axios from 'axios'
import { mapActions } from 'pinia'
import pusher from './services/PusherService'
import { call, get, sync } from 'vuex-pathify'
import { shoppingCartActions } from '@/store/modules/shopping-cart/types'
import SideBarV2 from '@/components/inc/SideBarV2/Index'
import LoadingScreen from '@/components/Helpers/LoadingScreen'
import '@material-icons/font/css/all.css'
import {
  permissions,
  permissionCheck
} from '@/scripts/helpers/permissions.helpers'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'
import SoftwareContractGlassbiller from '@/components/alertText/SoftwareContractGlassbiller_2_.vue'
import NagsPriceIncrease from '@/components/alertText/NagsPriceIncrease.vue'
import NielsenMollerAlertDialog from '@/components/modals/NielsenMollerAlertDialog.vue'
import JobDialogsForVue3 from '@/components/DataTab/JobsListV2/JobDialogsForVue3.vue'

export default {
  name: 'App',

  mixins: [UtilityMixin],

  components: {
    PageHeader,
    AlertDialog,
    GlobalNotification,
    NotificationDialog,
    UnsavedChangesDialog,
    SchedulerDialog,
    DocumentDialogDynamic,
    DocumentPopover,
    SideBarV2,
    LoadingScreen,
    NielsenMollerAlertDialog,
    ShopRatesDialog,
    JobDialogsForVue3
  },

  data() {
    return {
      headerTitle: 'GlassBiller',
      filePickerKey: '',
      isChangesDetected: false,
      url: '',
      showLoggedInComponents: false,
      showDocumentPopover: false,
      checkHashInterval: null,
      SoftwareContractGlassbiller,
      NagsPriceIncrease,
      nmAlertVisible: false,
      nmAlertShopId: null,
      showShopRateDialog: false,
      shopRateData: null
    }
  },

  computed: {
    isPublicRoute() {
      return this.$route.matched.length === 0 || this.$route.meta.isPublic
    },
    showLoading: sync('globalLoading'),
    hasTechCommissionPermission() {
      return permissionCheck(permissions.TECHCOMMISSION, this.$store.state)
    },
    userAccountIsActive: get('userAccountIsActive'),
    allowQuickBooks() {
      var allowQuickBooks = false
      var allowedShopsTemp = [597, 4, 849, 852, 853, 861, 764, 837, 267]
      try {
        const tempShops = this.$store.state.shops
        for (var i = 0; i < tempShops.length; i++) {
          if (allowedShopsTemp.includes(tempShops[i].id)) {
            allowQuickBooks = true
            i = tempShops.length
          }
        }
      } catch (err) {}
      return allowQuickBooks
    },
    pageChange() {
      return this.$store.getters.pageTrigger
    },
    documentDialogTrigger() {
      return this.$store.getters.documentConfig
    },
    showAcknowledgmentDialog() {
      if (this.$store?.state?.user) {
        if (
          (this.$store.state.user.nags_price_increase_acknowledged !== 1 ||
            this.$store.state.user.terms_and_conditions_acknowledged !== 1) &&
          this.componentsToShowInAcknowledgeDialog.length
        ) {
          return true
        }
      }
      return false
    },
    componentsToShowInAcknowledgeDialog() {
      const tempArray = []
      if (
        this.$store?.state?.user &&
        Object.keys(this.$store.state.user) &&
        this.$route.name !== 'Signature Capture' &&
        this.$route.name !== 'Payment Portal'
      ) {
        if (this.$store.state.user.nags_price_increase_acknowledged !== 1) {
          tempArray.push(NagsPriceIncrease)
        }
        if (this.$store.state.user.terms_and_conditions_acknowledged !== 1) {
          tempArray.push(SoftwareContractGlassbiller)
        }
      }
      return tempArray
    }
  },

  async created() {
    document.title = 'GlassBiller'

    this.$root.$on('runAppSetupCommand', this.runAppSetupCommand)

    this.$root.$on(
      'createSquareDeviceSubscription',
      this.createSquareDeviceSubscription
    )
    this.$root.$on(
      'removeSquareDeviceSubscription',
      this.removeSquareDeviceSubscription
    )

    const token = VueCookies.get('token')
    if (token) {
      axios.defaults.headers.common.Auth = token
    }
    window.addEventListener(
      'dragover',
      function(e) {
        e = e || event
        e.preventDefault()
      },
      false
    )
    window.addEventListener(
      'drop',
      function(e) {
        e = e || event
        e.preventDefault()
      },
      false
    )
    window.addEventListener('openVendor', this.openVendorFromVue3)
  },

  async mounted() {
    this.$store.dispatch(schedulerNamespacedActions.setStateValue, {
      key: 'isOpen',
      value: false
    })
    window.addEventListener('hydrateNags', this.hydrateNagsFromVue3)
    this.capitalizeClassWatcherInit()

    this.showLoading = false
  },

  destroyed() {
    this.capitalizeClassWatcherDestroy()
    this.$store.dispatch('triggerGoToPage', '')
    clearInterval(this.checkHashInterval)
  },

  beforeDestroy: function() {
    this.$root.$off('runAppSetupCommand', this.runAppSetupCommand)

    this.$root.$off(
      'createSquareDeviceSubscription',
      this.createSquareDeviceSubscription
    )
    this.$root.$off(
      'removeSquareDeviceSubscription',
      this.removeSquareDeviceSubscription
    )
    window.removeEventListener('hydrateNags', this.hydrateNagsFromVue3)
    window.removeEventListener('openVendor', this.openVendorFromVue3)
  },

  watch: {
    isPublicRoute: async function(val) {
      if (!val) {
        await this.runAppSetupCommand()
      }
    },
    pageChange: function(val) {
      if (val !== '') {
        this.url = val
        if (this.isChangesDetected) {
          this.$root.$emit('bv::show::modal', 'unsavedDialog')
        } else {
          if (this.url.startsWith('/')) {
            this.$router.push(this.url)
            if (this.url === '/jobs/new' && this.$job) {
              this.$job.resetStore()
              window.location.reload()
            }
          } else if (this.url === 'refresh') {
            this.$router.go()
          } else {
            window.location.href = this.url
          }
          this.$store.dispatch('triggerGoToPage', '')
        }
      } else {
        this.url = ''
      }
    },
    documentDialogTrigger: function(val) {
      if (val !== null) {
        if (val.config.popover) {
          this.showDocumentPopover = true
        } else {
          this.$root.$emit('bv::show::modal', 'dynamicDocumentModal')
        }
      }
    }
  },

  methods: {
    ...mapActions(useVendorStore, { viewVendor: 'openView' }),
    openVendorFromVue3(event) {
      this.viewVendor(event.detail.id)
    },
    async hydrateNagsFromVue3(event) {
      this.shopRateData = {
        ...event.detail,
        applyRateMethod: newRate => {
          const newEvent = new CustomEvent('returnNagsToVue3', {
            detail: {
              isCreate: event.detail.isCreate,
              newRate
            }
          })
          window.dispatchEvent(newEvent)
        }
      }
      await this.$nextTick(() => {
        this.showShopRateDialog = true
      })
    },
    runAppSetupCommand() {
      this.getConfigDetails()

      const self = this
      User.getUserAndShopsWithCookieToken(
        function(user, shops, organizations, roles, nmShopToInvite) {
          pusher.init()
          if (user.account_id !== -1) {
            self.$store.dispatch('setUser', user)
            self.$store.dispatch(
              'setWarehouseHideAdhesiveCache',
              user.warehouse_hide_adhesive_cache
            )
            self.$store.dispatch(
              'setWarehouseGroupByCache',
              user.warehouse_group_by_cache
            )
            self.$store.dispatch(
              'setWarehouseShopIdCache',
              user.warehouse_shop_id_cache
            )
            self.$store.dispatch(
              'setWarehouseIsPartsRequired',
              user.warehouse_is_parts_required_cache
            )
            self.$store.dispatch('setRoles', roles)
            self.$store.dispatch('setShops', shops)
            self.$store.dispatch('setOrganizations', organizations)
            self.$store.commit(schedulerNamespacedMutations.setStateValue, {
              key: 'filters.shops',
              value: user.scheduler_selected_shops
            })
            self.getShoppingCartItemsCount()
            if (self.$store.state.user.has_access_to_twilio_phone_numbers) {
              try {
                self.getUnreadTextMessageThreadsCount()
                pusher.subscribeToRelatedShops()
              } catch (err) {}
            }
            self.getOpenTicketCount()

            // open nm invite dialog if applicable
            if (nmShopToInvite) {
              self.nmAlertShopId = nmShopToInvite
              self.nmAlertVisible = true
            }
          }
        },
        function() {
          self.$store.dispatch('setUser', [])
          self.$store.dispatch('setShops', [])
          self.$store.dispatch('setOrganizations', [])
        }
      )
    },
    updateHeaderTitle(event) {
      this.headerTitle = event
    },
    changesDetected(event) {
      this.isChangesDetected = event
    },
    getConfigDetails() {
      const self = this
      if (!this.filePickerKey || this.filePickerKey.length === 0) {
        API({ url: '/api/config' }).then(
          res => {
            if (
              res &&
              res.data &&
              res.data.filePickerKey &&
              res.data.filePickerKey.length > 0
            ) {
              self.filePickerKey = res.data.filePickerKey
            } else {
              setTimeout(() => {
                self.getConfigDetails()
              }, 5000)
            }
          },
          function(error) {
            if ((error + '').indexOf('status code 401') !== -1) {
            } else {
              setTimeout(() => {
                self.getConfigDetails()
              }, 2000)
            }
          }
        )
      }
    },
    loading(isLoading) {
      this.showLoading = isLoading
    },
    getUnreadTextMessageThreadsCount: call(
      'twilio/textMessages/getUnreadThreadsCount'
    ),
    getShoppingCartItemsCount() {
      return this.$store.dispatch(shoppingCartActions.getItemsCount)
    },
    acknowledgeTermsAndConditions(val) {
      User.updateUserTermsAndConditions(val)
      this.$store.dispatch('setNagsPriceIncreaseAcknowledged', val)
    },
    getOpenTicketCount: call('support/getOpenTicketCount'),
    createSquareDeviceSubscription(shopId, deviceId) {
      pusher.subscribeToSquareDeviceNotifications(shopId, deviceId)
    },
    removeSquareDeviceSubscription(shopId, deviceId) {
      pusher.unsubscribeToSquareDeviceNotifications(shopId, deviceId)
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');
</style>
<style src="../node_modules/vue-multiselect/dist/vue-multiselect.min.css" />

<style scoped>
.app-loading-view {
  min-width: 100%;
}
</style>

<style lang="scss" scoped>
@import './App.scss';
</style>
