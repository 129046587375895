<template>
  <el-dialog
    v-if="method === 'email'"
    :visible.sync="isOpen"
    @input="$emit('input')"
    title="Email"
    append-to-body
    destory-on-close
    @open="onOpen"
    @closed="onClose"
    width="1100px"
    okText="Send"
    @on-save="submit"
  >
    <div
      @drop.prevent="documentDrop"
      @dragleave.stop.prevent="dragLeave"
      @dragenter.stop.prevent="dragEnter"
      @dragover.prevent
      @drop="dragging = false"
      :class="{
        'dragging-overlay': dragging,
        'uploading-file': uploadingDropFile,
      }"
    >
      <div v-if="uploadingDropFile">
        <div v-loading="true" class="uploading-spinner"></div>
      </div>
      <div slot="title" class="t-flex t-align-center">
        <div class="el-col el-col-12">
          <div>Quick Addresses</div>
          <div>
            <el-button
              v-for="shortcut in emailShortcuts"
              :key="shortcut.label"
              size="mini"
              @click="setEmail(shortcut.address)"
            >
              {{ shortcut.label }}
            </el-button>
          </div>
        </div>
        <div class="el-col el-col-12 t-pl-6 t-pr-8 t-pb-4">
          <span class="undo-label-styles">Template</span>
          <el-select
            clearable
            placeholder="Select email template (optional)"
            v-model="selectedTemplate"
            style="width: 100%"
            slot="append"
            value-key="id"
          >
            <el-option
              v-for="item in templates"
              :label="item.name"
              :key="item.id"
              :value="item"
            >
            </el-option>
            <div class="t-pl-3">
              <el-button
                type="text"
                icon="el-icon-plus"
                @click="createTemplate"
              >
                Add Template
              </el-button>
            </div>
          </el-select>
          or
          <a href="javascript:void(0)" @click="manageTemplates"
            >Manage email message templates</a
          >
        </div>
      </div>
      <div class="email-dialog">
        <gb-form :schema="schema" v-model="data" ref="formRef" />
        <div class="t-flex t-justify-between t-mb-2">
          <div class="email-dialog-attachments">
            <div class="attachments-label">Attachments</div>
            <div class="t-flex t-mt-1 t-mb-1">
              <div>
                <el-switch
                  class="t-mt-1"
                  v-model="shareFile"
                  :active-text="file.name"
                />
              </div>
            </div>
          </div>
          <div class="additional-attachments t-pl-2">
            <div class="additional-attachments__label t-mb-1">
              Additional Attachments
            </div>
            <div class="additional-attachments__files">
              <div
                v-for="(attachment, index) in attachments"
                :key="attachment.url"
                class="t-mb-1"
              >
                <a :href="attachment.url" target="_blank">{{
                  attachment.name
                }}</a
                >&nbsp;
                <a @click="deleteAttachment(index)" href="javascript:void(0)">
                  <i class="el-icon-close remove-icon t-ml-2"></i>
                </a>
              </div>
              <div class="t-mt-2" @dragover.prevent>
                <FilePicker
                  :buttonLabel="'Choose File'"
                  :filePickerKey="filePickerKey"
                  :handleFilePickerResponse="handleFilePickerResponse"
                  :dropListenerName="'genericEmailAttachment'"
                  :uploadingDropFile.sync="uploadingDropFile"
                />
                <div class="t-mt-1">
                  <div v-if="!uploadingDropFile">Or Drag and Drop...</div>
                  <div v-if="uploadingDropFile">Uploading...</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-collapse v-model="showLog">
          <el-collapse-item
            :title="showLog.length ? 'Hide History' : 'Show History'"
            name="1"
          >
            <email-history :history="history" :isGeneric="true" />
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="isOpen = false" size="normal">Cancel</el-button>
      <el-button size="normal" type="primary" @click="submit" :loading="loading"
        >Send</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { sync, call, get } from "vuex-pathify";
import { emailShortcuts } from "./share.config.js";
import { emailPattern, multiEmailValidator } from "@/constants/form.config";
import FilePicker from "@/components/Helpers/FilePicker.vue";
import EmailHistory from "@/components/JobPageV2/helpers/dialogs/EmailDialog/EmailHistory.vue";

export default {
  props: ["filePickerKey"],
  components: { FilePicker, EmailHistory },
  data() {
    return {
      attachments: [],
      data: {},
      dragging: 0,
      emailShortcuts,
      history: [],
      loading: false,
      quill: null,
      selectedTemplate: null,
      shareFile: false,
      showLog: ["1"],
      templateBodySelection: {
        index: 0,
        length: 0,
      },
      templates: [],
      uploadingDropFile: false,
    };
  },
  methods: {
    openEmailTemplatesDialog: call("twilio/textMessages/emailTemplates/open"),
    setTextMessageTemplatesReferral: call(
      "twilio/textMessages/emailTemplates/setReferral"
    ),
    async onOpen() {
      if (this.file?.url) {
        this.shareFile = true;
      }

      const { data } = await this.$unum
        .documents()
        .get(`share/history/email/${this.fileType}`);
      this.history = data;

      await this.getTemplates();
    },
    async onClose() {
      this.data = {};
      this.attachments = [];
      this.history = [];
      this.templates = [];
      this.selectedTemplate = null;
      this.templateBodySelection = {
        index: 0,
        length: 0,
      };
    },
    setEmail(email) {
      if (this.data?.to) {
        this.data.to.push(email);
      } else {
        this.data = { ...this.data, to: [email] };
      }
    },
    async getTemplates() {
      try {
        const { data } = await this.$unum
          .job()
          .get("textMessageTemplate/allByType/email?type=8");
        this.templates = data || [];

        if (this.templates.length === 1) {
          this.selectedTemplate = this.templates[0];
          return;
        }

        this.templates.forEach((template) => {
          if (template.default === 1) {
            this.selectedTemplate = template;
          }
        });
      } catch {
        this.templates = [];
      }
    },
    manageTemplates() {
      this.setTextMessageTemplatesReferral("report");
      this.openEmailTemplatesDialog();
    },
    createTemplate() {
      this.setTextMessageTemplatesReferral("add-new-template");
      this.openEmailTemplatesDialog();
    },
    dragEnter() {
      this.dragging -= 1;
    },
    dragLeave() {
      this.dragging += 1;
    },
    handleFilePickerResponse: function (fileName, fileUrl) {
      this.attachments.push({
        name: fileName,
        url: fileUrl,
      });
    },
    documentDrop: function (e) {
      this.$root.$emit("genericEmailAttachment", e);
    },
    deleteAttachment(index) {
      const attachments = this.attachments;
      attachments.splice(index, 1);
      this.attachments = attachments;
    },
    async submit() {
      if (await this.$refs.formRef.validateAll()) {
        this.loading = true;
        const attachmentUrls = this.attachments.map((a) => a.url);
        if (this.shareFile) {
          attachmentUrls.push(this.file.url);
        }

        if (!this.data.body) {
          this.data.body = " ";
        }

        const res = await this.$unum.documents().post("share/email", {
          data: this.data,
          fileType: this.fileType,
          shops: this.shops,
          attachmentUrls,
        });

        this.loading = false;

        if (res.status === 200) {
          this.$message({ type: "success", message: res.data });
        } else {
          this.$message({ type: "error", message: res.data });
        }

        this.isOpen = false;
      }
    },
  },
  computed: {
    method: sync("share/method"),
    isOpen: sync("share/visible"),
    file: sync("share/file"),
    fileType: sync("share/fileType"),
    shops: sync("share/shops"),
    options: get("share/options"),
    schema() {
      return [
        [
          {
            id: "to",
            label: "To *",
            rule: [
              {
                required: true,
                message: "Email is required",
              },
              {
                validator: multiEmailValidator,
                message: "Invalid email",
                trigger: "blur",
              },
            ],
            select: {
              allowCreate: true,
              defaultFirstOption: true,
              options: this.options || [],
            },
            clearable: true,
            multiple: true,
          },
          {
            id: "replyTo",
            label: "Reply To",
            rule: [
              {
                pattern: emailPattern,
                message: "Invalid email",
              },
            ],
          },
          {
            id: "ccReplyTo",
            label: "CC Reply To",
            type: "checkbox",
            true_label: 1,
            false_label: 0,
            disabled: !this.data.replyTo,
          },
        ],
        [
          {
            id: "subject",
            label: "Subject",
            rule: [
              {
                required: true,
                message: "Subject is required",
              },
            ],
          },
        ],
        [
          {
            id: "body",
            label: "Body",
            type: "wysiwyg",
            editorOptions: {
              modules: {
                toolbar: {
                  container: [
                    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                    ["bold", "italic", "underline", "strike"], // toggled buttons
                    [
                      { align: "" },
                      { align: "center" },
                      { align: "right" },
                      { align: "justify" },
                    ],
                    ["blockquote", "code-block"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { list: "check" },
                    ],
                    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    ["link"],
                    ["clean"], // remove formatting button
                    ["emoji"],
                  ],
                  handlers: {
                    emoji: function () {},
                  },
                },
                short_name_emoji: true,
                toolbar_emoji: true,
                textarea_emoji: false,
              },
            },
            onSelectionChange: (range) => {
              this.templateBodySelection = range;
            },
            onFocus: (quill) => {
              this.quill = quill;
            },
          },
        ],
      ];
    },
  },
  watch: {
    selectedTemplate: async function (v) {
      if (v && v.body && v.subject) {
        this.$set(this.data, "subject", v.subject || "");
        this.$set(this.data, "body", v.body || "");
      } else {
        this.$set(this.data, "subject", "");
        this.$set(this.data, "body", "");
      }
    },
  },
};
</script>

<style lang="scss">
.el-dialog__header {
  font-size: 14px;
}

.additional-attachments {
  width: 40%;

  &__files {
    line-height: 12px;

    .remove-icon {
      color: $crimson-red;
      vertical-align: text-bottom;

      &:hover {
        color: $watermelon-pink;
      }
    }
    button {
      font-size: 12px;
      padding: 0.275rem 0.65rem;
    }
  }
}

.receipt-label .el-loading-spinner {
  top: 0;
  margin-top: 0;
}

.dragging-overlay {
  &:after {
    content: "Drop Files to Upload";
    position: absolute;
    top: $padding-xs;
    bottom: $padding-xs;
    left: $padding-xs;
    right: $padding-xs;
    background: transparentize($primary, 0.7);
    border: 3px dashed $primary;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    color: $primary;
  }
}

.uploading-file {
  &:after {
    content: "";
    position: absolute;
    top: $padding-xs;
    bottom: $padding-xs;
    left: $padding-xs;
    right: $padding-xs;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    color: $primary;
  }
}

.new-item-2 {
  background-color: #409eff;
  color: white;
  border-radius: 5px;
  padding: 0px 5px;
  margin-left: 5px;
  font-size: x-small;
  line-height: 1.5;
}

.ql-emoji > svg {
  border-radius: 10px;
  background-color: rgba(238, 255, 0, 0.6);
}
</style>
