// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".floating-button .el-badge__content.is-fixed {\n  -webkit-transform: translateY(-50%) translateX(40%);\n          transform: translateY(-50%) translateX(40%);\n}", "",{"version":3,"sources":["/app/src/components/SchedulerV3/SchedulerJobDetails.vue"],"names":[],"mappings":"AA8SE;EACE,mDAAA;UAAA,2CAAA;AAlSJ","file":"SchedulerJobDetails.vue","sourcesContent":[":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.floating-button .el-badge__content.is-fixed {\n  transform: translateY(-50%) translateX(40%);\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
