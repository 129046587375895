<template>
  <div class="user-shop-transfer">
    <div class="t-flex t-flex-grow t-justify-between t-items-center">
      <div class="t-flex t-items-center">
        <div class="t-text-gray-400 t-text-base t-mr-6">Shops</div>
      </div>

      <div v-if="saving" class="t-flex t-text-yellow-700 t-items-center t-space-x-2">
        <i class="el-icon-loading t-text-xl" />
        <div>Saving...</div>
      </div>

      <div v-if="!saving && saved" class="t-flex t-text-green-600 t-items-center t-space-x-2">
        <i class="material-icons-outline md-check" />
        <div>Updated!</div>
      </div>
    </div>

    <el-transfer
      v-model="att"
      :data="localOptions"
      :titles="['Available', 'Connected']"
      filterable
      filter-placeholder="Filter by name/id"
      @change="handleChange">
    </el-transfer>
  </div>
</template>

<script>
import Api from '@/services/Api'

export default {
  props: ['attached', 'options', 'userId'],
  data () {
    return {
      saving: false,
      saved: false,
      att: []
    }
  },
  mounted () {
    this.att = this.attached.map((shop) => shop.id)
  },
  methods: {
    async handleChange (v) {
      try {
        if (this.userId > 0) {
          this.saving = true
          await Api.post('/api/user/updateShops', {
            userId: this.userId,
            shopIds: v
          })
  
          this.saving = false
          this.saved = true
        }
        this.$emit('changed', v)
      } catch ({response}) {
        this.saving = false
        this.att = this.attached.map((shop) => shop.id)
        this.$message.error(response.data)
      }
    }
  },
  computed: {
    localOptions () {
      const temp = this.options.map((shop) => {
        return { key: shop.id, label: shop.name }
      })
      return temp
    }
  },
  watch: {
    saved: function (val) {
      if (val) {
        setTimeout(() => {
          this.saved = false
        }, 3000)
      }
    },
    userId(val, old) {
      if (val !== old) {
        this.att = this.attached.map((shop) => shop.id)
      }
    }
  }
}
</script>

<style lang="scss">
.user-shop-transfer {
  .el-transfer {
    @apply t-flex t-items-center;

    .el-transfer-panel {
      @apply t-flex-grow t-rounded-lg;

      .el-transfer-panel__header {
        .el-checkbox__label {
          @apply t-text-gray-600;
        }
      }

      .el-checkbox__label {
        @apply t-normal-case t-font-normal;
      }
    }

    .el-transfer__buttons {
      @apply t-px-3;

      .el-button {
        @apply t-p-3;
      }
    }
  }
}
</style>
