<template>
  <div class="t-flex">
    <el-scrollbar
      class="t-flex-grow t-h-[700px] t-my-4 t-mr-2"
      wrap-class="t-overflow-x-hidden t-flex-shrink-0 t-py-2 t-px-8"
    >
      <div class="qbo-mapping-dialog-body">
        <quick-books-account-select accountType="Receivable Account" disabled />

        <el-divider class="t-my-6">Income</el-divider>

        <quick-books-item-select
          class="qbo-account-select"
          itemType="Materials Item"
        />

        <quick-books-item-select
          class="qbo-account-select"
          itemType="Labor Item"
        />

        <el-divider class="t-my-6">Payments</el-divider>

        <quick-books-account-select
          class="qbo-account-select"
          accountType="Cash Payment"
        />

        <quick-books-account-select
          class="qbo-account-select"
          accountType="Check Payment"
        />

        <quick-books-account-select
          class="qbo-account-select"
          accountType="Bank Transfer Payment"
        />

        <quick-books-account-select
          class="qbo-account-select"
          accountType="Manual Card Payment"
        />

        <quick-books-account-select
          class="qbo-account-select"
          accountType="Other Payment"
        />

        <quick-books-account-select
          v-if="shopHasCardProcessor"
          class="qbo-account-select"
          accountType="Card Payment"
        />

        <el-divider class="t-my-6">Adjustments</el-divider>

        <quick-books-account-select
          class="qbo-account-select"
          accountType="Clearing Account"
        />

        <quick-books-account-select
          class="qbo-account-select"
          accountType="Promo Adjustment"
          :disabled="!defaultClearingAccount"
        />

        <quick-books-account-select
          class="qbo-account-select"
          accountType="Coupon Adjustment"
          :disabled="!defaultClearingAccount"
        />

        <quick-books-account-select
          class="qbo-account-select"
          accountType="Credit Adjustment"
          :disabled="!defaultClearingAccount"
        />

        <quick-books-account-select
          class="qbo-account-select"
          accountType="Short Pay Adjustment"
          :disabled="!defaultClearingAccount"
        />

        <quick-books-account-select
          class="qbo-account-select"
          accountType="Write Off Adjustment"
          :disabled="!defaultClearingAccount"
        />

        <quick-books-account-select
          class="qbo-account-select"
          accountType="Deductible Discount Adjustment"
          :disabled="!defaultClearingAccount"
        />

        <quick-books-account-select
          class="qbo-account-select"
          accountType="Fee Adjustment"
          :disabled="!defaultClearingAccount"
        />

        <quick-books-account-select
          class="qbo-account-select"
          accountType="Doubtful Account Adjustment"
          :disabled="!defaultClearingAccount"
        />

        <el-divider class="t-my-6">Commissions</el-divider>

        <quick-books-account-select
          class="qbo-account-select"
          accountType="CSR Bill"
        />

        <quick-books-account-select
          class="qbo-account-select"
          accountType="Sale Source Bill"
        />

        <quick-books-account-select
          class="qbo-account-select"
          accountType="Sales Rep Bill"
        />

        <quick-books-account-select
          class="qbo-account-select"
          accountType="Tech Bill"
        />

        <quick-books-account-select
          class="qbo-account-select"
          accountType="Location Bill"
        />
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import QuickBooksAccountSelect from './QuickBooksAccountSelect.vue'
import QuickBooksItemSelect from './QuickBooksItemSelect.vue'
import { mapState } from 'pinia'
import { useQboStore } from '@/components/modals/QuickBooksAccountMappingDialog/qbo.store.js'

export default {
  computed: {
    ...mapState(useQboStore, ['defaultClearingAccount'])
  },
  components: {
    QuickBooksAccountSelect,
    QuickBooksItemSelect
  },

  props: {
    shopHasCardProcessor: Boolean
  }
}
</script>

<style lang="scss">
  .qbo-mapping-dialog-body {
    .el-input {
      @apply t-w-[250px];
    }
  }
  .qbo-account-select {
    margin-bottom: 8px;
  }
</style>
