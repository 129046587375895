var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "job-details-commission" }, [
    _c(
      "div",
      { attrs: { slot: "reference" }, slot: "reference" },
      [
        _c(
          "el-popover",
          { attrs: { title: "Tech Commissions", trigger: "click" } },
          [
            _vm._l(_vm.input.vendors, function(vendor) {
              return _c(
                "div",
                { key: vendor.id },
                [
                  _c("commission-row", {
                    attrs: {
                      vendor: vendor,
                      total: _vm.getDollarValue(vendor)
                    },
                    on: { "update-commission": _vm.updateVendorCommission }
                  })
                ],
                1
              )
            }),
            _c("div", { attrs: { slot: "reference" }, slot: "reference" }, [
              _vm._v(" $" + _vm._s(_vm.input.value || 0.0) + " ")
            ])
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }