import * as R from 'ramda'

const getRefNumber = R.pipe(R.path(['job', 'referral_number']), R.length)
const getPartLength = R.pipe(R.path(['jobParts']), R.all(v=>v.total_price && v.total_price > 0))
const adhesiveOrderCheck = R.pipe(R.prop('jobParts'), v => {
  if (!v.length) return true
  const tempArray = [...v]
  tempArray.sort((a,b)=> a.order - b.order)
  for (var i = 0; i < tempArray.length; i++) {
    if (tempArray[i].is_adhesive_kit_part == '1' && (i === 0 || (i > 0 && tempArray[i-1].is_glass != '1'))) {
      return false
    }
  }
  return true
})
const checkLoss = R.pipe(R.prop('job'), v => {
  if (!v.loss_date) return false
  if (v.loss_date<=v.install_date)return true
  return false
})
const checkInstall = R.pipe(R.prop('job'), v => {
  if (!v.install_date) return false
  if (v.install_date<=v.invoice_date || (!v.invoice_date && new Date(v.install_date)<=new Date()))return true
  // if (!v.invoice_date && v.status === 'draft') return true
  return false
})

export const ediConfig = {
  safelite: {
    name: 'Safelite',
    id: 'safelite',
    programs: true,
    referral_rule: {
      customRule: R.pipe(getRefNumber, R.equals(6)),
      key: 'referral_rule',
      message: 'Exactly 6 digits long'
    }
  },
  lynx: {
    name: 'Lynx ($1.25)',
    id: 'lynx',
    referral_rule: {
      customRule: R.pipe(getRefNumber, R.equals(9)),
      key: 'referral_rule',
      message: 'Exactly 9 digits long'
    }
  },
  harmon: {
    name: 'Harmon',
    id: 'harmon',
    referral_rule: {
      customRule: R.pipe(getRefNumber, R.equals(8)),
      key: 'referral_rule',
      message: 'At least 8 digits long'
    }
  },
  quest: {
    name: 'Quest (Under Maintenance)',
    id: 'quest',
    programs: true,
    referral_rule: {
      customRule: R.pipe(getRefNumber, R.lte(7)),
      key: 'referral_rule',
      message: 'At least 7 digits long'
    }
  },
  gerber: {
    name: 'Gerber ($1)',
    id: 'gerber',
    referral_rule: {
      customRule: R.pipe(getRefNumber, R.anyPass([R.equals(9), R.equals(10)])),
      key: 'referral_rule',
      message: '9 or 10 digits long'
    }
  }
}

export const ediSharedRules = [
  {
    key: 'billTo.edi.capable',
    message: 'EDI capable'
  },
  {
    key: 'billTo.edi.trading_partner',
    message: 'Trading Partner',
    rules: [
      {
        display: R.pipe(
          R.path(['billTo', 'edi', 'trading_partner']),
          R.prop(R.__, ediConfig),
          R.prop('programs')
        ),
        customRule:
          R.anyPass([
            R.pipe(
              R.path(['billTo', 'edi', 'partner_program_id']),
              v => !!v
            ),
            R.pipe(
              R.path(['billTo', 'edi', 'trading_partner']),
              R.prop(R.__, ediConfig),
              R.prop('programs'),
              R.not
            )]
          ),
        key: 'billTo.edi.partner_program_id',
        message: 'Partner Program ID'
      }
    ]
  },
  {
    key: 'vehicle',
    message: 'Vehicle',
    rules: [
      {
        key: 'vehicle.vin',
        message: 'VIN'
      },
      {
        key: 'vehicle.year',
        message: 'Year'
      },
      {
        key: 'vehicle.make',
        message: 'Make'
      },
      {
        key: 'vehicle.model',
        message: 'Model'
      },
      {
        key: 'vehicle.style',
        message: 'Style'
      },
      {
        key: 'vehicle.nags_id',
        message: 'Not manual entry'
      }
    ]
  },
  {
    key: 'jobParts',
    message: 'Parts',
    rules: [
      {
        key: 'jobParts.length',
        message: 'Parts Added'
      },
      {
        key: 'partPricing',
        message: 'Parts Without Pricing',
        customRule: getPartLength
      },
      {
        key: 'partOrder',
        message: 'Adhesive Directly After Glass',
        customRule: adhesiveOrderCheck
      },
    ]
  },
  {
    key: 'job.referral_number',
    message: 'Referral Number',
    rules: [
      {
        key: 'all_digits',
        message: 'All digits',
        customRule: R.pipe(R.path(['job', 'referral_number']), R.test(/^\d+$/))
      },
      R.pipe(
        R.pathOr('safelite', ['billTo', 'edi', 'trading_partner']),
        R.prop(R.__, ediConfig),
        R.prop('referral_rule')
      )
    ]
  },
  {
    key: 'job',
    message: 'Dates',
    rules: [
      {
        key: 'job.loss_date',
        message: 'Loss Date',
        error_message: 'Loss Date - On or before install date',
        customRule: checkLoss
      },
      {
        key: 'job.install_date',
        message: 'Install Date',
        error_message: 'Install Date - On or before invoice date',
        customRule: checkInstall
      },
      // {
      //   key: 'job.invoice_date',
      //   message: 'Invoice Date',
      //   customRule: checkInvoice
      // }
    ]
  }
]

export const ediStatuses = {
  unsubmitted: {
    color: 'silver',
    icon: 'md-unpublished',
    hex: '#bcbcbc'
  },
  sent: {
    color: 'light-orange',
    icon: 'md-published_with_changes',
    hex: '#e4b370'
  },
  paid: {
    color: 'green',
    icon: 'md-request_quote',
    hex: '#54a955'
  },
  accepted: {
    color: 'green',
    icon: 'md-library_add_check',
    hex: '#54a955'
  },
  rejected: {
    color: 'red',
    icon: 'md-thumb_down_alt',
    hex: '#dc3545'
  },
  unknown: {
    color: 'silver',
    icon: 'md-question',
    hex: '#bcbcbc'
  },
  queued: {
    color: 'orange',
    icon: 'md-question',
    hex: '#f0ad4e'
  },
  unlocked: {
    icon: 'md-lock_open'
  }
}
