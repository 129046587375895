var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.showLoading
        ? _c("loading-screen")
        : [
            _c("JobDialogsForVue3", {
              attrs: { filePickerKey: _vm.filePickerKey }
            }),
            _c(
              "div",
              {
                staticClass: "glassbiller-container",
                class: {
                  "glassbiller-fixed-layout": _vm.$route.meta.fixedLayout
                }
              },
              [
                !_vm.isPublicRoute
                  ? _c("PageHeader", {
                      attrs: {
                        title: _vm.headerTitle,
                        allowQuickBooks: true,
                        changeDetected: _vm.isChangesDetected,
                        filePickerKey: _vm.filePickerKey
                      }
                    })
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "glassbiller-content-wrapper" },
                  [
                    !_vm.isPublicRoute && !_vm.hasTechCommissionPermission
                      ? _c("side-bar-v2")
                      : _vm._e(),
                    _c("div", { staticClass: "glassbiller-content" }, [
                      _c(
                        "div",
                        { staticClass: "glassbiller-page-wrapper" },
                        [
                          _vm.userAccountIsActive
                            ? _c("router-view", {
                                key: _vm.$route.fullPath,
                                attrs: { filePickerKey: _vm.filePickerKey },
                                on: {
                                  changesDetected: _vm.changesDetected,
                                  updateHeaderTitle: _vm.updateHeaderTitle,
                                  loading: _vm.loading
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            !_vm.isPublicRoute
              ? [
                  _c("NotificationDialog"),
                  _c("UnsavedChangesDialog", { attrs: { url: _vm.url } }),
                  _c("GlobalNotification"),
                  _c("scheduler-dialog"),
                  _c("DocumentDialogDynamic", {
                    attrs: { filePickerKey: _vm.filePickerKey }
                  }),
                  _c("DocumentPopover", {
                    attrs: {
                      showPopover: _vm.showDocumentPopover,
                      filePickerKey: _vm.filePickerKey
                    },
                    on: {
                      popoverShown: function($event) {
                        _vm.showDocumentPopover = false
                      }
                    }
                  })
                ]
              : _vm._e(),
            _vm.shopRateData
              ? _c("shop-rates-dialog", {
                  attrs: {
                    rate: _vm.shopRateData.rate,
                    shopRates: _vm.shopRateData.shopRates,
                    shop_id: _vm.shopRateData.shopId,
                    defaultName: _vm.shopRateData.defaultName,
                    applyRateMethod: _vm.shopRateData.applyRateMethod,
                    autoApply: true
                  },
                  model: {
                    value: _vm.showShopRateDialog,
                    callback: function($$v) {
                      _vm.showShopRateDialog = $$v
                    },
                    expression: "showShopRateDialog"
                  }
                })
              : _vm._e(),
            _c("AlertDialog"),
            _c("nielsen-moller-alert-dialog", {
              attrs: {
                visible: _vm.nmAlertVisible,
                shopId: _vm.nmAlertShopId,
                syncShopId: true
              },
              on: {
                "update:visible": function($event) {
                  _vm.nmAlertVisible = $event
                },
                "update:shopId": function($event) {
                  _vm.nmAlertShopId = $event
                },
                "update:shop-id": function($event) {
                  _vm.nmAlertShopId = $event
                }
              }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }