var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            title: "QuickBooks Export",
            "header-bg-variant": "primary",
            "header-text-variant": "light",
            size: "lg",
            id: "quickBooksExportModal",
            "hide-footer": "",
            "no-close-on-esc": ""
          },
          on: { show: _vm.dialogAboutToAppear, shown: _vm.dialogAppeared }
        },
        [
          _c("div", { staticClass: "row mb-2" }, [
            _c(
              "div",
              { staticClass: "col-sm-12 col-md-6 col-xl-4 col-lg-4" },
              [
                _c("label", { attrs: { for: "#" } }, [_vm._v("Shop")]),
                _c("Multiselect", {
                  attrs: {
                    placeholder: "Select Shop...",
                    options: _vm.shopsForQuickBooks,
                    label: "name",
                    "show-labels": false
                  },
                  model: {
                    value: _vm.selectedShop,
                    callback: function($$v) {
                      _vm.selectedShop = $$v
                    },
                    expression: "selectedShop"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "col-sm-12 col-md-6 col-xl-4 col-lg-4" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-6 col-xl-6 col-lg-6" },
                  [
                    _c("label", { attrs: { for: "#" } }, [_vm._v("From Date")]),
                    _c("DatePickerShortcut", {
                      attrs: {
                        value: _vm.fromDate,
                        displayFormat: "yyyy-MM-dd",
                        saveFormat: "yyyy-MM-dd"
                      },
                      on: {
                        "update:value": function($event) {
                          _vm.fromDate = $event
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-6 col-xl-6 col-lg-6" },
                  [
                    _c("label", { attrs: { for: "#" } }, [_vm._v("To Date")]),
                    _c("DatePickerShortcut", {
                      attrs: {
                        value: _vm.toDate,
                        displayFormat: "yyyy-MM-dd",
                        saveFormat: "yyyy-MM-dd"
                      },
                      on: {
                        "update:value": function($event) {
                          _vm.toDate = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "col-sm-12 col-md-6 col-xl-2 col-lg-2" }, [
              _c("label", { attrs: { for: "#" } }, [_vm._v("QuickBooks Type")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.qbExportType,
                      expression: "qbExportType"
                    }
                  ],
                  class: { "form-control": true, "form-control-sm": true },
                  staticStyle: { height: "40px" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.qbExportType = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c(
                    "option",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.selectedShop &&
                            _vm.selectedShop.qbo_refresh_token &&
                            _vm.selectedShop.qbo_refresh_token.length > 0,
                          expression:
                            "selectedShop && selectedShop.qbo_refresh_token && selectedShop.qbo_refresh_token.length > 0"
                        }
                      ],
                      attrs: { value: "qbOnline" }
                    },
                    [_vm._v("QB Online")]
                  ),
                  _c("option", { attrs: { value: "qbDesktop18" } }, [
                    _vm._v("QB Desktop 18 and Less")
                  ]),
                  _c("option", { attrs: { value: "qbDesktop19" } }, [
                    _vm._v("QB Desktop 19 and Up")
                  ])
                ]
              )
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.qbExportType == "qbOnline",
                    expression: "qbExportType == 'qbOnline'"
                  }
                ],
                staticClass: "col-sm-12 col-md-6 col-xl-2 col-lg-2"
              },
              [
                _c("label", { attrs: { for: "#" } }, [_vm._v("Export Type")]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.tableType,
                        expression: "tableType"
                      }
                    ],
                    class: { "form-control": true, "form-control-sm": true },
                    staticStyle: { height: "40px" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.tableType = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "invoices" } }, [
                      _vm._v("Invoices")
                    ]),
                    _c("option", { attrs: { value: "payments" } }, [
                      _vm._v("Payments")
                    ]),
                    _c("option", { attrs: { value: "commissions" } }, [
                      _vm._v("Commissions")
                    ])
                  ]
                )
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.selectedShop &&
                      (!_vm.selectedShop.qbo_refresh_token ||
                        _vm.selectedShop.qbo_refresh_token.length == 0),
                    expression:
                      "selectedShop && (!selectedShop.qbo_refresh_token || selectedShop.qbo_refresh_token.length == 0)"
                  }
                ],
                staticClass: "col-sm-12 col-md-6 col-xl-2 col-lg-2"
              },
              [
                _c("label", { attrs: { for: "#" } }, [
                  _vm._v("Connect To QBO?")
                ]),
                _c("br"),
                _c("img", {
                  ref: "qboButton",
                  staticStyle: {
                    width: "100%",
                    "max-width": "220px",
                    cursor: "pointer"
                  },
                  attrs: {
                    src: require("../../assets/img/intuit/C2QB_white_btn_lg_default.png")
                  },
                  on: {
                    click: function($event) {
                      return _vm.connectToQBOClicked()
                    }
                  }
                })
              ]
            )
          ]),
          _c("div", { staticClass: "row mb-2" }, [
            _c(
              "div",
              { staticClass: "col-sm-12 col-md-6 col-xl-7 col-lg-7" },
              [
                _c("CheckBoxLabel", {
                  attrs: {
                    label: "Show Only Unexported",
                    value: _vm.showOnlyUnexported
                  },
                  on: {
                    "update:value": function($event) {
                      _vm.showOnlyUnexported = $event
                    }
                  }
                }),
                _c("CheckBoxLabel", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.selectedShop &&
                        _vm.selectedShop.qbo_refresh_token &&
                        _vm.selectedShop.qbo_refresh_token.length > 0,
                      expression:
                        "selectedShop && selectedShop.qbo_refresh_token && selectedShop.qbo_refresh_token.length > 0"
                    }
                  ],
                  attrs: {
                    label: "Auto Export Invoices To QBO?",
                    value: _vm.autoExportInvoicesToQBO
                  },
                  on: {
                    "update:value": function($event) {
                      _vm.autoExportInvoicesToQBO = $event
                    }
                  }
                }),
                _c("CheckBoxLabel", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.selectedShop &&
                        _vm.selectedShop.qbo_refresh_token &&
                        _vm.selectedShop.qbo_refresh_token.length > 0,
                      expression:
                        "selectedShop && selectedShop.qbo_refresh_token && selectedShop.qbo_refresh_token.length > 0"
                    }
                  ],
                  attrs: {
                    label: "Auto Export Payments To QBO?",
                    value: _vm.autoExportPaymentsToQBO
                  },
                  on: {
                    "update:value": function($event) {
                      _vm.autoExportPaymentsToQBO = $event
                    }
                  }
                }),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.selectedShop &&
                          _vm.selectedShop.qbo_refresh_token &&
                          _vm.selectedShop.qbo_refresh_token.length > 0 &&
                          (_vm.adminCheck() || _vm.accountManagerCheck()),
                        expression:
                          "selectedShop &&\n            selectedShop.qbo_refresh_token &&\n            selectedShop.qbo_refresh_token.length > 0 &&\n            (adminCheck() || accountManagerCheck())"
                      }
                    ],
                    attrs: { type: "primary", size: "mini", round: "" },
                    on: {
                      click: function($event) {
                        return _vm.onOpenQboMapping(_vm.selectedShop.id)
                      }
                    }
                  },
                  [_vm._v(" QBO Mapping ")]
                )
              ],
              1
            ),
            _c("div", { staticClass: "col-sm-12 col-md-6 col-xl-3 col-lg-3" }, [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.numberSelectedObjects > 0,
                      expression: "numberSelectedObjects > 0"
                    }
                  ]
                },
                [
                  _c("span", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v("# Selected:")
                  ]),
                  _vm._v(" " + _vm._s(_vm.numberSelectedObjects) + " "),
                  _c("span", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v("Selected Total:")
                  ]),
                  _vm._v(" " + _vm._s(_vm.selectedObjectsTotal))
                ]
              )
            ])
          ]),
          _c("div", { staticClass: "tableHolder row" }, [
            _c(
              "div",
              { staticClass: "form-group col-12 table-responsive" },
              [
                _vm.tableType === "invoices"
                  ? _c("b-table", {
                      ref: "qbInvoicesTable",
                      staticClass: "text-left",
                      attrs: {
                        stacked: "md",
                        items: _vm.invoiceTableProvider,
                        fields: _vm.invoiceFields,
                        "current-page": _vm.currentPage,
                        "per-page": _vm.perPage,
                        "sort-by": _vm.invoiceSortBy,
                        "sort-desc": _vm.invoiceSortDesc,
                        busy: _vm.isBusy,
                        "show-empty": true,
                        "empty-text": _vm.isBusy
                          ? "Loading, please wait..."
                          : "There are no records to show",
                        "empty-filtered-text": _vm.isBusy
                          ? "Loading, please wait..."
                          : "There are no records matching your request"
                      },
                      on: {
                        "update:sortBy": function($event) {
                          _vm.invoiceSortBy = $event
                        },
                        "update:sort-by": function($event) {
                          _vm.invoiceSortBy = $event
                        },
                        "update:sortDesc": function($event) {
                          _vm.invoiceSortDesc = $event
                        },
                        "update:sort-desc": function($event) {
                          _vm.invoiceSortDesc = $event
                        },
                        "update:busy": function($event) {
                          _vm.isBusy = $event
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "HEAD_select",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  attrs: {
                                    type: "checkbox",
                                    "data-key": row.id
                                  },
                                  domProps: {
                                    checked:
                                      _vm.exportAllInvoiceRows &&
                                      Object.keys(_vm.selectedExportInvoiceRows)
                                        .length === 0
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    change: function($event) {
                                      _vm.exportAllInvoiceRows =
                                        $event.target.checked
                                      _vm.exportAllInvoiceRowsChanged()
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_invoiceNumber",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.invoiceInvoiceNumberFilter,
                                      expression: "invoiceInvoiceNumberFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Invoice #"
                                  },
                                  domProps: {
                                    value: _vm.invoiceInvoiceNumberFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.invoiceInvoiceNumberFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_customer",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.invoiceCustomerFilter,
                                      expression: "invoiceCustomerFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Customer"
                                  },
                                  domProps: {
                                    value: _vm.invoiceCustomerFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.invoiceCustomerFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_memo",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.invoiceMemoFilter,
                                      expression: "invoiceMemoFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Memo"
                                  },
                                  domProps: { value: _vm.invoiceMemoFilter },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.invoiceMemoFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_referralNumber",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.invoiceReferralNumberFilter,
                                      expression: "invoiceReferralNumberFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Referral Number"
                                  },
                                  domProps: {
                                    value: _vm.invoiceReferralNumberFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.invoiceReferralNumberFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_invoiceTotal",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.invoiceInvoiceTotalFilter,
                                      expression: "invoiceInvoiceTotalFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Invoice Total"
                                  },
                                  domProps: {
                                    value: _vm.invoiceInvoiceTotalFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.invoiceInvoiceTotalFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_invoiceDate",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.invoiceInvoiceDateFilter,
                                      expression: "invoiceInvoiceDateFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Invoice Date"
                                  },
                                  domProps: {
                                    value: _vm.invoiceInvoiceDateFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.invoiceInvoiceDateFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_lastExported",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.invoiceLastExportedFilter,
                                      expression: "invoiceLastExportedFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Last Exported"
                                  },
                                  domProps: {
                                    value: _vm.invoiceLastExportedFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.invoiceLastExportedFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_errors",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.invoiceErrorsFilter,
                                      expression: "invoiceErrorsFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Errors"
                                  },
                                  domProps: { value: _vm.invoiceErrorsFilter },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.invoiceErrorsFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_exportDetails",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.invoiceExportDetailsFilter,
                                      expression: "invoiceExportDetailsFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Export Details"
                                  },
                                  domProps: {
                                    value: _vm.invoiceExportDetailsFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.invoiceExportDetailsFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "select",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked:
                                      (_vm.exportAllInvoiceRows &&
                                        !(
                                          row.item.invoiceNumber in
                                          _vm.selectedExportInvoiceRows
                                        )) ||
                                      (!_vm.exportAllInvoiceRows &&
                                        row.item.invoiceNumber in
                                          _vm.selectedExportInvoiceRows)
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    change: function($event) {
                                      return _vm.exportInvoiceRowCheckboxChecked(
                                        $event.target.checked,
                                        row.item.invoiceNumber,
                                        row.item.invoiceTotal
                                      )
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "invoiceNumber",
                            fn: function(row) {
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "/jobs/" + row.value,
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v(_vm._s(row.value))]
                                )
                              ]
                            }
                          },
                          {
                            key: "errors",
                            fn: function(row) {
                              return [
                                _c(
                                  "span",
                                  {
                                    style: {
                                      color:
                                        row.value === "Yes" ? "red" : "green"
                                    }
                                  },
                                  [_vm._v(_vm._s(row.value))]
                                )
                              ]
                            }
                          },
                          {
                            key: "exportDetails",
                            fn: function(row) {
                              return [
                                _c(
                                  "span",
                                  {
                                    style: {
                                      color:
                                        row.item.errors === "Yes"
                                          ? "red"
                                          : "green"
                                    }
                                  },
                                  [_vm._v(_vm._s(row.value))]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3420979495
                      )
                    })
                  : _vm._e(),
                _vm.tableType === "payments"
                  ? _c("b-table", {
                      ref: "qbPaymentsTable",
                      staticClass: "text-left",
                      attrs: {
                        stacked: "md",
                        items: _vm.paymentTableProvider,
                        fields: _vm.paymentFields,
                        "current-page": _vm.currentPage,
                        "per-page": _vm.perPage,
                        "sort-by": _vm.paymentsSortBy,
                        "sort-desc": _vm.paymentsSortDesc,
                        busy: _vm.isBusy,
                        "show-empty": true,
                        "empty-text": _vm.isBusy
                          ? "Loading, please wait..."
                          : "There are no records to show",
                        "empty-filtered-text": _vm.isBusy
                          ? "Loading, please wait..."
                          : "There are no records matching your request"
                      },
                      on: {
                        "update:sortBy": function($event) {
                          _vm.paymentsSortBy = $event
                        },
                        "update:sort-by": function($event) {
                          _vm.paymentsSortBy = $event
                        },
                        "update:sortDesc": function($event) {
                          _vm.paymentsSortDesc = $event
                        },
                        "update:sort-desc": function($event) {
                          _vm.paymentsSortDesc = $event
                        },
                        "update:busy": function($event) {
                          _vm.isBusy = $event
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "HEAD_select",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  attrs: {
                                    type: "checkbox",
                                    "data-key": row.id
                                  },
                                  domProps: {
                                    checked:
                                      _vm.exportAllPaymentRows &&
                                      Object.keys(_vm.selectedExportPaymentRows)
                                        .length === 0
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    change: function($event) {
                                      _vm.exportAllPaymentRows =
                                        $event.target.checked
                                      _vm.exportAllPaymentRowsChanged()
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_paymentId",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.paymentPaymentIdFilter,
                                      expression: "paymentPaymentIdFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Payment Id"
                                  },
                                  domProps: {
                                    value: _vm.paymentPaymentIdFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.paymentPaymentIdFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_invoiceNumber",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.paymentInvoiceNumberFilter,
                                      expression: "paymentInvoiceNumberFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Invoice #"
                                  },
                                  domProps: {
                                    value: _vm.paymentInvoiceNumberFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.paymentInvoiceNumberFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_customer",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.paymentCustomerFilter,
                                      expression: "paymentCustomerFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Customer"
                                  },
                                  domProps: {
                                    value: _vm.paymentCustomerFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.paymentCustomerFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_type",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.paymentTypeFilter,
                                      expression: "paymentTypeFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Type"
                                  },
                                  domProps: { value: _vm.paymentTypeFilter },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.paymentTypeFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_xActionType",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.paymentXActionTypeFilter,
                                      expression: "paymentXActionTypeFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "X-Action Type"
                                  },
                                  domProps: {
                                    value: _vm.paymentXActionTypeFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.paymentXActionTypeFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_xActionNumber",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.paymentXActionNumberFilter,
                                      expression: "paymentXActionNumberFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "X-Action Number"
                                  },
                                  domProps: {
                                    value: _vm.paymentXActionNumberFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.paymentXActionNumberFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_amount",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.paymentAmountFilter,
                                      expression: "paymentAmountFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Amount"
                                  },
                                  domProps: { value: _vm.paymentAmountFilter },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.paymentAmountFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_memo",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.paymentMemoFilter,
                                      expression: "paymentMemoFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Memo"
                                  },
                                  domProps: { value: _vm.paymentMemoFilter },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.paymentMemoFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_paymentDate",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.paymentPaymentDateFilter,
                                      expression: "paymentPaymentDateFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Payment Date"
                                  },
                                  domProps: {
                                    value: _vm.paymentPaymentDateFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.paymentPaymentDateFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_invoiceExported",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.paymentInvoiceExportedFilter,
                                      expression: "paymentInvoiceExportedFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Invoice Exported"
                                  },
                                  domProps: {
                                    value: _vm.paymentInvoiceExportedFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.paymentInvoiceExportedFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_lastExported",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.paymentLastExportedFilter,
                                      expression: "paymentLastExportedFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Last Exported"
                                  },
                                  domProps: {
                                    value: _vm.paymentLastExportedFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.paymentLastExportedFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_errors",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.paymentErrorsFilter,
                                      expression: "paymentErrorsFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Errors"
                                  },
                                  domProps: { value: _vm.paymentErrorsFilter },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.paymentErrorsFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_exportDetails",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.paymentExportDetailsFilter,
                                      expression: "paymentExportDetailsFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Export Details"
                                  },
                                  domProps: {
                                    value: _vm.paymentExportDetailsFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.paymentExportDetailsFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "select",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked:
                                      (_vm.exportAllPaymentRows &&
                                        !(
                                          row.item.paymentId in
                                          _vm.selectedExportPaymentRows
                                        )) ||
                                      (!_vm.exportAllPaymentRows &&
                                        row.item.paymentId in
                                          _vm.selectedExportPaymentRows)
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    change: function($event) {
                                      return _vm.exportPaymentRowCheckboxChecked(
                                        $event.target.checked,
                                        row.item.paymentId,
                                        row.item.amount
                                      )
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "invoiceNumber",
                            fn: function(row) {
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "/jobs/" + row.value,
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v(_vm._s(row.value))]
                                )
                              ]
                            }
                          },
                          {
                            key: "errors",
                            fn: function(row) {
                              return [
                                _c(
                                  "span",
                                  {
                                    style: {
                                      color:
                                        row.value === "Yes" ? "red" : "green"
                                    }
                                  },
                                  [_vm._v(_vm._s(row.value))]
                                )
                              ]
                            }
                          },
                          {
                            key: "exportDetails",
                            fn: function(row) {
                              return [
                                _c(
                                  "span",
                                  {
                                    style: {
                                      color:
                                        row.item.errors === "Yes"
                                          ? "red"
                                          : "green"
                                    }
                                  },
                                  [_vm._v(_vm._s(row.value))]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1273090108
                      )
                    })
                  : _vm._e(),
                _vm.tableType === "commissions"
                  ? _c("b-table", {
                      ref: "qbCommissionsTable",
                      staticClass: "text-left",
                      attrs: {
                        stacked: "md",
                        items: _vm.commissionTableProvider,
                        fields: _vm.commissionFields,
                        "current-page": _vm.currentPage,
                        "per-page": _vm.perPage,
                        "sort-by": _vm.commissionsSortBy,
                        "sort-desc": _vm.commissionsSortDesc,
                        busy: _vm.isBusy,
                        "show-empty": true,
                        "empty-text": _vm.isBusy
                          ? "Loading, please wait..."
                          : "There are no records to show",
                        "empty-filtered-text": _vm.isBusy
                          ? "Loading, please wait..."
                          : "There are no records matching your request"
                      },
                      on: {
                        "update:sortBy": function($event) {
                          _vm.commissionsSortBy = $event
                        },
                        "update:sort-by": function($event) {
                          _vm.commissionsSortBy = $event
                        },
                        "update:sortDesc": function($event) {
                          _vm.commissionsSortDesc = $event
                        },
                        "update:sort-desc": function($event) {
                          _vm.commissionsSortDesc = $event
                        },
                        "update:busy": function($event) {
                          _vm.isBusy = $event
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "HEAD_invoiceNumber",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.commissionInvoiceNumberFilter,
                                      expression:
                                        "commissionInvoiceNumberFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Invoice #"
                                  },
                                  domProps: {
                                    value: _vm.commissionInvoiceNumberFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.commissionInvoiceNumberFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_installDate",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.commissionInstallDateFilter,
                                      expression: "commissionInstallDateFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Install Date"
                                  },
                                  domProps: {
                                    value: _vm.commissionInstallDateFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.commissionInstallDateFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_customerName",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.commissionCustomerNameFilter,
                                      expression: "commissionCustomerNameFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Customer"
                                  },
                                  domProps: {
                                    value: _vm.commissionCustomerNameFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.commissionCustomerNameFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_vehicle",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.commissionVehicleFilter,
                                      expression: "commissionVehicleFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Vehicle"
                                  },
                                  domProps: {
                                    value: _vm.commissionVehicleFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.commissionVehicleFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_vendorName",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.commissionVendorNameFilter,
                                      expression: "commissionVendorNameFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Vendor"
                                  },
                                  domProps: {
                                    value: _vm.commissionVendorNameFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.commissionVendorNameFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_vendorType",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.commissionVendorTypeFilter,
                                      expression: "commissionVendorTypeFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Type"
                                  },
                                  domProps: {
                                    value: _vm.commissionVendorTypeFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.commissionVendorTypeFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_commissionTotal",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.commissionCommissionTotalFilter,
                                      expression:
                                        "commissionCommissionTotalFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Commission"
                                  },
                                  domProps: {
                                    value: _vm.commissionCommissionTotalFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.commissionCommissionTotalFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_lastExported",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.commissionLastExportedFilter,
                                      expression: "commissionLastExportedFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Last Exported"
                                  },
                                  domProps: {
                                    value: _vm.commissionLastExportedFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.commissionLastExportedFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "HEAD_exportDetails",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.commissionExportDetailsFilter,
                                      expression:
                                        "commissionExportDetailsFilter"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  attrs: {
                                    "data-id": row.id,
                                    type: "text",
                                    placeholder: "Export Details"
                                  },
                                  domProps: {
                                    value: _vm.commissionExportDetailsFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.commissionExportDetailsFilter =
                                        $event.target.value
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "select",
                            fn: function(row) {
                              return [
                                _c("input", {
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked:
                                      (_vm.exportAllCommissionRows &&
                                        !(
                                          row.item.jobVendorId +
                                            "-" +
                                            row.item.gbVendorTypeId in
                                          _vm.selectedExportCommissionRows
                                        )) ||
                                      (!_vm.exportAllCommissionRows &&
                                        row.item.jobVendorId +
                                          "-" +
                                          row.item.gbVendorTypeId in
                                          _vm.selectedExportCommissionRows)
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    change: function($event) {
                                      return _vm.exportCommissionRowCheckboxChecked(
                                        $event.target.checked,
                                        row
                                      )
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "invoiceNumber",
                            fn: function(row) {
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "/jobs/" + row.value,
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v(_vm._s(row.value))]
                                )
                              ]
                            }
                          },
                          {
                            key: "vendorType",
                            fn: function(row) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "vendor-type-tag",
                                    style: {
                                      "background-color":
                                        _vm.vendorColors[row.value]
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.vendorLabels[row.value]) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: "exportDetails",
                            fn: function(row) {
                              return [
                                _c(
                                  "span",
                                  {
                                    style: {
                                      color:
                                        row.item.exportDetails &&
                                        row.item.exportDetails.includes("Error")
                                          ? "red"
                                          : "green"
                                    }
                                  },
                                  [_vm._v(_vm._s(row.value))]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1733772870
                      )
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { md: "6" } }, [
                _c("span", [_vm._v(_vm._s(_vm.tableResultsText))])
              ]),
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      "total-rows": _vm.totalRows,
                      "per-page": _vm.perPage,
                      align: "right"
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("b-row", { staticClass: "modal-footer" }, [
            _c("div", { staticClass: "float-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button", size: "lg", variant: "primary" },
                  on: { click: _vm.exportButtonPressed }
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.exporting,
                        expression: "exporting"
                      }
                    ],
                    staticStyle: {
                      width: "18px",
                      height: "18px",
                      "margin-right": "6px"
                    },
                    attrs: { src: require("./../../assets/img/spinner.gif") }
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.exporting ? "Starting Export..." : "Export")
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button", variant: "primary" },
                  on: { click: _vm.cancelButtonPressed }
                },
                [_vm._v("Cancel")]
              )
            ])
          ])
        ],
        1
      ),
      _c("QuickBooksCommissionValidationDialog", {
        on: {
          continueExport: _vm.continueCommissionsExport,
          resetTable: _vm.resetTable
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }