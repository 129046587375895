<template>
  <el-popover trigger="click">
    <div :style="{ display: 'flex' }">
      <el-input v-model="note" />
      <el-button @click="save" :style="{ marginLeft: '8px' }" />
    </div>
    <el-link type="primary" slot="reference" class="job-details-new-note">
      + Add
    </el-link>
  </el-popover>
</template>

<script>
import { sync, call, get } from 'vuex-pathify'
import * as R from 'ramda'
import actions from '@/store/modules/scheduler_v3/types'
import { format } from 'date-fns'

export default {
  data() {
    return { note: '' }
  },
  computed: {
    job: sync('sch_v3/jobDetails'),
    user: get('user'),
    tags() {
      return R.propOr([], 'jobtags')(this.job)
    },
    shopTags: get('sch_v3/jobDetails@shop.tags'),
    dropdownTags() {
      return R.without(this.tags)(this.shopTags || [])
    }
  },
  methods: {
    update: call(actions.updateJobDetailsValue),
    save() {
      this.update({
        key: 'notes',
        value: [
          {
            note_text: this.note,
            note_date: format(new Date(), 'YYYY-DD-MM hh:mm A'),
            user_name: this.user.username,
            user_id: this.user.id,
            note_sequence: R.pathOr(0, ['job', 'notes', 'length'])(this) + 1
          },
          ...this.job.notes
        ],
        id: this.job.id
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/JobDetailsNewNote.scss';
</style>
