var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vendor-setting-tab" },
    [
      _c("div", { staticClass: "t-text-gray-400 t-mb-4" }, [
        _vm._v("Shop Override")
      ]),
      _c(
        "el-checkbox",
        {
          on: { change: _vm.updateOverrideAllowedShopsHandler },
          model: {
            value: _vm.override_allowed_shops,
            callback: function($$v) {
              _vm.override_allowed_shops = $$v
            },
            expression: "override_allowed_shops"
          }
        },
        [_vm._v(" Override which shops included on ")]
      ),
      _c(
        "div",
        [
          _vm.override_allowed_shops
            ? _c(
                "el-select",
                {
                  staticClass: "t-mt-4",
                  attrs: { multiple: "", placeholder: "Select included shops" },
                  on: { change: _vm.updateAllowedShops },
                  model: {
                    value: _vm.shop_ids,
                    callback: function($$v) {
                      _vm.shop_ids = $$v
                    },
                    expression: "shop_ids"
                  }
                },
                _vm._l(_vm.shops, function(i) {
                  return _c("el-option", {
                    key: i.id,
                    attrs: { label: i.name, value: i.id }
                  })
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.isTech ? _c("Tech") : _vm._e(),
      _vm.isSaleSource ? _c("SaleSource") : _vm._e(),
      _vm.isSupplier ? _c("Supplier") : _vm._e(),
      _vm.isSalesRep
        ? _c("SalesRep", { attrs: { shops: _vm.shops } })
        : _vm._e(),
      _c("QboVendorDefault", { attrs: { shops: _vm.shops } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }