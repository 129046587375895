<template>
  <div>
    <el-divider class="t-my-6" />
    <div class="t-text-gray-400 t-mb-4">Default QBO Vendor</div>

    <div>
      <el-select
        v-model="selectedShop"
        filterable
        @change="changeShop"
        value-key="id"
      >
        <el-option
          v-for="i in shops"
          :key="i.id"
          :label="i.name"
          :value="i"
        />
      </el-select>
    </div>
    <div class="t-mt-3">
      <el-select
        v-model="selectedQboVendor"
        clearable
        filterable
        @change="updateQboVendor"   
        @clear="deleteQboVendor"  
      >
        <el-option
          v-for="i in qboVendors"
          :key="i.Id"
          :label="i.DisplayName"
          :value="i.Id"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'
import API from '@/services/Api';

export default {
  props: ['shops'],
  data() {
    return {
      selectedShop: null,
      selectedQboVendor: null,
      qboVendors: []
    }
  },

  computed: {
    ...mapState(useVendorStore, ['data'])
  },

  methods: {
    ...mapActions(useVendorStore, ['updateQboDefault', 'deleteQboDefault']),
    async deleteQboVendor (v) {
      let defaultId = null
      this.data?.qboVendorDefaults?.forEach(d => {
        if (d.shop_id === this.selectedShop.id) {
          defaultId = d.id
        }
      })
      if (defaultId) {
        this.deleteQboDefault(defaultId)
      }
    },
    async updateQboVendor (v) {
      let found = false
      let updateArr = this.data?.qboVendorDefaults?.map(d => {
        if (d.shop_id == this.selectedShop.id) {
          found = true
          return {
            ...d,
            qbo_vendor_id: v
          }
        }
        return d
      }) || []
      console.log(updateArr, found)
      if (!found) {
        updateArr.push({
          shop_id: this.selectedShop.id,
          gb_vendor_id: this.data.id,
          qbo_vendor_id: v
        })
      }
      await this.updateQboDefault(updateArr)
    },
    async changeShop () {
      this.getQboVendors()
      this.setSelectedQboVendor()
    },
    async getQboVendors () {
      console.log(this.selectedShop)
      if (!this.selectedShop?.qbo_key || this.selectedShop?.qbo_key === '') {
        this.qboVendors = []
        return
      }
      const {data} = await API.get(`/api/shop/${this.selectedShop.id}/qboMapping/getQboVendors`)
      this.qboVendors = data
    },
    async setSelectedQboVendor () {
      let accountId = null
      this.data?.qboVendorDefaults?.forEach(d => {
        if (d.shop_id === this.selectedShop.id) {
          accountId = d.qbo_vendor_id
        }
      });
      this.selectedQboVendor = accountId
    }
  },

  created () {
    if (this.shops?.length && !this.selectedShop) {
      this.selectedShop = this.shops[0]
      this.getQboVendors()
      this.setSelectedQboVendor()
    }
  },

  watch: {
    shops (v) {
      if (v?.length && !this.selectedShop) {
        this.selectedShop = this.shops[0]
        this.getQboVendors()
        this.setSelectedQboVendor()
      }
    }
  }
}

</script>
