import Vue from 'vue'
import { defineStore } from 'pinia'
import { Message } from 'element-ui'
import { VendorsAPI } from '@/services/VendorsAPI'

export const VendorType = {
  TECH: 'tech',
  SUPPLIER: 'supplier',
  LOCATION: 'location',
  SALESREP: 'salesrep',
  CSR: 'csr',
  SALESOURCE: 'salesource'
}

export const useVendorStore = defineStore('vendor', {
  state: () => {
    return {
      id: null,
      data: {},

      newVendor: {
        organizationId: null,
        type: null
      },

      visible: false,
      fetching: false,
      saving: false,
      saved: false,
      revert: false,

      fetchingVendorTechsideConnection: false,
      
      documentTags: [],

      vendorTypes: {
        [VendorType.TECH]: {
          name: 'Tech',
          color: '#54a955'
        },
        [VendorType.SUPPLIER]: {
          name: 'Supplier',
          color: '#805ad5'
        },
        [VendorType.LOCATION]: {
          name: 'Location',
          color: '#bcbcbc'
        },
        [VendorType.SALESREP]: {
          name: 'Sales Rep',
          color: '#299fd7'
        },
        [VendorType.CSR]: {
          name: 'CSR',
          color: '#00897b'
        },
        [VendorType.SALESOURCE]: {
          name: 'Sale Source',
          color: '#f0ad4e'
        }
      }
    }
  },

  getters: {
    initials() {
      if (!this.data.name) return ''

      return this.data.name.slice(0, 2).toUpperCase()
    },

    primaryPhone() {
      if (!this.data.phones) return ''
      const p = this.data.phones.find(phone => phone.id === this.data.phone_id)
      return p ? p.number : ''
    },

    primaryEmail() {
      if (!this.data.emails) return ''
      const email = this.data.emails.find(e => e.id === this.data.email_id)
      return email ? email.email : ''
    },

    primaryAddress() {
      if (!this.data.addresses) return ''
      const address = this.data.addresses.find(a => a.id === this.data.g_address_id)
      return address ? address.full_address : ''
    },

    isCSR() {
      return this.data.is_csr === 1
    },

    isSaleSource() {
      return this.data.is_salesource === 1
    },

    isSalesRep() {
      return this.data.is_salesrep === 1
    },

    isTech() {
      return this.data.is_tech === 1
    },

    isLocation() {
      return this.data.is_location === 1
    },

    isSupplier() {
      return this.data.is_supplier === 1
    },

    techsidePhoneId() {
      return this.data.techsideInvite?.phone_id || null 
    },

    isTechsideLocked() {
      return this.data.is_tech === 1 &&
        (
          this.data.techside_user_id !== null || 
          (
            this.data.techsideInvite &&
            this.data.techsideInvite.rejected_at !== null
          )
        )
    }
  },

  actions: {
    openView(id) {
      this.visible = true
      this.id = id
    },

    openCreate(organizationId, type) {
      if (!organizationId) {
        Message.error('Organization ID is required')
        return
      }

      if (!(type in this.vendorTypes)) {
        Message.error('Invalid vendor type')
        return
      }

      this.newVendor = { organizationId, type }
      this.visible = true
    },

    close() {
      this.$reset()
    },

    async create(data) {
      try {
        this.saving = true
        this.id = await VendorsAPI.post('create', data)
        await this.fetch()
        this.saved = true
      } catch (e) {
        throw new Error('Failed to create vendor')
      } finally {
        this.saving = false
      }
    },

    async fetch() {
      this.fetching = true
      try {
        this.data = await VendorsAPI.get(`${this.id}`)
      } catch (e) {
        throw new Error('Failed to get vendor\'s information')
      } finally {
        this.fetching = false
      }
    },

    async updateName(name) {
      try {
        this.saving = true
        await VendorsAPI.patch(`${this.id}/name`, { name })
        this.data.name = name
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update name')
      } finally {
        this.saving = false
      }
    },

    async createPhone(number) {
      try {
        this.saving = true
        const phoneId = await VendorsAPI.post(`${this.id}/phones`, { number })
        this.data.phones.push({ id: phoneId, number })
        this.saved = true
        return phoneId
      } catch (e) {
        throw new Error(e.response.data)
      } finally {
        this.saving = false
      }
    },

    async updatePhone(phoneId, number) {
      try {
        this.saving = true
        await VendorsAPI.patch(`${this.id}/phones/${phoneId}`, { number })
        this.data.phones.forEach(phone => {
          if (phone.id === phoneId) {
            phone.number = number
          }
        })
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update phone number')
      } finally {
        this.saving = false
      }
    },

    async deletePhone(phoneId) {
      try {
        this.saving = true
        await VendorsAPI.delete(`${this.id}/phones/${phoneId}`)
        this.data.phones = this.data.phones.filter(phone => phone.id !== phoneId)
        if (this.data.phone_id === phoneId) {
          this.data.phone_id = null
        }
        this.saved = true
      } catch (e) {
        throw new Error('Failed to remove phone number')
      } finally {
        this.saving = false
      }
    },

    async setPrimaryPhone(phoneId) {
      try {
        this.saving = true
        await VendorsAPI.post(`${this.id}/phones/${phoneId}/primary`)
        this.data.phone_id = phoneId
        this.saved = true
      } catch (e) {
        throw new Error('Failed to set primary phone number')
      } finally {
        this.saving = false
      }
    },

    async createFax(number) {
      try {
        this.saving = true
        const faxId = await VendorsAPI.post(`${this.id}/faxes`, { number })
        this.data.faxes.push({ id: faxId, number })
        this.saved = true
        return faxId
      } catch (e) {
        throw new Error(e.response.data)
      } finally {
        this.saving = false
      }
    },

    async updateFax(faxId, number) {
      try {
        this.saving = true
        await VendorsAPI.patch(`${this.id}/faxes/${faxId}`, { number })
        this.data.faxes.forEach(fax => {
          if (fax.id === faxId) {
            fax.number = number
          }
        })
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update fax number')
      } finally {
        this.saving = false
      }
    },

    async deleteFax(faxId) {
      try {
        this.saving = true
        await VendorsAPI.delete(`${this.id}/faxes/${faxId}`)
        this.data.faxes = this.data.faxes.filter(fax => fax.id !== faxId)
        if (this.data.fax_id === faxId) {
          this.data.fax_id = null
        }
        this.saved = true
      } catch (e) {
        throw new Error('Failed to remove fax number')
      } finally {
        this.saving = false
      }
    },

    async setPrimaryFax(faxId) {
      try {
        this.saving = true
        await VendorsAPI.post(`${this.id}/faxes/${faxId}/primary`)
        this.data.fax_id = faxId
        this.saved = true
      } catch (e) {
        throw new Error('Failed to set primary fax number')
      } finally {
        this.saving = false
      }
    },

    async createEmail(email) {
      try {
        this.saving = true
        const emailId = await VendorsAPI.post(`${this.id}/emails`, { email })
        this.data.emails.push({ id: emailId, email })
        this.saved = true
        return emailId
      } catch (e) {
        throw new Error(e.response.data)
      } finally {
        this.saving = false
      }
    },

    async updateEmail(emailId, email) {
      try {
        this.saving = true
        await VendorsAPI.patch(`${this.id}/emails/${emailId}`, { email })
        this.data.emails.forEach(e => {
          if (e.id === emailId) {
            e.email = email
          }
        })
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update email address')
      } finally {
        this.saving = false
      }
    },

    async deleteEmail(emailId) {
      try {
        this.saving = true
        await VendorsAPI.delete(`${this.id}/emails/${emailId}`)
        this.data.emails = this.data.emails.filter(e => e.id !== emailId)
        if (this.data.email_id === emailId) {
          this.data.email_id = null
        }
        this.saved = true
      } catch (e) {
        throw new Error('Failed to remove email address')
      } finally {
        this.saving = false
      }
    },

    async setPrimaryEmail(emailId) {
      try {
        this.saving = true
        await VendorsAPI.post(`${this.id}/emails/${emailId}/primary`)
        this.data.email_id = emailId
        this.saved = true
      } catch (e) {
        throw new Error('Failed to set primary email address')
      } finally {
        this.saving = false
      }
    },

    async createAddress(address) {
      try {
        this.saving = true
        const addressId = await VendorsAPI.post(`${this.id}/addresses`, { address })
        this.data.addresses.push({ id: addressId, ...address })
        this.saved = true
        return addressId
      } catch (e) {
        throw new Error(e.response.data)
      } finally {
        this.saving = false
      }
    },

    async updateAddress(addressId, address) {
      try {
        this.saving = true
        await VendorsAPI.patch(`${this.id}/addresses/${addressId}`, { address })
        this.data.addresses.forEach(i => {
          if (i.id === addressId) {
            for (const key in address) {
              i[key] = address[key]
            }
          }
        })
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update address')
      } finally {
        this.saving = false
      }
    },

    async deleteAddress(addressId) {
      try {
        this.saving = true
        await VendorsAPI.delete(`${this.id}/addresses/${addressId}`)
        this.data.addresses = this.data.addresses.filter(i => i.id !== addressId)
        if (this.data.g_address_id === addressId) {
          this.data.g_address_id = null
        }
        this.saved = true
      } catch (e) {
        throw new Error('Failed to remove address')
      } finally {
        this.saving = false
      }
    },

    async setPrimaryAddress(addressId) {
      try {
        this.saving = true
        await VendorsAPI.post(`${this.id}/addresses/${addressId}/primary`)
        this.data.g_address_id = addressId
        this.saved = true
      } catch (e) {
        throw new Error('Failed to set primary address')
      } finally {
        this.saving = false
      }
    },

    async updateColor(color) {
      try {
        this.saving = true
        await VendorsAPI.patch(`${this.id}/color`, { color })
        this.data.color = color
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update vendor\'s color')
      } finally {
        this.saving = false
      }
    },

    async toggleStatus() {
      try {
        this.saving = true
        await VendorsAPI.patch(
          `${this.id}/status`,
          { is_disabled: !this.data.is_disabled }
        )
        this.data.is_disabled = !this.data.is_disabled
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update vendor\'s status')
      } finally {
        this.saving = false
      }
    },

    async updateNotes(notes) {
      try {
        this.saving = true
        await VendorsAPI.patch(`${this.id}/notes`, { notes })
        this.data.notes = notes
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update vendor\'s notes')
      } finally {
        this.saving = false
      }
    },

    async updateCommission(commission) {
      try {
        this.saving = true
        await VendorsAPI.patch(`${this.id}/commission`, commission)
        this.data.commission = commission
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update vendor\'s commission')
      } finally {
        this.saving = false
      }
    },

    async updateChipCommission(commission) {
      try {
        this.saving = true
        await VendorsAPI.patch(`${this.id}/chip-commission`, commission)
        this.data.chip_commission = commission
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update vendor\'s chip commission')
      } finally {
        this.saving = false
      }
    },

    async updateOverrideAllowedShops(val) {
      try {
        this.saving = true
        await VendorsAPI.patch(
          `${this.id}/override_allowed_shops`,
          { override_allowed_shops: val }
        )
        this.data.override_allowed_shops = val ? 1 : 0
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update setting')
      } finally {
        this.saving = false
      }
    },

    async updateShops(shop_ids) {
      try {
        this.saving = true
        await VendorsAPI.patch(`${this.id}/shops`, { shop_ids })
        this.data.shop_ids = shop_ids
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update shops')
      } finally {
        this.saving = false
      }
    },

    async updateHideCompletedJobs(val) {
      try {
        this.saving = true
        await VendorsAPI.patch(
          `${this.id}/hide-completed-jobs`,
          { hide_completed_jobs: val }
        )
        this.data.hide_completed_jobs = val ? 1 : 0
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update setting')
      } finally {
        this.saving = false
      }
    },

    async updateRequireClockIn(val) {
      try {
        this.saving = true
        await VendorsAPI.patch(
          `${this.id}/require-clock-in`,
          { require_clock_in: val }
        )
        this.data.require_clock_in = val ? 1 : 0
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update setting')
      } finally {
        this.saving = false
      }
    },

    async updateRequireTechPickup(val) {
      try {
        this.saving = true
        await VendorsAPI.patch(
          `${this.id}/require-tech-pickup`,
          { require_tech_pickup: val }
        )
        this.data.require_tech_pickup = val ? 1 : 0
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update setting')
      } finally {
        this.saving = false
      }
    },

    async updateRequirePreInspection(val) {
      try {
        this.saving = true
        await VendorsAPI.patch(
          `${this.id}/require-pre-inspection`,
          { require_pre_inspection: val }
        )
        this.data.require_pre_inspection = val ? 1 : 0
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update setting')
      } finally {
        this.saving = false
      }
    },

    async updateTechsideHidePrices(val) {
      try {
        this.saving = true
        await VendorsAPI.patch(
          `${this.id}/techside-hide-prices`,
          { techside_hide_prices: val }
        )
        this.data.techside_hide_prices = val ? 1 : 0
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update setting')
      } finally {
        this.saving = false
      }
    },

    async updateDefaultSalesRep(val) {
      try {
        this.saving = true
        await VendorsAPI.patch(
          `${this.id}/default-sales-rep`,
          { default_salesrep_id: val }
        )
        this.data.default_salesrep_id = val
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update setting')
      } finally {
        this.saving = false
      }
    },

    async updateShortCode(val) {
      try {
        this.saving = true
        await VendorsAPI.patch(`${this.id}/short-code`, { short_code: val })
        this.data.short_code = val
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update setting')
      } finally {
        this.saving = false
      }
    },

    async updateUsername(username) {
      try {
        this.saving = true
        const invitation_status = await VendorsAPI.patch(
          `${this.id}/username`,
          { username }
        )
        this.data.username = username
        this.data.invitation_status = invitation_status
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update username')
      } finally {
        this.saving = false
      }
    },

    async updateHideSentSSMJobs(val) {
      try {
        this.saving = true
        await VendorsAPI.patch(
          `${this.id}/hide-sent-ssm-jobs`,
          { hide_sent_ssm_jobs: val }
        )
        this.data.hide_sent_ssm_jobs = val ? 1 : 0
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update setting')
      } finally {
        this.saving = false
      }
    },

    async updateSalesidejobStartOnSend(val) {
      try {
        this.saving = true
        await VendorsAPI.patch(
          `${this.id}/salesidejob-start-on-send`,
          { salesidejob_start_on_send: val }
        )
        this.data.salesidejob_start_on_send = val ? 1 : 0
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update setting')
      } finally {
        this.saving = false
      }
    },

    async updateSSMAllowedShops(shop_ids) {
      try {
        this.saving = true
        await VendorsAPI.patch(`${this.id}/ssm-allowed-shops`, { shop_ids })
        this.data.ssm_allowed_shop_ids = shop_ids
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update SSM allowed shops')
      } finally {
        this.saving = false
      }
    },

    async updateDefaultLocation(val) {
      try {
        this.saving = true
        await VendorsAPI.patch(
          `${this.id}/default-location`,
          { default_location_id: val }
        )
        this.data.default_location_id = val
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update setting')
      } finally {
        this.saving = false
      }
    },

    async updateCommercialAccounts(val) {
      try {
        this.saving = true
        await VendorsAPI.patch(`${this.id}/commercial-accounts`, {
          commercial_account_ids: val
        })
        this.data.commercial_account_ids = val
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update commercial accounts')
      } finally {
        this.saving = false
      }
    },

    async updateLocations(val) {
      try {
        this.saving = true
        await VendorsAPI.patch(`${this.id}/locations`, {
          location_ids: val
        })
        this.data.location_ids = val
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update locations')
      } finally {
        this.saving = false
      }
    },

    async updateSaleSources(val) {
      try {
        this.saving = true
        await VendorsAPI.patch(`${this.id}/sale-sources`, {
          sale_source_ids: val
        })
        this.data.sale_source_ids = val
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update sale sources')
      } finally {
        this.saving = false
      }
    },

    async getDocuments() {
      try {
        this.data.documents = await VendorsAPI.get(`${this.id}/documents`)
      } catch (e) {
        throw new Error('Failed to get documents')
      }
    },

    async uploadDocument(data) {
      try {
        this.saving = true
        await VendorsAPI.post(`${this.id}/documents`, data)
        this.saved = true
      } catch (e) {
        throw new Error('Failed to upload document')
      } finally {
        this.saving = false
      }
    },

    async deleteDocument(id) {
      try {
        this.saving = true
        await Vue.prototype.$unum.documents().delete(`data/${this.id}/${id}`)
        this.data.documents = this.data.documents.filter(doc => doc.id !== id)
        this.saved = true
      } catch (e) {
        throw new Error('Failed to delete the document')
      } finally {
        this.saving = false
      }
    },

    async getDocumentTags() {
      try {
        this.documentTags = await VendorsAPI.get('documents/tags')
      } catch (e) {
        throw new Error('Failed to get document tags')
      }
    },

    async updateAvatar(data) {
      try {
        this.saving = true
        this.data.avatar = await VendorsAPI.patch(
          `${this.id}/avatar`,
          { img: data }
        )
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update avatar')
      } finally {
        this.saving = false
      }
    },

    async updateQboDefault(defaults) {
      try {
        this.saved = true
        await VendorsAPI.patch(
          `${this.id}/qbo-defaults`,
          { defaults }
        )
        this.data.qboVendorDefaults = defaults
        this.saved = true
      } catch (e) {
        throw new Error('Failed to update QBO vendor defaults')
      } finally {
        this.saving = false
      }
    },

    async getVendorTechsideConnection() {
      try {
        this.fetchingVendorTechsideConnection = true
        const temp = await VendorsAPI.get(`techside/${this.id}/vendor-techside-connection`)
        this.data = Object.assign({}, {...this.data, vendorTechsideConnection: temp})
      } catch (e) {
        throw new Error(e?.response?.data || 'Failed to get vendor techside connection information')
      }
      this.fetchingVendorTechsideConnection = false
    },

    async sendVendorTechsideConnectionInvitation(phoneId) {
      try {
        this.data.techsideInvite = await VendorsAPI.post(`techside/${this.id}/invite`, {
          phoneId
        })
        this.getVendorTechsideConnection()
      } catch (e) {
        throw new Error(e?.response?.data || 'Failed to send vendor techside invitation')
      }
    },

    async disconnectTechnician() {
      try {
        await VendorsAPI.post(`techside/${this.id}/terminate`)
        this.data.techsideInvite = null
        this.getVendorTechsideConnection()
      } catch (e) {
        throw new Error(e?.response?.data || 'Failed to disconnect Tech')
      }
    },

    async cancelInvitation() {
      try {
        await VendorsAPI.post(`techside/${this.id}/cancel`)
        this.data.techsideInvite = null
        this.getVendorTechsideConnection()
      } catch (e) {
        throw new Error(e?.response?.data || 'Failed to cancel tech invitation')
      }
    }
  }
})
