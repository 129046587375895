import Vue from 'vue'
import axios from 'axios'
import cfg from '@/config'

const baseUrl = `${cfg.uri}/unum/consumer-confirmation`

const ConfirmationsAPI = axios.create({
  baseURL: baseUrl
})

ConfirmationsAPI.interceptors.request.use(async request => {
  const auth = Vue.prototype.$auth

  request.headers.Authorization = auth.getAuthorizationHeader()
  request.headers['Visitor-Fingerprint-Id'] = auth.getVisitorFingerprintId()

  return request
})

ConfirmationsAPI.interceptors.response.use(
  response => response.data,
  error => {
    const auth = Vue.prototype.$auth

    if (error.response?.status === 401) {
      if (error.response?.data?.type === 'suspended_visitor_fingerprint_id') {
        auth.handleSuspendedFingerprint()
      } else {
        auth.logout()
      }
    }
  }
)

export { ConfirmationsAPI }
