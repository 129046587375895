<template>
  <div class="t-flex">
    <el-scrollbar
      class="t-flex-grow t-h-[700px] t-my-4 t-mr-2"
      wrap-class="t-overflow-x-hidden t-flex-shrink-0 t-py-2 t-px-8"
    >
      <div class="qbo-mapping-dialog-body">
        <div v-if="missingQboAccount.length">
          <el-divider class="t-my-6">Vendor Types</el-divider>
          <p class="t-my-6">Please map each of the following vendor types to an expense account in QBO</p>
          <quick-books-account-select
            v-for="type in missingQboAccount"
            class="qbo-account-select"
            :label="gbExportItemLabel[type.id]"
            :type="type"
          />
        </div>

        <div v-if="missingQboVendor.length">
          <el-divider class="t-my-6">Vendors</el-divider>
          <p class="t-my-6">Please map each of the following vendors to a vendor in QBO</p>

          <quick-books-vendor-select
            v-for="vendor in missingQboVendor"
            :vendor="vendor"
            :options="options"
            class="qbo-account-select"
          />
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import QuickBooksAccountSelect from './QuickBooksAccountSelect.vue'
import QuickBooksVendorSelect from './QuickBooksVendorSelect.vue'
import { mapState, mapActions } from 'pinia'
import { useQboCommissionStore } from '@/components/modals/QuickBooksCommissionValidationDialog/qboCommission.store.js'

export default {
  data() {
    return {
      options: [],
      gbExportItemLabel: {
        19: 'CSR',
        20: 'Sale Source',
        21: 'Sales Rep',
        22: 'Tech',
        23: 'Location'
      }
    }
  },
  components: {
    QuickBooksAccountSelect,
    QuickBooksVendorSelect
  },

  async created() {
    this.initialize()
  },

  computed: {
    ...mapState(useQboCommissionStore, ['missingQboAccount', 'missingQboVendor'])
  },

  methods: {
    ...mapActions(useQboCommissionStore, [
      'getAllQboVendorsForShop'
    ]),

    async initialize () {
      try {
        const results = await this.getAllQboVendorsForShop()
        this.options = results.map((v) => {
          return {
            value: v.Id,
            label: v.DisplayName
          }
        })
      } catch (e) {
        console.log('error:', e)
      }
    }
  }
}
</script>

<style lang="scss">
  .qbo-mapping-dialog-body {
    .el-input {
      @apply t-w-[250px];
    }
  }
  .qbo-account-select {
    margin-bottom: 8px;
  }
</style>
