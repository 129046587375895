<template>
  <div
    class="t-flex t-py-2"
  >
    <div class="t-pt-1">
      <el-badge
        class="commission-row-badge"
        type="info"
        :hidden="commissionType === 'flat' || !total"
        :value="`$${total}`"
      >
        <span>{{ vendor.name }}</span>
      </el-badge>
    </div>
    <div class="t-flex-grow t-min-w-[2rem]"></div>
    <div class="">
      <input-with-label
        type="money"
        v-model="commission"
        class="commission-type-input multi-commission-row-input"
        :autoFocus="true"
        lazy
      >
        <el-select
          v-model="commissionType"
          size="mini"
          slot="append"
          class="commission-type-select t-w-24"
          placeholder=""
        >
          <el-option value="flat" label="$" />
          <el-option value="percent" label="% Total" />
          <el-option value="percent_subtotal" label="% Subtotal" />
          <el-option value="percent_margin" label="% Margin" />
        </el-select>
      </input-with-label>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    vendor: {},
    total: null
  },
  computed: {
    commission: {
      get() {
        return this.vendor?.['commission'] || 0
      },
      set(c) {
        this.$emit('update-commission', {
          ...this.vendor,
          commission: c
        })
      }
    },
    commissionType: {
      get() {
        return this.vendor?.['commission_flat_or_percent'] || 'flat'
      },
      set(ct) {
        this.$emit('update-commission', {
          ...this.vendor,
          commission_flat_or_percent: ct
        })
      }
    },
  }
}
</script>

<style lang="scss">
.multi-commission-row-input {
  .el-input {
    .el-input__inner {
      width: 100px;
    }
  }
}

.commission-row-badge {
  z-index: 1;
}

.comm-suffix {
  line-height: 30px;
}
</style>