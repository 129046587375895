<template>
  <b-navbar
    toggleable="lg"
    type="dark"
    variant="dark"
    :class="{ isMobileNavBar: isMobile, topMenuNavBar: !isMobile }"
  >
    <div style="height: 0px; width: 0px; overflow:hidden;">
      <input name="autofillEmailBlocker" />
      <input name="autofillPasswordBlocker" type="password" />
    </div>
    <b-navbar-brand
      style="cursor: pointer;"
      tag="a"
      @click="changeRouteWithCheck('/')"
    >
      <div
        v-if="!isMobile"
        @click="() => (showNewVersionDialog = true)"
        class="newVersionComing"
      >
        New Version
      </div>
      <div
        v-if="isMobile"
        @click="() => (showNewVersionDialog = true)"
        class="newVersionComing-sm"
      >
        New Version
      </div>
    </b-navbar-brand>
    <v-icon
      v-if="showLoading"
      name="spinner"
      style="color: white;"
      spin
      scale="1.5"
    />
    <b-navbar-toggle
      id="navBurger"
      target="navbarCollapse"
      data-toggle="collapse"
      data-target="#navbarCollapse"
      style="margin-left:auto"
    ></b-navbar-toggle>

    <b-collapse is-nav id="navbarCollapse" class="top-menu-options">
      <b-navbar-nav style="margin-right:0px;" class="ml-auto">
        <li
          v-if="
            isMobile &&
              !localPermissionCheck(permissionEnum.TECHCOMMISSION, $store.state)
          "
        >
          <b-row>
            <b-col cols="6" class="nav-item nav-search mx-auto">
              <GlobalSearch
                v-if="localPermissionCheck(permissionEnum.JOB, $store.state)"
                @selected="selectedJob"
              />
            </b-col>
            &nbsp;
            <b-col
              v-if="localPermissionCheck(permissionEnum.JOB, $store.state)"
              cols="3"
              class="nav-item nav-search mx-auto"
            >
              <JobNav
                :filter="newSearchFilter"
                @toggleNavSearch="toggleNavSearch"
              />
            </b-col>
            <b-col
              v-if="localPermissionCheck(permissionEnum.JOB, $store.state)"
              cols="2"
              class="nav-item mx-auto my-auto"
            >
              <b-dropdown
                size="sm"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template slot="button-content">
                  <i v-if="!navSearching" class="fa fa-caret-down"></i>
                  <loader v-if="navSearching" />
                  <span class="sr-only">Search</span>
                </template>
                <b-dropdown-item
                  class="ignore-click"
                  href="#"
                  @click="updateNewSearchFilter('JOB #')"
                  >JOB #</b-dropdown-item
                >
                <b-dropdown-item
                  class="ignore-click"
                  href="#"
                  @click="updateNewSearchFilter('VIN #')"
                  >VIN # (6 Char Min)</b-dropdown-item
                >
                <b-dropdown-item
                  class="ignore-click"
                  href="#"
                  @click="updateNewSearchFilter('REF #')"
                  >REFERRAL #</b-dropdown-item
                >
                <b-dropdown-item
                  class="ignore-click"
                  href="#"
                  @click="updateNewSearchFilter('RO #')"
                  >RO #</b-dropdown-item
                >
                <b-dropdown-item
                  class="ignore-click"
                  href="#"
                  @click="updateNewSearchFilter('PO #')"
                  >PO #</b-dropdown-item
                >
                <b-dropdown-item
                  class="ignore-click"
                  href="#"
                  @click="updateNewSearchFilter('ADH #')"
                  >ADH #/STOCK #</b-dropdown-item
                >
                <b-dropdown-item
                  class="ignore-click"
                  href="#"
                  @click="updateNewSearchFilter('PLATE #')"
                  >PLATE #</b-dropdown-item
                >
                <b-dropdown-item
                  class="ignore-click"
                  href="#"
                  @click="updateNewSearchFilter('UNIT #')"
                  >UNIT #</b-dropdown-item
                >
                <b-dropdown-item
                  class="ignore-click"
                  href="#"
                  @click="updateNewSearchFilter('VEHICLE')"
                  >VEHICLE</b-dropdown-item
                >
                <b-dropdown-item
                  class="ignore-click"
                  href="#"
                  @click="updateNewSearchFilter('MOBILE #')"
                  >MOBILE #</b-dropdown-item
                >
                <b-dropdown-item
                  class="ignore-click"
                  href="#"
                  @click="openDraftsDialog()"
                  >Drafts</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
          </b-row>
        </li>
        <b-nav-item
          v-if="
            localPermissionCheck(permissionEnum.JOB, $store.state) &&
              !localPermissionCheck(permissionEnum.JOBVIEWONLY)
          "
          class="ignore-click toggle-collapse"
          id="newJobNav"
          href="/jobs/new"
          @click="changeWithPermissionCheck(permissionEnum.JOB, '/jobs/new')"
          right
          >New Job</b-nav-item
        >
        <b-popover
          v-if="localPermissionCheck(permissionEnum.JOB, $store.state)"
          triggers="hover"
          :delay="{ show: 400, hide: 2000 }"
          :placement="'bottom'"
          target="newJobNav"
        >
          <div style="text-align: center;">
            <a id="newTabLink" target="_blank" href="/jobs/new"
              >Open in new tab</a
            >
            <br />
            <a id="newTabLink" target="" href="/jobs/creditmemo/none/none"
              >Create Credit Memo</a
            >
            <br />
          </div>
        </b-popover>
        <b-nav-item
          v-if="
            !localPermissionCheck(permissionEnum.TECHCOMMISSION, $store.state)
          "
          class="ignore-click toggle-collapse"
          href="/jobs"
          @click="
            false
            changeWithPermissionCheck(permissionEnum.JOBMANAGER, '/jobs')
          "
          right
          >Jobs</b-nav-item
        >
        <b-nav-item-dropdown
          text="Manage"
          v-if="
            !localPermissionCheck(permissionEnum.TECHCOMMISSION, $store.state)
          "
        >
          <b-dropdown-item
            class="ignore-click toggle-collapse"
            href="/manage?tab=4"
            @click="
              changeWithPermissionCheck(
                permissionEnum.MANAGECSRS,
                '/manage?tab=4'
              )
            "
            >CSRS</b-dropdown-item
          >
          <b-dropdown-item
            class="ignore-click toggle-collapse"
            href="/manage?tab=5"
            @click="
              changeWithPermissionCheck(
                permissionEnum.MANAGESALESREPS,
                '/manage?tab=5'
              )
            "
            >Sales Reps</b-dropdown-item
          >
          <b-dropdown-item
            class="ignore-click toggle-collapse"
            href="/manage?tab=6"
            @click="
              changeWithPermissionCheck(
                permissionEnum.MANAGETECHS,
                '/manage?tab=6'
              )
            "
            >Techs</b-dropdown-item
          >
          <b-dropdown-item
            class="ignore-click toggle-collapse"
            href="/manage?tab=7"
            @click="
              changeWithPermissionCheck(
                permissionEnum.MANAGESALESSOURCES,
                '/manage?tab=7'
              )
            "
            >Sale Sources</b-dropdown-item
          >
          <b-dropdown-item
            class="ignore-click toggle-collapse"
            href="/manage?tab=8"
            @click="
              changeWithPermissionCheck(
                permissionEnum.MANAGELOCATIONS,
                '/manage?tab=8'
              )
            "
            >Locations</b-dropdown-item
          >
          <b-dropdown-item
            class="ignore-click toggle-collapse"
            href="/manage?tab=9"
            @click="
              changeWithPermissionCheck(
                permissionEnum.MANAGEINSURANCE,
                '/manage?tab=9'
              )
            "
            >Insurance/Fleet</b-dropdown-item
          >
          <b-dropdown-item
            class="ignore-click toggle-collapse"
            href="/manage?tab=10"
            @click="
              changeWithPermissionCheck(
                permissionEnum.MANAGECOMMERCIALACCOUNTS,
                '/manage?tab=10'
              )
            "
            >Comm. Accts</b-dropdown-item
          >
          <b-dropdown-item
            class="ignore-click toggle-collapse"
            href="/manage?tab=11"
            @click="
              changeWithPermissionCheck(
                permissionEnum.MANAGETAXES,
                '/manage?tab=11'
              )
            "
            >Taxes</b-dropdown-item
          >
        </b-nav-item-dropdown>
        <b-nav-item
          v-if="localPermissionCheck(permissionEnum.ARAP, $store.state)"
          class="ignore-click toggle-collapse"
          href="/ar"
          @click="changeWithPermissionCheck(permissionEnum.ARAP, '/ar')"
          right
          >AR/AP</b-nav-item
        >
        <b-nav-item
          v-if="
            !localPermissionCheck(permissionEnum.TECHCOMMISSION, $store.state)
          "
          class="ignore-click toggle-collapse"
          href="/reports"
          @click="changeWithPermissionCheck(permissionEnum.REPORTS, '/reports')"
          right
          >Reports</b-nav-item
        >
        <b-nav-item-dropdown v-if="roleCheck('account_manager')" text="Admin">
          <b-dropdown-item
            class="ignore-click toggle-collapse"
            href="/admin?tab=14"
            @click="
              changeWithPermissionCheck(
                permissionEnum.ACCOUNTS,
                '/admin?tab=14'
              )
            "
            >Accounts</b-dropdown-item
          >
          <b-dropdown-item
            class="ignore-click toggle-collapse"
            href="/admin?tab=16"
            @click="
              changeWithPermissionCheck(permissionEnum.USERS, '/admin?tab=16')
            "
            >Users</b-dropdown-item
          >
          <b-dropdown-item
            v-if="roleCheck('admin')"
            class="ignore-click toggle-collapse"
            href="/admin?tab=17"
            @click="changeRouteWithCheck('/admin?tab=17')"
            >TechSide Users</b-dropdown-item
          >
          <b-dropdown-item
            class="ignore-click toggle-collapse"
            href="/admin?tab=15"
            @click="
              changeWithPermissionCheck(permissionEnum.SHOPS, '/admin?tab=15')
            "
            >Shops</b-dropdown-item
          >
          <b-dropdown-item
            v-if="roleCheck('admin')"
            class="ignore-click toggle-collapse"
            href="/admin?tab=24"
            @click="
              changeWithPermissionCheck(
                permissionEnum.ACCOUNTINGPACKAGES,
                '/admin?tab=24'
              )
            "
            >Accounting Packages</b-dropdown-item
          >
          <b-dropdown-item
            v-if="roleCheck('admin')"
            class="ignore-click toggle-collapse"
            href="/admin?tab=25"
            @click="changeRouteWithCheck('/admin?tab=25')"
            >Organizations</b-dropdown-item
          >
          <b-dropdown-item
            v-if="roleCheck('admin')"
            class="ignore-click toggle-collapse"
            href="/admin?tab=23"
            @click="changeRouteWithCheck('/admin?tab=23')"
            >NAGS Licenses</b-dropdown-item
          >
          <b-dropdown-item
            v-if="roleCheck('admin')"
            class="ignore-click toggle-collapse"
            href="/fundingCenter"
            @click="changeRouteWithCheck('/fundingCenter')"
            right
            >Funding Center</b-dropdown-item
          >
          <b-dropdown-item
            v-if="roleCheck('admin')"
            class="ignore-click toggle-collapse"
            href="/shopAnalytics"
            @click="changeRouteWithCheck('/shopAnalytics')"
            right
            >Shop Analytics</b-dropdown-item
          >
          <b-dropdown-item
            class="ignore-click toggle-collapse"
            @click="openUserAuditDialog"
            >User Audit</b-dropdown-item
          >
        </b-nav-item-dropdown>
        <b-nav-item
          v-if="localPermissionCheck(permissionEnum.WAREHOUSE, $store.state)"
          class="ignore-click toggle-collapse"
          href="/warehouse"
          @click="
            changeWithPermissionCheck(permissionEnum.WAREHOUSE, '/warehouse')
          "
          right
          >Warehouse</b-nav-item
        >
        <b-nav-item
          v-if="localPermissionCheck(permissionEnum.JOBMANAGER, $store.state)"
          class="ignore-click toggle-collapse"
          href="/activity"
          @click="
            changeWithPermissionCheck(permissionEnum.JOBMANAGER, '/activity')
          "
          right
          >TS Activity</b-nav-item
        >
        <div
          @click="openShoppingCart"
          class="t-flex t-items-center t-justify-center t-ml-3 t-mr-5"
          style="position: relative !important;"
        >
          <span
            v-if="cartItemsCount > 0"
            id="cart-items-count"
            class="t-flex t-items-center t-justify-center"
          >
            {{ cartItemsCount }}
          </span>
          <i
            class="el-icon-shopping-cart-1 t-text-xl t-bg-white t-p-2 t-rounded t-cursor-pointer hover:t-bg-gray-300"
          ></i>
        </div>
        <b-nav-item-dropdown
          :text="
            $store.state.user != null
              ? $store.state.user.username +
                (!isNagsUser ? ' (Non-NAGS)' : ' (NAGS)')
              : ''
          "
          right
          class="dropdown-account"
        >
          <b-dropdown-item
            v-if="roleCheck('account_manager')"
            class="ignore-click toggle-collapse"
            @click="openUserDialog"
            >Settings</b-dropdown-item
          >
          <b-dropdown-item
            v-if="$store.state.user.account_manager === 1"
            class="ignore-click toggle-collapse"
            @click="openBillingDetails"
            >Billing Details</b-dropdown-item
          >
          <!-- <b-dropdown-item class="ignore-click toggle-collapse" @click="switchToOldSite">Switch to Old Site</b-dropdown-item> -->
          <b-dropdown-item id="column-width-toggler"
            >Wider Panels</b-dropdown-item
          >
          <b-dropdown-item id="column-height-toggler"
            >Collapse Panels</b-dropdown-item
          >
          <b-dropdown-item
            v-if="
              allowQuickBooks &&
                !localPermissionCheck(permissionEnum.TECHCOMMISSION)
            "
            class="ignore-click toggle-collapse"
            @click="callQuickBooksDialog"
            >QuickBooks</b-dropdown-item
          >
          <b-dropdown-item id="accordion-totals-toggler" style="display: none"
            >Totals</b-dropdown-item
          >
          <b-dropdown-item
            class="ignore-click toggle-collapse"
            href="/tutorials"
            @click="changeRouteWithCheck('/tutorials')"
            >Help Videos</b-dropdown-item
          >
          <b-dropdown-item
            class="ignore-click toggle-collapse"
            @click="callDashboardDialog"
            >Dashboard</b-dropdown-item
          >
          <b-dropdown-item
            class="ignore-click toggle-collapse"
            @click="joinMeeting"
            >Join Meeting</b-dropdown-item
          >
          <b-dropdown-item
            class="ignore-click toggle-collapse"
            @click="logoutUser"
            >Logout</b-dropdown-item
          >
        </b-nav-item-dropdown>
        <li
          v-if="
            !isMobile &&
              localPermissionCheck(permissionEnum.JOB, $store.state) &&
              !localPermissionCheck(permissionEnum.TECHCOMMISSION, $store.state)
          "
          class="nav-item nav-search"
          id="globalSearch"
        >
          <GlobalSearch @selected="selectedJob" />
        </li>
        <li
          v-if="
            !isMobile &&
              localPermissionCheck(permissionEnum.JOB, $store.state) &&
              !localPermissionCheck(permissionEnum.TECHCOMMISSION, $store.state)
          "
          class="nav-item nav-search"
        >
          <JobNav
            :filter="newSearchFilter"
            @toggleNavSearch="toggleNavSearch"
          />
        </li>
        <b-nav-item
          v-if="
            !isMobile &&
              localPermissionCheck(permissionEnum.JOB, $store.state) &&
              !localPermissionCheck(permissionEnum.TECHCOMMISSION, $store.state)
          "
          right
          id="newSearchFilterButton"
        >
          <b-dropdown
            size="sm"
            toggle-class="text-decoration-none"
            right
            no-caret
          >
            <template slot="button-content">
              <i v-if="!navSearching" class="fa fa-caret-down"></i>
              <loader v-if="navSearching" />
              <span class="sr-only">Search</span>
            </template>
            <b-dropdown-item href="#" @click="updateNewSearchFilter('JOB #')"
              >JOB #</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="updateNewSearchFilter('VIN #')"
              >VIN # (6 Char Min)</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="updateNewSearchFilter('REF #')"
              >REFERRAL #</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="updateNewSearchFilter('RO #')"
              >RO #</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="updateNewSearchFilter('PO #')"
              >PO #</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="updateNewSearchFilter('ADH #')"
              >ADH #/STOCK #</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="updateNewSearchFilter('PLATE #')"
              >PLATE #</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="updateNewSearchFilter('UNIT #')"
              >UNIT #</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="updateNewSearchFilter('VEHICLE')"
              >VEHICLE</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="updateNewSearchFilter('MOBILE #')"
              >MOBILE #</b-dropdown-item
            >
            <b-dropdown-item
              class="ignore-click"
              href="#"
              @click="openDraftsDialog()"
              >Drafts</b-dropdown-item
            >
          </b-dropdown>
        </b-nav-item>
      </b-navbar-nav>
      <SideBar
        location="top"
        v-if="
          !localPermissionCheck(permissionEnum.TECHCOMMISSION, $store.state)
        "
      />
    </b-collapse>
    <GlaxisDialog />
    <GlaxisClaimDialog />
    <GlaxisReviewCoverageDialog />
    <DashboardDialog />
    <QuickBooksDialog v-if="allowQuickBooks" />
    <TextMessagesDialog />
    <TextMessageTemplatesDialog />
    <TextMessageEmailTemplatesDialog />
    <TextMessageGbFormTemplatesDialog />
    <TextMessageConfirmationDialog v-if="showConfirmationDialog" />
    <TextMessageInvoiceDialog />
    <TextMessagePaymentDialog :filePickerKey="filePickerKey" />
    <TextMessageSignatureDialog />
    <adDialog />
    <TextMessageTssInviteDialog />
    <SalesideJobDialog
      :visible="salesideJobDialogVisible"
      :mobileJobId="mobileJobNumber"
      :shouldOpenMobileInbox="shouldOpenMobileInbox"
      title="Unsent Job"
      @close="salesideJobDialogVisible = false"
    />
    <BillingDetailsDialog
      :accountId="$store.state.user.account_id"
      :modalId="'userBillingDetailsDialog'"
    />
    <ShoppingCartDialog />
    <NotificationsDialog />
    <NotificationsSettingsDialog />
    <UserDialog modalName="userSettingDialog" :isExpanded="true" />
    <UserAuditDialog :visible.sync="userAuditLogVisible" />
    <new-version-dialog :visible.sync="showNewVersionDialog" />
    <DraftsDialog :visible.sync="draftsDialogVisible" />
    <AdminUtilsDialog
      :visible.sync="adminUtilsOpen"
      :filePickerKey="filePickerKey"
    />
    <Chat />
    <PurchaseOrderDialog />
    <ReceiveDialog />
    <TechPickUpDialog />
    <ReturnDialog />
    <GenericEmailDialog :filePickerKey="filePickerKey" />
    <GenericSmsDialog :filePickerKey="filePickerKey" />
    <support-center :filePickerKey="filePickerKey" />
    <VendorProfileDialog />
    <QuickBooksAccountMappingDialog />
    <QuickBooksSyncDialog />
  </b-navbar>
</template>

<script>
import { mapState } from 'vuex'
import SideBar from '@/components/inc/SideBar.vue'
// import FontAwesomeIcon from '@fortawesome/vue-fontawesome'
import faSearch from '@fortawesome/fontawesome-free-solid/faSearch'
import faInfoCircle from '@fortawesome/fontawesome-free-solid/faInfoCircle'
import Job from '@/scripts/objects/job'
import GlobalSearch from '@/components/Helpers/GlobalSearch/Index.vue'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import JobNav from '@/components/Helpers/JobNav.vue'
import GlaxisDialog from '@/components/modals/GlaxisDialogs/Index'
import GlaxisClaimDialog from '@/components/modals/GlaxisDialogs/claim/Index'
import GlaxisReviewCoverageDialog from '@/components/modals/GlaxisDialogs/reviewCoverage/Index'
import DashboardDialog from '@/components/modals/DashboardDialog.vue'
import QuickBooksDialog from '@/components/modals/QuickBooksDialog.vue'
import TextMessagesDialog from '@/components/Twilio/TextMessages/Index'
import TextMessageTemplatesDialog from '@/components/Twilio/TextMessages/Templates/Index'
import TextMessageEmailTemplatesDialog from '@/components/Twilio/TextMessages/EmailTemplates/Index'
import TextMessageGbFormTemplatesDialog from '@/components/Twilio/TextMessages/GbFormTemplates/Index'
import TextMessageConfirmationDialog from '@/components/Twilio/TextMessages/JobConfirmation/Index'
import TextMessageInvoiceDialog from '@/components/Twilio/TextMessages/JobInvoice/Index'
import TextMessagePaymentDialog from '@/components/Twilio/TextMessages/JobPayment/Index'
import TextMessageSignatureDialog from '@/components/Twilio/TextMessages/JobSignature/Index'
import TextMessageTssInviteDialog from '@/components/Twilio/TextMessages/TssInvite/Index'
import adDialog from '@/components/Twilio/TextMessages/AdModal'
import ShoppingCartDialog from '@/components/ShoppingCart/Index'
import NotificationsDialog from '@/components/Notifications/Index'
import NotificationsSettingsDialog from '@/components/NotificationsSettings/Index'
import {
  permissions,
  permissionCheck,
  permissionHumanReadable
} from '@/scripts/helpers/permissions.helpers'
import SalesideJobDialog from '@/components/modals/SalesideJobDialog'
import { get } from 'vuex-pathify'
import $ from 'jquery'
import Loader from '../Helpers/Loader'
import { shoppingCartActions } from '@/store/modules/shopping-cart/types'
import BillingDetailsDialog from '@/components/modals/BillingDetailsDialog.vue'
import Account from '@/scripts/objects/account'
import UserDialog from '@/components/modals/UserDialog.vue'
import UserAuditDialog from '@/components/modals/UserAuditDialog.vue'
import NewVersionDialog from '@/components/modals/NewVersionDialog.vue'
import DraftsDialog from '@/components/modals/DraftsDialog.vue'
import AdminUtilsDialog from '@/components/modals/AdminUtilsDialog/Index.vue'
import Chat from '@/components/Chat/Index'
import SupportCenter from '@/components/Support/Index.vue'
import PurchaseOrderDialog from '@/components/WarehousePage/PurchaseOrders/ViewDialog/Index.vue'
import ReceiveDialog from '@/components/WarehousePage/Receives/ViewDialog/Index.vue'
import TechPickUpDialog from '@/components/WarehousePage/TechPickUps/ViewDialog/Index.vue'
import ReturnDialog from '@/components/WarehousePage/Returns/ViewDialog/Index.vue'
import {
  GenericEmailDialog,
  GenericSmsDialog
} from '@/components/modals/GenericShareDialogs'
import VendorProfileDialog from '@/components/Vendors/Profile/Index.vue'
import QuickBooksAccountMappingDialog from '@/components/modals/QuickBooksAccountMappingDialog/Index.vue'
import QuickBooksSyncDialog from '@/components/modals/QuickBooksSyncDialog/Index.vue'

// just to keep this for the moment
// <Autocomplete :dataSet='quickSearchDataSet' @selected="selectedJob" :searchValue.sync="quickSearchVal" :filterInternally='false' :smallInput="true" placeholder="Quick Search..." :autoCompleteHeight="120" />

export default {
  props: [
    'title',
    'changeDetected',
    'filePickerKey',
    'showLoading',
    'allowQuickBooks'
  ],
  data() {
    return {
      quickSearchVal: '',
      quickSearchDataSet: [],
      promiseToPass: null,
      url: null,
      permissionEnum: permissions,
      newSearchFilter: 'JOB #',
      navSearching: false,
      mobileJobNumber: null,
      shouldOpenMobileInbox: false,
      userAuditLogVisible: false,
      showNewVersionDialog: false,
      draftsDialogVisible: false,
      adminUtilsOpen: false,
      salesideJobDialogVisible: false
    }
  },
  created() {
    document.title =
      this.title && this.title.length > 0 ? this.title : 'GlassBiller'
    this.$root.$on('showMobileJob', this.showMobileJob)
  },
  beforeDestroy() {
    this.$root.$off('showMobileJob', this.showMobileJob)
  },
  mounted() {
    $('.ignore-click').click(function(event) {
      return !$(event.target).hasClass('ignore-click')
    })
    $('.toggle-collapse').click(function() {
      $('#navBurger').click()
      return false
    })

    if (!this.userAccountIsActive) {
      this.openBillingDetails()
    } else {
      setTimeout(() => {
        if (this.$store.state.user) {
          this.$store.dispatch('updateAccountStatus')
        }
      }, 5000)
    }

    if (
      this.$route.query.request &&
      this.$route.query.request === 'billingDetailsUpdated' &&
      this.$route.query.hostedpage_id &&
      this.$route.query.hostedpage_id.length > 0
    ) {
      var hostedPageId = this.$route.query.hostedpage_id
      var previousStatus = this.$route.query.currentZohoStatus
      window.history.pushState('', 'GlassBiller', '/')
      this.openBillingDetails()
      Account.checkBillingDetailsUpdateResult(
        this.$store.state.user.account_id,
        hostedPageId,
        previousStatus,
        function(data) {
          if (data.result && data.result === 'updated') {
            alert('Success! Your payment information has been updated.')
          }
        },
        function(error) {
          console.log('error: ', error)
        }
      )
    }
  },
  watch: {
    userAccountIsActive(val) {
      if (!val) {
        this.openBillingDetails()
      }
    },
    title() {
      document.title =
        this.title && this.title.length > 0 ? this.title : 'GlassBiller'
    },
    quickSearchVal(val) {
      if (val.length > 3) {
        // let's make a call to the backend
        const self = this
        Job.quickSearch(val, function(response) {
          self.quickSearchDataSet = response
        })
      }
    }
  },
  computed: {
    faSearch() {
      return faSearch
    },
    faInfoCircle() {
      return faInfoCircle
    },
    userAccountIsActive: get('userAccountIsActive'),
    isNagsUser: get('isNagsUser'),
    showConfirmationDialog: get('twilio/textMessages/jobConfirmation/visible'),
    ...mapState(['user']),
    totalCartItemsCount() {
      return this.$store.state.shoppingCart.items.totalItemsCount
    },
    cartItemsCount() {
      return this.totalCartItemsCount > 99 ? '99+' : this.totalCartItemsCount
    }
  },
  methods: {
    switchToOldSite: function() {
      const self = this
      // var tempHref = window.location.pathname.toString().replace('frontend/', '')
      self.setDefaultFrontend(
        false,
        self.changeRouteWithCheck('https://new.glassbiller.com')
      )
      // window.location = window.location.href.toString().replace('frontend/', '')
    },
    callDashboardDialog: function() {
      this.$root.$emit('bv::show::modal', 'dashboardDialog')
    },
    openUserAuditDialog: function() {
      this.userAuditLogVisible = true
    },
    openBillingDetails: function() {
      this.$root.$emit('bv::show::modal', 'userBillingDetailsDialog')
    },
    callQuickBooksDialog: function() {
      this.$root.$emit('bv::show::modal', 'quickBooksExportModal')
    },
    selectedJob: function(option, type) {
      if (this.isMobile) {
        $('#navBurger').click()
      }
      if (type === 'job') {
        this.changeRouteWithCheck('/jobs/' + option)
      } else if (type === 'salesidejob') {
        this.showMobileJob(option, false)
      }
      // else if (type === 'consumer') {
      //   this.selectedConsumer = option
      //   this.showConsumerDialog = true
      // }
    },
    roleCheck: function(val) {
      if (this.user) {
        if (this.user[val] === 1 || this.user.admin === 1) {
          return true
        }
      }
      return false
    },
    logoutUser: async function() {
      await this.$auth.logout()
    },
    changeWithPermissionCheck: function(permission, route) {
      if (permissionCheck(permission, this.$store.state)) {
        this.changeRouteWithCheck(route)
      } else {
        this.$root.$emit(
          'showGlobalNotification',
          'Warning',
          'You do not have permission to ' +
            permissionHumanReadable(permission) +
            '.',
          'warning',
          5
        )
      }
    },
    localPermissionCheck: function(permission) {
      return permissionCheck(permission, this.$store.state)
    },
    updateNewSearchFilter(filter) {
      this.newSearchFilter = filter
    },
    toggleNavSearch() {
      this.navSearching = !this.navSearching
    },
    showMobileJob(mobileJobNumber, shouldOpenMobileInbox) {
      this.mobileJobNumber = mobileJobNumber
      this.shouldOpenMobileInbox = shouldOpenMobileInbox
      this.salesideJobDialogVisible = true
    },
    openShoppingCart() {
      return this.$store.dispatch(shoppingCartActions.open)
    },
    openUserDialog() {
      this.$root.$emit('bv::show::modal', 'userSettingDialog')
    },
    openDraftsDialog() {
      this.draftsDialogVisible = true
    },
    joinMeeting() {
      window.open('https://meet.google.com/rdi-eqmb-xjs', '_blank')
    },
    openTutorials() {
      this.changeRouteWithCheck('/tutorials')
    },
    openAdminUtils() {
      this.adminUtilsOpen = true
    }
  },
  components: {
    // FontAwesomeIcon,
    SideBar,
    GlobalSearch,
    JobNav,
    GlaxisDialog,
    GlaxisClaimDialog,
    GlaxisReviewCoverageDialog,
    DashboardDialog,
    QuickBooksDialog,
    TextMessagesDialog,
    TextMessageTemplatesDialog,
    TextMessageEmailTemplatesDialog,
    TextMessageGbFormTemplatesDialog,
    TextMessageConfirmationDialog,
    TextMessageInvoiceDialog,
    TextMessagePaymentDialog,
    TextMessageSignatureDialog,
    adDialog,
    TextMessageTssInviteDialog,
    Loader,
    SalesideJobDialog,
    ShoppingCartDialog,
    NotificationsDialog,
    NotificationsSettingsDialog,
    BillingDetailsDialog,
    UserDialog,
    UserAuditDialog,
    Chat,
    NewVersionDialog,
    DraftsDialog,
    AdminUtilsDialog,
    SupportCenter,
    PurchaseOrderDialog,
    ReceiveDialog,
    TechPickUpDialog,
    ReturnDialog,
    GenericEmailDialog,
    GenericSmsDialog,
    VendorProfileDialog,
    QuickBooksAccountMappingDialog,
    QuickBooksSyncDialog
  },
  mixins: [UtilityMixin]
}
</script>

<style scoped lang="scss">
#newTabLink:hover {
  cursor: pointer;
}

#globalSearch {
  width: 275px;
}

#mobileQuickNav {
  width: 100%;
}

#newSearchFilterButton a {
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}

.isMobileNavBar {
  min-height: 55px;
  max-height: 100vh !important;
  overflow-y: auto !important;
}

#cart-items-count {
  position: absolute;
  color: #fff;
  background-color: #28a745;
  border-radius: 999px;
  font-size: 10px;
  width: 18px;
  height: 18px;
  top: -6px;
  right: -10px;
  // left: -5px;
  // bottom: -12px;
  // right: 35px !important;
}

.topMenuNavBar {
  min-height: 55px;
  flex-shrink: 0;
}

.top-menu-options {
  min-height: 39px;
}

.newVersionComing {
  color: black;
  right: -150px;
  position: relative;
  top: -14px;
  border-radius: 15px;
  background-color: white;
  text-align: center;
  font-size: 12px;
  width: 100px;
}
.newVersionComing-sm {
  color: black;
  right: -150px;
  position: relative;
  top: -14px;
  border-radius: 15px;
  background-color: white;
  text-align: center;
  font-size: 12px;
  width: 100px;
}
</style>
