var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comments" },
    [
      _vm._l(_vm.data, function(event, index) {
        return _c(
          "div",
          { key: index, staticClass: "mb-3" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 16, type: "flex", align: "middle" } },
              [
                _c(
                  "el-col",
                  {
                    attrs: {
                      span: !event.gb_user_id ? 2 : 1,
                      justify: "center"
                    }
                  },
                  [
                    !event.gb_user_id
                      ? _c("el-avatar", {
                          attrs: { src: _vm.logoImg, size: 40 }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 20 } },
                  [
                    _c(
                      "el-card",
                      { staticClass: "mb-1" },
                      [
                        event.payload.contentType.includes("html")
                          ? _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.content(
                                    event.gb_user_id,
                                    event.payload.content
                                  )
                                )
                              }
                            })
                          : _c("div", [_vm._v(_vm._s(event.payload.content))]),
                        _vm._l(event.support_ticket_attachments, function(
                          attachment,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index, staticClass: "job-documents" },
                            [
                              _c(
                                "div",
                                { staticClass: "document-list-item" },
                                [
                                  _c("document-tag", {
                                    attrs: {
                                      doc: Object.assign({}, _vm.doc, {
                                        tag: attachment.name
                                      })
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.openDocPreview(
                                          Object.assign({}, attachment, {
                                            document_name: attachment.name,
                                            document_type: "support_event"
                                          })
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        })
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        class: {
                          subtitle: true,
                          is_left: event.gb_user_id,
                          is_right: !event.gb_user_id
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.title(event)) +
                            " " +
                            _vm._s(_vm.date(event.payload.commentedTime)) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    staticClass: "user-icon",
                    attrs: { span: event.gb_user_id ? 2 : 1 }
                  },
                  [
                    event.gb_user_id &&
                    event.gb_user_id == _vm.$store.state.user.id &&
                    _vm.avatarUrl
                      ? _c("el-avatar", {
                          staticClass: "float-right",
                          attrs: { src: _vm.avatarUrl }
                        })
                      : _vm._e(),
                    event.gb_user_id &&
                    (event.gb_user_id != _vm.$store.state.user.id ||
                      !_vm.avatarUrl)
                      ? _c("i", {
                          staticClass:
                            "material-icons md-account_circle t-text-white t-px-3 t-cursor-pointer t-text-3xl"
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _c("file-preview-dialog", {
        attrs: {
          doc: _vm.preview,
          noDelete: true,
          gcp: true,
          dateFunc: _vm.dateFunc
        },
        model: {
          value: _vm.previewDialog,
          callback: function($$v) {
            _vm.previewDialog = $$v
          },
          expression: "previewDialog"
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }