<template>
  <div v-if="isJobsPage">
    <JobtagDialog
      :tags="editTagTags"
      :jobtags="editTagJobTags"
      :editTag.sync="editTag"
      :job_id="editTagJobId"
      :shop_id="editTagShopId"
    />
    <TagDialog :editTag="editTag" :shopId="editTagShopId" />
    <LoadingDialog
      id="'jobslist-action'"
      :isOpen="showLoadingDialog"
      :message="loadingDialogMessage"
    />
    <EdiStatusDialog
      :modalId="'jobslistEdiStatusModal'"
      :ediStatusChangeModal="'jobslistEdiStatusChangeModal'"
      :ediqueue="selectedEdiqueue"
      :job_id="selectedEdiJobId"
      :job_shop_id="selectedEdiShopId"
    />
    <EdiStatusChangeDialog
      :modalId="'jobslistEdiStatusChangeModal'"
      :model_to_return_to="'jobslistEdiStatusModal'"
      :job_id="selectedEdiJobId"
    />
    <DocumentDialog
      :documents="selectedDocuments"
      :filePickerKey="filePickerKey"
      :shop_id="selectedDocumentShopId"
      :job_id="selectedDocumentJobId"
    />
    <NotesDialog
      :notes="selectedNotesNotes"
      :users="selectedNotesUsers"
      :job_id="selectedNotesJobId"
      :shop_id="selectedNotesShopId"
    />
  </div>
</template>

<script>
import JobtagDialog from "@/components/modals/JobtagDialog.vue";
import TagDialog from "@/components/modals/TagDialog.vue";
import Tag from "@/scripts/objects/tag";
import Jobtag from "@/scripts/objects/jobtag";
import Document from "@/scripts/objects/document";
import Ediqueue from "@/scripts/objects/ediqueue";
import EdiStatusDialog from "@/components/modals/EdiStatusDialog.vue";
import EdiStatusChangeDialog from "@/components/modals/EdiStatusChangeDialog.vue";
import DocumentDialog from "@/components/modals/DocumentDialog.vue";
import NotesDialog from "@/components/modals/NotesDialog.vue";
import Note from "@/scripts/objects/note";
import {
  permissions,
  permissionCheck,
  permissionHumanReadable,
} from "@/scripts/helpers/permissions.helpers";
import LoadingDialog from "@/components/modals/LoadingDialog.vue";

export default {
  props: ["filePickerKey"],
  data() {
    return {
      ediStatuses: [
        { name: "Unknown", id: "unknown" },
        { name: "Accepted", id: "accepted" },
        { name: "Paid", id: "paid" },
        { name: "Sent", id: "sent" },
        { name: "Queued", id: "queued" },
        { name: "Rejected", id: "rejected" },
        { name: "Pending", id: "pending" },
        { name: "Unlocked", id: "unlocked" },
        { name: "Unsubmitted", id: "Unsubmitted" },
      ],

      selectedHasTags: [],
      selectedDoesNotHaveTags: [],
      tags: [],
      filteredTags: [],

      selectedHasTaxes: [],
      selectedDoesNotHaveTaxes: [],
      taxes: [],
      filteredTaxes: [],

      editTag: new Tag(),
      editTagJobId: -1,
      editTagShopId: -1,
      editTagTags: [],
      editTagJobTags: [],
      showLoadingDialog: false,
      loadingDialogMessage: "Loading...",
      loadingAreaLeftOffset: "15px",

      selectedEdiJobId: -1,
      selectedEdiShopId: -1,
      selectedEdiqueue: new Ediqueue(),

      selectedDocumentJobId: -1,
      selectedDocumentShopId: -1,
      selectedDocuments: [],

      selectedNotesJobId: -1,
      selectedNotesShopId: -1,
      selectedNotesUsers: [],
      selectedNotesNotes: [],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        if (to?.path === "/jobs") {
          window.addEventListener(
            "editJobDocumentsFromVue3",
            this.editDocumentsButtonPressed
          );
          window.addEventListener(
            "editJobNotesFromVue3",
            this.editNotesButtonPressed
          );
          window.addEventListener(
            "editJobEdiFromVue3",
            this.ediStatusButtonPressed
          );
          window.addEventListener(
            "editJobTagsFromVue3",
            this.editTagsButtonPressed
          );
          this.$root.$on("addUpdateTag", this.addUpdateTagListener);
          this.$root.$on("updateJobtags", this.updateJobtagsListener);
          this.$root.$on("updateEdiqueue", this.updateEdiqueueListener);
          this.$root.$on("addNewDocument", this.addNewDocumentListener);
          this.$root.$on("deleteDocument", this.deleteDocumentListener);
          this.$root.$on("deleteNote", this.deleteNoteListener);
          this.$root.$on("addNewNote", this.addNewNoteListener);
        } else if (from?.path === "/jobs") {
          window.removeEventListener(
            "editJobDocumentsFromVue3",
            this.editDocumentsButtonPressed
          );
          window.removeEventListener(
            "editJobNotesFromVue3",
            this.editNotesButtonPressed
          );
          window.removeEventListener(
            "editJobEdiFromVue3",
            this.ediStatusButtonPressed
          );
          window.removeEventListener(
            "editJobTagsFromVue3",
            this.editTagsButtonPressed
          );
          this.$root.$off("addUpdateTag", this.addUpdateTagListener);
          this.$root.$off("updateJobtags", this.updateJobtagsListener);
          this.$root.$off("updateEdiqueue", this.updateEdiqueueListener);
          this.$root.$off("deleteDocument", this.deleteDocumentListener);
          this.$root.$off("addNewDocument", this.addNewDocumentListener);
          this.$root.$off("deleteNote", this.deleteNoteListener);
          this.$root.$off("addNewNote", this.addNewNoteListener);
        }
      },
    },
    "this.$store.state.shops": function () {
      if (this.$store && this.$store.state && this.$store.state.shops) {
        this.shopIdOptions = [];
        for (var i = 0; i < this.$store.state.shops.length; i++) {
          this.shopIdOptions.push({
            name: this.$store.state.shops[i].id,
            id: this.$store.state.shops[i].id,
          });
        }
        this.sortArrayOfObjects(this.shopIdOptions, "id");
      }
    },
  },
  computed: {
    hasPermission: function () {
      return permissionCheck(permissions.JOB, this.$store.state);
    },
    isJobsPage() {
      return this.$route?.path === "/jobs";
    },
  },
  methods: {
    addNewNoteListener(note) {
      this.refreshTableRow(this.selectedNotesJobId);
    },
    deleteNoteListener(noteIndex) {
      this.refreshTableRow(this.selectedNotesJobId);
      if (noteIndex >= 0 && noteIndex < this.selectedNotesNotes.length) {
        this.selectedNotesNotes.splice(noteIndex, 1);
      }
    },
    addNewDocumentListener(document) {
      this.refreshTableRow(this.selectedDocumentJobId);
      if (document) {
        this.selectedDocuments.push(document);
      }
    },
    deleteDocumentListener(documentIndex) {
      this.refreshTableRow(this.selectedDocumentJobId);
      if (documentIndex >= 0 && documentIndex < this.selectedDocuments.length) {
        this.selectedDocuments.splice(documentIndex, 1);
      }
    },
    updateEdiqueueListener(job_id, status, message) {
      this.refreshTableRow(job_id);
    },
    updateJobtagsListener(newJobtags) {
      this.refreshTableRow(this.editTagJobId);
    },
    addUpdateTagListener(tag) {
      // replace matching tags currently loaded in job rows with new updated tag

      let self = this;
      Tag.getAll(self.editTagShopId, function (tags) {
        self.editTagTags = tags;
        Jobtag.getAll(
          self.editTagShopId,
          self.editTagJobId,
          function (jobTags) {
            self.editTagJobTags = jobTags;
            for (var j = 0; j < self.items.length; j++) {
              if (self.items[j]["tags"]) {
                var tags = self.items[j]["tags"].split("#~#");
                for (var i = 0; i < tags.length; i++) {
                  if (tags[i] && tags[i].length > 0) {
                    try {
                      var id = tags[i].split("*~t*")[0];
                      if (id + "" === tag.id + "") {
                        var replaceTagString = tags[i];
                        var newTagString =
                          tag.id + "*~t*" + tag.text + "*~c*" + tag.color;
                        var row = self.items[j];
                        row["tags"] = row["tags"].replace(
                          replaceTagString,
                          newTagString
                        );
                        self.$set(self.items, j, row);
                      }
                    } catch (err) {}
                  }
                }
              }
            }
          }
        );
      });
    },
    refreshTableRow(jobId) {
      const newEvent = new CustomEvent("updateJobToVue3", {
        detail: { jobId },
      });
      window.dispatchEvent(newEvent);
    },
    editNotesButtonPressed(event) {
      if (
        permissionCheck(permissions.JOB, this.$store.state) &&
        !permissionCheck(permissions.JOBVIEWONLY, this.$store.state)
      ) {
        let self = this;
        self.selectedNotesJobId = event.detail.jobId;
        self.selectedNotesShopId = event.detail.shopId;
        self.showLoadingDialog = true;

        Note.getAll(
          event.detail.shopId,
          event.detail.jobId,
          function (results) {
            self.selectedNotesUsers = results["users"];
            self.selectedNotesNotes = results["notes"];
            self.showLoadingDialog = false;
            self.$root.$emit("bv::show::modal", "notesModal");
          },
          function (error) {
            self.showLoadingDialog = false;
          }
        );
      } else {
        this.$root.$emit(
          "showGlobalNotification",
          "Warning",
          "You do not have permission to " +
            (!permissionCheck(permissions.JOB, this.$store.state)
              ? permissionHumanReadable(permissions.JOB)
              : permissionHumanReadable(permissions.JOBVIEWONLY)) +
            ".",
          "warning",
          5
        );
      }
    },
    editDocumentsButtonPressed(event) {
      if (
        permissionCheck(permissions.JOB, this.$store.state) &&
        !permissionCheck(permissions.JOBVIEWONLY, this.$store.state)
      ) {
        let self = this;
        self.selectedDocumentShopId = event.detail.shopId;
        self.selectedDocumentJobId = event.detail.jobId;
        self.showLoadingDialog = true;

        Document.getAll(
          self.selectedDocumentShopId,
          self.selectedDocumentJobId,
          function (documents) {
            self.selectedDocuments = documents;
            self.showLoadingDialog = false;
            self.$root.$emit("bv::show::modal", "documentModal");
          },
          function (error) {
            self.showLoadingDialog = false;
          }
        );
      } else {
        this.$root.$emit(
          "showGlobalNotification",
          "Warning",
          "You do not have permission to " +
            (!permissionCheck(permissions.JOB, this.$store.state)
              ? permissionHumanReadable(permissions.JOB)
              : permissionHumanReadable(permissions.JOBVIEWONLY)) +
            ".",
          "warning",
          5
        );
      }
    },
    ediStatusButtonPressed(event) {
      if (
        permissionCheck(permissions.JOB, this.$store.state) &&
        !permissionCheck(permissions.JOBVIEWONLY, this.$store.state)
      ) {
        let self = this;
        self.selectedEdiJobId = event.detail.jobId;
        self.selectedEdiShopId = event.detail.shopId;

        self.$root.$emit("bv::show::modal", "jobslistEdiStatusModal");
      } else {
        this.$root.$emit(
          "showGlobalNotification",
          "Warning",
          "You do not have permission to " +
            (!permissionCheck(permissions.JOB, this.$store.state)
              ? permissionHumanReadable(permissions.JOB)
              : permissionHumanReadable(permissions.JOBVIEWONLY)) +
            ".",
          "warning",
          5
        );
      }
    },
    editTagsButtonPressed(event) {
      if (
        permissionCheck(permissions.JOB, this.$store.state) &&
        !permissionCheck(permissions.JOBVIEWONLY, this.$store.state)
      ) {
        this.editTagJobId = event.detail.jobId;
        this.editTagShopId = event.detail.shopId;
        this.showLoadingDialog = true;
        let self = this;
        Tag.getAll(
          self.editTagShopId,
          function (tags) {
            self.editTagTags = tags;
            Jobtag.getAll(
              self.editTagShopId,
              self.editTagJobId,
              function (jobTags) {
                self.editTagJobTags = jobTags;
                self.showLoadingDialog = false;

                self.$root.$emit("bv::show::modal", "jobtagModal");
              },
              function (error) {
                alert("Something went wrong loading tags");
                self.showLoadingDialog = false;
              }
            );
          },
          function (error) {
            alert("Something went wrong loading tags");
            self.showLoadingDialog = false;
          }
        );
      } else {
        this.$root.$emit(
          "showGlobalNotification",
          "Warning",
          "You do not have permission to " +
            (!permissionCheck(permissions.JOB, this.$store.state)
              ? permissionHumanReadable(permissions.JOB)
              : permissionHumanReadable(permissions.JOBVIEWONLY)) +
            ".",
          "warning",
          5
        );
      }
    },
  },
  components: {
    JobtagDialog,
    TagDialog,
    LoadingDialog,
    EdiStatusDialog,
    EdiStatusChangeDialog,
    DocumentDialog,
    NotesDialog,
  },
};
</script>
