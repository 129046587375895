<template>
  <div class="t-flex t-justify-between t-my-4">
    <div>
      <span>{{vendor.name}}</span>
      <span class="vendor-type-tag t-ml-2" :style="{'background-color': vendorColors[vendor.type]}">
        {{vendorLabels[vendor.type]}}
      </span>
    </div>
    <div>
      <el-select
        class="t-ml-2"
        v-model="selected"
        placeholder="Unmapped"
        :loading="loading"
        popper-class="qbo-mapping-popper"
      >
        <div v-for="item in options">
          <el-option
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </div>
      </el-select>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useQboCommissionStore } from '@/components/modals/QuickBooksCommissionValidationDialog/qboCommission.store.js'

export default {
  props: {
    vendor: Object,
    options: Array,
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false,

      vendorColors: {
        csr: 'rgb(0, 137, 123)',
        salesource: 'rgb(240, 173, 78)',
        salesrep: 'rgb(41, 159, 215)',
        tech: 'rgb(84, 169, 85)',
        location: 'rgb(188, 188, 188)'
      },
      vendorLabels: {
        csr: 'CSR',
        salesource: 'SALE SOURCE',
        salesrep: 'SALES REP',
        tech: 'TECH',
        location: 'LOCATION'
      }
    }
  },

  computed: {
    ...mapState(useQboCommissionStore, ['shopId', 'defaults']),

    selected: {
      get() {
        return this.vendor.default
      },
      set(qboVendorId) {
        this.updateShopVendorQboDefault(this.vendor.id, qboVendorId, this.shopId)
      }
    }
  },

  methods: {
    ...mapActions(useQboCommissionStore, [
      'updateShopQboMapping',
      'getShopAccountsFromQbo',
      'updateShopVendorQboDefault'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .vendor-type-tag {
    color: white;
    margin-right: 0.75rem;
    border-radius: 0.25rem;
    padding: 0.25rem;
    font-size: 0.75rem;
  }
</style>

<style lang="scss">
  .qbo-mapping-popper {
    @media screen and (max-width: 503px) {
      max-width: 100vw !important;
      min-width: 100vw !important;
    }
  }
</style>
