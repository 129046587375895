<template>
  <div class="scheduler-map-tech-filters" :class="{ expanded: expanded }">
    <div
      class="mobile-expand"
      @click="
        () => {
          expanded = !expanded
        }
      "
    >
      <v-icon name="angle-up" :class="{ 'fa-rotate-180': expanded }" />
    </div>
    <div v-if="routeStates.length > 1">
      <h4>States:</h4>
      <div class="tech-state-filters">
        <el-button
          v-for="state in routeStates"
          :key="state"
          round
          :type="state === bounds ? 'primary' : ''"
          @click="setBounds(state)"
          :size="isMobile ? 'small' : 'default'"
          >{{ state }}</el-button
        >
      </div>
    </div>
    <el-collapse accordion>
      <el-collapse-item name="1">
        <template slot="title">
          <div class="t-flex t-justify-between">
            <h4>Map Layers</h4>
            <el-tag
              size="small"
              class="t-ml-1"
              :type="selectedLayersCount === layerList.length ? '' : 'warning'">
              {{ `${selectedLayersCount}/${layerList.length}` }}
            </el-tag>
          </div>
        </template>
        <div>
          <el-checkbox
            class="tech-checkbox"
            v-for="l in layerList"
            :key="l"
            :checked="layerFilter[l]"
            @change="handleLayerChange($event, l)"
          >
            {{ l.toUpperCase() }}
          </el-checkbox>
        </div>
      </el-collapse-item>
      <el-collapse-item name="2">
        <template slot="title">
          <div class="t-flex t-justify-between">
            <h4>Shops</h4>
            <el-tag
              size="small"
              class="t-ml-1"
              :type="selectedShopsCount === shops.length ? '' : 'warning'">
              {{ `${selectedShopsCount}/${shops.length}` }}
            </el-tag>
          </div>
        </template>
        <div>
          <el-checkbox
            class="tech-checkbox"
            v-for="shop in shops"
            :key="shop.id"
            :checked="shop.checked"
            @change="handleShopChange($event, shop)"
          >
            {{ shop.name.toUpperCase() }}
          </el-checkbox>
        </div>
      </el-collapse-item>
      <el-collapse-item name="3">
        <template slot="title">
          <div class="t-flex t-justify-between">
            <h4>Tags</h4>
            <el-tag
              size="small"
              class="t-ml-1"
              :type="selectedTagsCount === tags.length ? '' : 'warning'">
              {{ `${selectedTagsCount}/${tags.length}` }}
            </el-tag>
          </div>
        </template>
        <div>
          <el-checkbox-group v-model="selectedTags">
            <el-checkbox
              class="tech-checkbox"
              v-for="tag in tags"
              :label="tag.id"
              :key="tag.id"
              :style="{ color: tag.color }"
            >
              {{ tag.text.toUpperCase() }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </el-collapse-item>
      <el-collapse-item name="4">
        <template slot="title">
          <div class="t-flex t-justify-between">
            <h4>Technician routes</h4>
            <el-tag
              size="small"
              class="t-ml-1"
              :type="selectedRoutesCount === routes.length ? '' : 'warning'">
              {{ `${selectedRoutesCount}/${routes.length}` }}
            </el-tag>
          </div>
        </template>
        <div>
          <el-checkbox
            class="tech-checkbox"
            v-for="route in routes"
            :key="route.id"
            @change="handleSelectionChange($event, route)"
            :value="!route.hidden && !route.error"
            :disabled="!!route.error"
            :style="{ color: route.color }"
          >
            <div class="tech-checkbox-label">
              {{ getName(route) }}
              <tech-location-indicator
                @click="onIndicatorClick"
                :username="route.techside_username"
                :color="route.color"
              />
            </div>
          </el-checkbox>
        </div>
      </el-collapse-item>
      <el-collapse-item name="5">
        <template slot="title">
          <div class="t-flex t-justify-between">
            <h4>Time Frames</h4>
            <el-tag
              size="small"
              class="t-ml-1"
              :type="selectedTimeFramesCount === timeFrameList.length ? '' : 'warning'">
              {{ `${selectedTimeFramesCount}/${timeFrameList.length}` }}
            </el-tag>
          </div>
        </template>
        <div>
          <el-checkbox
            class="tech-checkbox"
            v-for="tf in timeFrameList"
            :key="tf"
            :checked="timeFrameFilter[tf]"
            @change="handleTimeFrameChange($event, tf)"
          >
            {{ tf.toUpperCase() }}
          </el-checkbox>
        </div>
      </el-collapse-item>
      <el-collapse-item name="6">
        <template slot="title">
          <div class="t-flex t-justify-between">
            <h4>Warehouses</h4>
            <el-tag
              size="small"
              class="t-ml-1"
              :type="selectedWarehousesCount === warehouseList.length ? '' : 'warning'">
              {{ `${selectedWarehousesCount}/${warehouseList.length}` }}
            </el-tag>
          </div>
        </template>
        <div>
          <el-checkbox
            class="tech-checkbox"
            v-for="wh in warehouseList"
            :key="wh"
            :checked="warehouseFilter[wh]"
            :style="{ color: warehouseColors[wh] }"
            @change="handleWarehouseChange($event, wh)"
          >
            {{ wh.toUpperCase() }}
          </el-checkbox>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { shedulerColors, warehouseColors } from '@/constants/sheduler.configs'
import { isMobile } from '@/scripts/helpers/helpers'
import { get, sync } from 'vuex-pathify'
import * as R from 'ramda'
import { getRouteTechColor } from '@/scripts/helpers/scheduler_v3.helpers'
import TechLocationIndicator from '../helpers/TechLocationIndicator'

export default {
  components: { TechLocationIndicator },
  computed: {
    ...get('sch_v3', ['routeStates']),
    bounds: sync('sch_v3/bounds'),
    warehouseFilter: sync('sch_v3/filters@warehouses'),
    layerFilter: sync('sch_v3/filters@layers'),
    timeFrameFilter: sync('sch_v3/filters@timeFrames'),
    data: sync('sch_v3/data'),
    warehouseList() {
      return R.keys(this.warehouseFilter)
    },
    selectedWarehousesCount() {
      return Object.values(this.warehouseFilter).filter(value => value === true)?.length || 0
    },
    warehouseColors() {
      return warehouseColors
    },
    layerList() {
      return R.keys(this.layerFilter)
    },
    selectedLayersCount() {
      return Object.values(this.layerFilter).filter(value => value === true)?.length || 0
    },
    timeFrameList() {
      return R.keys(this.timeFrameFilter)
    },
    selectedTimeFramesCount() {
      return Object.values(this.timeFrameFilter).filter(value => value === true)?.length || 0
    },
    routes() {
      return R.pipe(
        R.values,
        R.filter(R.pipe(R.prop('route_entries'), R.length))
      )(this.data)
    },
    selectedRoutesCount() {
      return this.routes.filter(route => !route.hidden)?.length || 0
    },
    shops() {
      const arr = []
      for (const route of this.routes) {
        for (const entry of route.route_entries) {
          if (!arr.some(shop => shop.id === entry.job.shop_id)) {
            arr.push({
              id: entry.job.shop_id,
              name: entry.job.shop_name,
              checked: !!!entry.shopHidden
            })
          }
        }
      }
      return arr
    },
    selectedShopsCount() {
      return this.shops.filter(shop => shop.checked)?.length || 0
    },
    tags() {
      const arr = []
      for (const route of this.routes) {
        for (const entry of route.route_entries) {
          for (const jobtag of entry.job.jobtags) {
            if (!arr.some(tag => tag.id === jobtag.id)) {
              arr.push(jobtag)
            }
          }
        }
      }
      const oldTags = this.tagsList.map(o => o.id)
      let newTags = arr.filter(n => !oldTags.includes(n.id)).map(t => t.id)

      if (newTags.length) {
        newTags = newTags.filter(i => !this.selectedTags.includes(i))
        this.selectedTags = [...this.selectedTags, ...newTags]
      }

      this.tagsList = arr
      return arr
    },
    selectedTagsCount() {
      if (typeof this.selectedTags === 'boolean') {
        return 0
      }
      return this.tags.filter(tag => this.selectedTags.includes(tag.id))?.length || 0
    }
  },
  data() {
    return {
      defaultColor: shedulerColors.default,
      isMobile: isMobile(),
      expanded: false,
      selectedTags: [],
      tagsList: []
    }
  },
  watch: {
    selectedTags(v) {
      if (typeof v === 'boolean') return

      let d = JSON.parse(JSON.stringify(this.data))

      for (const route in d) {
        let r = d[route]

        for (const entry of r.route_entries) {
          let hidden = false
          for (const jobtag of entry.job.jobtags) {
            if (!v.includes(jobtag.id)) {
              hidden = true
            }
          }
          entry.tagHidden = hidden
        }

        d[route] = r
      }

      this.data = d
    },
  },
  methods: {
    handleSelectionChange(value, { id }) {
      this.data = R.over(R.lensProp(id), R.assoc('hidden', !value))(this.data)
    },
    getRouteTechColor(route) {
      return getRouteTechColor(route)
    },
    handleWarehouseChange(value, wh) {
      this.warehouseFilter = R.set(R.lensProp(wh), value)(this.warehouseFilter)
    },
    handleLayerChange(value, l) {
      this.layerFilter = R.set(R.lensProp(l), value)(this.layerFilter)
    },
    handleShopChange(value, shop) {
      let d = JSON.parse(JSON.stringify(this.data))

      for (const route in d) {
        let r = d[route]

        for (const entry of r.route_entries) {
          if (entry.job.shop_id === shop.id) {
            entry.shopHidden = !value
          }
        }

        d[route] = r
      }

      this.data = d
    },
    handleTimeFrameChange(value, tf) {
      this.timeFrameFilter = R.set(R.lensProp(tf), value)(this.timeFrameFilter)
      let d = JSON.parse(JSON.stringify(this.data))

      for (const route in d) {
        let r = d[route]

        for (const entry of r.route_entries) {
          const { scheduled_time_start: st, scheduled_time_end: et } = entry.job

          if (!st || !et) {
            if (tf === 'time not selected') {
              entry.timeHidden = !value
            }
            continue
          }

          let jobTimeFrame = 'afternoon'
          const avg = ((+st.split(':')[0]) + (+et.split(':')[0])) / 2

          if (avg <= 10.5) {
            jobTimeFrame = 'morning'
          } else if (avg <= 13.5) {
            jobTimeFrame = 'mid day'
          }

          if (tf === jobTimeFrame) {
            entry.timeHidden = !value
          }
        }

        d[route] = r
      }

      this.data = d
    },
    setBounds(state) {
      this.bounds = state
    },
    getName(route) {
      return R.cond([
        [R.path(['tech', 'name']), R.path(['tech', 'name'])],
        [R.prop('techs'), R.prop('techside_username')],
        [R.complement(R.prop('tech_id')), () => 'UNASSIGNED']
      ])(route)
    },
    onIndicatorClick({ latitude, longitude }) {
      this.$emit('center-map', { lat: latitude, lng: longitude })
    }
  }
}
</script>

<style lang="scss">
@import '../styles/SchedulerMapTechFilters';
</style>
