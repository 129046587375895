<template>
  <div class="select-parent">
    <span class="select-label">{{label}}</span>

    <el-select
      v-model="selected"
      placeholder="Unmapped"
      :loading="loading"
      popper-class="qbo-mapping-popper"
    >
      <el-option
        class="qbo-mapping-option"
        v-for="item in options"
        :key="item.Id"
        :label="item.Name"
        :value="item.Id">
          <quick-books-commission-account-option v-bind="item" />
      </el-option>
    </el-select>
  </div>
</template>

<script>
import QuickBooksCommissionAccountOption from './QuickBooksCommissionAccountOption.vue'
import { mapActions, mapState } from 'pinia'
import { useQboCommissionStore, gbExportItems } from '@/components/modals/QuickBooksCommissionValidationDialog/qboCommission.store.js'

export default {
  components: {
    QuickBooksCommissionAccountOption
  },

  props: {
    label: String,
    type: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      options: [],
      loading: false
    }
  },

  computed: {
    ...mapState(useQboCommissionStore, ['shopId', 'defaults']),

    selected: {
      get() {
        return this.type.default
      },
      set(optionId) {
        let temp = this.options.filter((o) => o.Id === optionId)[0]
        this.updateShopDefaultQboAccountHandler(temp)
      }
    }
  },

  async created() {
    this.initialize()
  },

  methods: {
    ...mapActions(useQboCommissionStore, [
      'updateShopQboMapping',
      'getShopAccountsFromQbo'
    ]),

    async initialize () {
      try {
        this.loading = true
        const accounts = await this.getShopAccountsFromQbo(this.shopId)
        this.options = accounts || []

      } catch (error) {
        this.$message.error(error.message)
      } finally {
        this.loading = false
      }
    },

    async updateShopDefaultQboAccountHandler (qboAccountOption) {
      const { Id, Name } = qboAccountOption

      try {
        this.updateShopQboMapping(this.type.id, Id, Name, this.shopId)
      } catch (e) {
        this.$message.error(e.message)
      }
    }
  },

  watch: {
    shopId: function (val, oldVal) {
      if (val && val !== oldVal) {
        this.initialize()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .select-label {
    display: inline-block;
    width: 150px;
  }
  .special-content-label {
    display: inline-block;
    width: 150px;
    color: #8492a6;
    font-style: italic;
  }
  .el-select-dropdown__item.qbo-mapping-option {
    height: 75px;
  }
  .select-parent {
    white-space: nowrap;
  }
</style>

<style lang="scss">
  .qbo-mapping-popper {
    @media screen and (max-width: 503px) {
      max-width: 100vw !important;
      min-width: 100vw !important;
    }
  }
</style>
