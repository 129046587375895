<template>
  <div :class="{ 'header-v2-menu-mobile': isMobile }">
    <div class="header-v2-menu-button" @click="menuOpen = !menuOpen" v-if="isMobile">
      <i class="material-icons md-menu" />
    </div>
    <el-menu
      :mode="isMobile ? 'vertical' : 'horizontal'"
      background-color="#272727"
      text-color="#fff"
      active-text-color="#ffd04b"
      class="header-v2-menu"
      :class="{ 'menu-open': menuOpen }"
      :default-active="defaultActive"
    >
      <template v-for="item in config">
        <template v-if="item.children">
          <el-submenu
            :index="item.key"
            :key="item.key"
            popper-class="header-v2-submenu"
            v-if='!item.can || $role.can(item.can)'
          >
            <template slot="title">
              <span v-if='item.link'>
                <a
                  :href="item.key"
                  @click.prevent.stop="checkForUnpublishedDraft(item);menuOpen=false;"
                  class="hover:t-no-underline t-text-white"
                >
                  {{ item.label }}
                </a>
              </span>
              <span v-else>{{ item.label }}</span>
            </template>
            <template v-for="child in item.children">
              <el-menu-item
                :index="child.key"
                :key="child.key"
                v-if="!child.can || $role.can(child.can)"
              >
                <a
                  :href="child.key"
                  @click.prevent.stop="checkForUnpublishedDraft(child);menuOpen=false;"
                  class="hover:t-no-underline t-text-white t-block"
                >
                  {{ child.label }}
                </a>
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item
            :index="item.key"
            :key="item.key"
            v-if="item.link"
          >
            <a :href="item.key" @click.prevent.stop="openExternalLink(item.key);menuOpen=false;">{{ item.label }}</a>
          </el-menu-item>
          <el-menu-item
            :index="item.key"
            :key="item.key"
            v-else-if="!item.can || $role.can(item.can)"
          >
            <a
              :href="item.key"
              @click.prevent.stop="checkForUnpublishedDraft(item);menuOpen=false;"
              class="hover:t-no-underline t-block">
              {{ item.label }}
            </a>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
    <!-- <span>
      <p style="color: white">Help</p>
    </span> -->
    <draft-warning-dialog
      :visible.sync='draftWarningVisible'
      @discardDraft="discardDraft"
      @leaveDraft="leaveDraft"
      @publishDraft="publishDraft"
    />
  </div>
</template>

<script>
import { headerConfig } from '@/constants/header.config'
import DraftWarningDialog from '../../JobPageV2/helpers/dialogs/DraftWarningDialog.vue'
import * as R from 'ramda'
import { sync } from 'vuex-pathify'
import RightPart from '@/scripts/objects/right_part'
import router from '@/router'

export default {
  components: { DraftWarningDialog },
  inject: ['isMobile'],
  data() {
    return {
      config: headerConfig,
      menuOpen: false,
      draftWarningVisible: false,
      draftWarningNavKey: null
    }
  },
  computed: {
    statusDialog: sync('jobDetails/actionDialogs@status'),
    defaultActive() {
      const { fullPath, name } = this.$route
      const findActive = R.find(
        R.ifElse(
          R.prop('activePage'),
          R.propEq('activePage', name),
          R.propEq('key', fullPath)
        )
      )
      return R.converge(R.or, [
        R.pipe(findActive, R.prop('key')),
        R.pipe(
          R.map(R.propOr([], 'children')),
          R.flatten,
          findActive,
          R.prop('key')
        )
      ])(headerConfig)
    }
  },
  methods: {
    openExternalLink(url) {
      window.open(url, '_blank');
    },
    async discardDraft() {
      try {
        await this.$messageBox.confirm(
          'This will permanently delete the draft. Continue?',
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        )
        this.draftWarningVisible = false
        RightPart.unlinkDraftJob(this.$route.params.draft_id)
        this.$unum.job().delete(`/draft/${this.$route.params.draft_id}`)
        window.location.href = this.draftWarningNavKey
      } catch {}
    },
    leaveDraft() {
      this.draftWarningVisible = false
      window.location.href = this.draftWarningNavKey
    },
    publishDraft() {
      this.draftWarningVisible = false
      this.statusDialog = true
    },
    async checkForUnpublishedDraft(item) {
      if (this.$job?.isDraft && this.$job?.shop?.id) {
        this.draftWarningNavKey = item.key
        this.draftWarningVisible = true
        return
      }
      if (item.useRouter) {
        router.push(item.key)
        return
      }
      router.push(item.key)
    }
  }
}
</script>
<style lang="scss">
.header-v2-menu {
  height: 56px;
  .el-menu-item,
  .el-submenu .el-submenu__title {
    font-size: 12px;
    height: 56px;
    line-height: 56px;
    padding: 0;
    letter-spacing: 0.5px;
    a {
      padding: 0 $padding-md;
      height: 100%;
    }
  }
  .el-submenu .el-submenu__title {
    padding: 0 $padding-md;
  }
  .header-v2-menu-button {
    height: 24px;
    margin-right: $padding-sm;
  }
}
.header-v2-submenu {
  .el-menu-item {
    font-size: 12px;
  }
}
.header-v2-menu-mobile {
  order: -1;
  height: 100%;
  display: flex;
  align-items: center;
  .header-v2-menu-button {
    height: 24px;
    margin-right: $padding-sm;
    display: block;
    i {
      color: #fff;
    }
  }
  .header-v2-menu {
    position: absolute;
    border-right: 0;
    top: 56px;
    width: 100%;
    height: calc(100vh - 56px);
    overflow-y: auto;
    opacity: 0;
    .el-menu-item,
    .el-submenu .el-submenu__title {
      font-size: 16px;
    }
    left: -100%;
    transition: opacity 0.2s ease-in-out;
    &.menu-open {
      left: 0;
      opacity: 1;
    }
  }
}
</style>
