// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".qbo-mapping-dialog-body .el-input {\n  width: 250px;\n}\n.qbo-account-select {\n  margin-bottom: 8px;\n}", "",{"version":3,"sources":["/app/<no source>","Body.vue","/app/src/components/modals/QuickBooksCommissionValidationDialog/Body.vue"],"names":[],"mappings":"AAAA;EAAA,YAAA;ACaA;ACkFA;EACE,kBAAA;AA/EF","file":"Body.vue","sourcesContent":[null,":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.qbo-mapping-dialog-body .el-input {\n  width: 250px;\n}\n\n.qbo-account-select {\n  margin-bottom: 8px;\n}",":export {\n  azureBlue: #2898ff;\n  skyBlue: #299fd7;\n  airForce-blue: #337ab7;\n  royalPurple: #805ad5;\n  crimsonRed: #dc3545;\n  goldOrange: #f0ad4e;\n  jadeGreen: #54a955;\n  teaGreen: #cadfc9;\n}\n\n.qbo-mapping-dialog-body .el-input {\n  @apply t-w-[250px];\n}\n\n.qbo-account-select {\n  margin-bottom: 8px;\n}"]}]);
// Exports
exports.locals = {
	"azureBlue": "#2898ff",
	"skyBlue": "#299fd7",
	"airForce-blue": "#337ab7",
	"royalPurple": "#805ad5",
	"crimsonRed": "#dc3545",
	"goldOrange": "#f0ad4e",
	"jadeGreen": "#54a955",
	"teaGreen": "#cadfc9"
};
module.exports = exports;
