var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-divider", { staticClass: "t-my-6" }),
      _c("div", { staticClass: "t-text-gray-400 t-mb-4" }, [
        _vm._v("Default QBO Vendor")
      ]),
      _c(
        "div",
        [
          _c(
            "el-select",
            {
              attrs: { filterable: "", "value-key": "id" },
              on: { change: _vm.changeShop },
              model: {
                value: _vm.selectedShop,
                callback: function($$v) {
                  _vm.selectedShop = $$v
                },
                expression: "selectedShop"
              }
            },
            _vm._l(_vm.shops, function(i) {
              return _c("el-option", {
                key: i.id,
                attrs: { label: i.name, value: i }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "t-mt-3" },
        [
          _c(
            "el-select",
            {
              attrs: { clearable: "", filterable: "" },
              on: { change: _vm.updateQboVendor, clear: _vm.deleteQboVendor },
              model: {
                value: _vm.selectedQboVendor,
                callback: function($$v) {
                  _vm.selectedQboVendor = $$v
                },
                expression: "selectedQboVendor"
              }
            },
            _vm._l(_vm.qboVendors, function(i) {
              return _c("el-option", {
                key: i.Id,
                attrs: { label: i.DisplayName, value: i.Id }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }