var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "t-flex t-justify-between t-my-4" }, [
    _c("div", [
      _c("span", [_vm._v(_vm._s(_vm.vendor.name))]),
      _c(
        "span",
        {
          staticClass: "vendor-type-tag t-ml-2",
          style: { "background-color": _vm.vendorColors[_vm.vendor.type] }
        },
        [_vm._v(" " + _vm._s(_vm.vendorLabels[_vm.vendor.type]) + " ")]
      )
    ]),
    _c(
      "div",
      [
        _c(
          "el-select",
          {
            staticClass: "t-ml-2",
            attrs: {
              placeholder: "Unmapped",
              loading: _vm.loading,
              "popper-class": "qbo-mapping-popper"
            },
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          },
          _vm._l(_vm.options, function(item) {
            return _c(
              "div",
              [
                _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value }
                })
              ],
              1
            )
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }