<template>
    <el-dialog
      append-to-body
      top="80px"
      width="685px"
      custom-class="qbo-commission-mapping-dialog"
      :visible="visible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div v-loading="fetching" class="t-flex-grow t-min-w-0">
        <Header @closeDialog="closeDialog" />
        <Body />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="continueExport" :disabled="!store.continue">Continue Export</el-button>
      </span>
    </el-dialog>
  </template>
  
  <script>
  import { mapState, mapActions } from 'pinia'
  import { useQboCommissionStore } from '@/components/modals/QuickBooksCommissionValidationDialog/qboCommission.store.js'
  import Header from './Header.vue'
  import Body from './Body.vue'
  
  export default {
    components: {
      Header,
      Body
    },
  
    data() {
      return {
        store: useQboCommissionStore()
      }
    },
  
    computed: {
      ...mapState(useQboCommissionStore, ['visible', 'fetching']),
    },
  
    methods: {
      ...mapActions(useQboCommissionStore, ['close']),
      continueExport () {
        this.$emit('continueExport')
        this.close()
      },
      closeDialog () {
        this.$emit('resetTable')
        this.close()
      }
    }
  }
  </script>
  
  <style lang="scss">
  .el-dialog.qbo-commission-mapping-dialog {
    @apply t-font-sans t-rounded-xl;
    .el-dialog__header {
      @apply t-hidden;
    }
  
    .el-dialog__body {
      @apply t-flex t-p-0 t-overflow-y-hidden
    }
  
    .el-form-item {
      @apply t-mb-0;
    }
  
    .el-form-item__content {
      @apply t-leading-none;
    }
  }
  </style>
