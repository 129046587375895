var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-v2-user" },
    [
      _c(
        "div",
        { staticClass: "header-v2-user-icons" },
        [
          _c("header-v-2-notifications", {
            staticClass: "header-v2-user-icons-item"
          }),
          _c(
            "el-badge",
            {
              staticClass: "header-v2-user-icons-item",
              attrs: { max: 99, value: _vm.cartCount, hidden: !_vm.cartCount }
            },
            [
              _c(
                "div",
                {
                  staticClass: "gb-icon-white-button",
                  on: { click: _vm.openShoppingCart }
                },
                [
                  _c("i", {
                    staticClass: "material-icons-outline md-shopping_cart"
                  })
                ]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "header-v2-user-avatar" },
        [
          _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom-end",
                trigger: "click",
                "popper-class": "header-v2-user-popover"
              },
              model: {
                value: _vm.visibleUserMenu,
                callback: function($$v) {
                  _vm.visibleUserMenu = $$v
                },
                expression: "visibleUserMenu"
              }
            },
            [
              _c(
                "div",
                { attrs: { slot: "reference" }, slot: "reference" },
                [
                  _vm.avatarUrl
                    ? _c("el-avatar", { attrs: { src: _vm.avatarUrl } })
                    : _c("i", {
                        staticClass:
                          "material-icons md-account_circle t-text-white t-cursor-pointer t-text-3xl"
                      })
                ],
                1
              ),
              _c("header-v-2-user-menu", {
                attrs: {
                  "allow-quick-books": _vm.allowQuickBooks,
                  "file-picker-key": _vm.filePickerKey
                },
                on: { "menu-select": _vm.onSelect }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("header-v-1", {
        ref: "oldHeader",
        staticClass: "header-v2-hide-header",
        attrs: {
          "allow-quick-books": _vm.allowQuickBooks,
          "file-picker-key": _vm.filePickerKey
        }
      }),
      _c("draft-warning-dialog", {
        attrs: { visible: _vm.draftWarningVisible },
        on: {
          "update:visible": function($event) {
            _vm.draftWarningVisible = $event
          },
          discardDraft: _vm.discardDraft,
          leaveDraft: _vm.leaveDraft,
          publishDraft: _vm.publishDraft
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }