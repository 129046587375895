<template>
  <div class="scheduler-map-container">
    <scheduler-map-tech-filters @center-map="centerMapTo" />
    <gmap-map v-bind="defaultValues" ref="mapRef">
      <gmap-polyline
        v-for="polyline in polylines"
        :key="polyline.id"
        v-bind="polyline"
      />
      <gmap-marker
        v-for="marker in visibleWarehouses"
        :key="marker.id + 'warehouse'"
        v-bind="warehouseMarkerOptions(marker)"
        @mouseover="showInfowindow(marker)"
        @mouseout="showInfowindow(null)"
        :visible="marker.visible"
        :z-index="10"
      />
      <template v-for="values in combinedStops">
        <gmap-marker
          :key="`${stop.type}_${stop.details.id}_icon`"
          :position="{
            lat: stop.details.g_address.lat,
            lng: stop.details.g_address.lng,
          }"
          :icon="getMapStopIcon(stop)"
          @mouseover="showInfowindow(stop)"
          @mouseout="showInfowindow(null)"
          v-for="stop in values"
          :z-index="15"
        />
        <template v-if="map">
          <scheduler-map-route-stop-overlay
            :key="`${stop.type}_${stop.details.id}_content`"
            :map="map"
            v-bind="stop"
            v-for="stop in values"
          />
        </template>
      </template>
      <template v-for="route in data">
        <template v-if="!route.hidden">
          <gmap-marker
            v-for="marker in getRouteMarkers(route)"
            :key="marker.id + 'route_entry'"
            v-bind="marker"
            @click="openDetails(marker)"
            @mouseover="showInfowindow(marker)"
            @mouseout="showInfowindow(null)"
            draggable
            :ref="marker.id"
            :z-index="20"
            @drag="dragmarker(marker, route, $event)"
            @dragstart="dragging = true"
            @dragend="dragmarkerend(marker, route, $event)"
          />
        </template>
      </template>

      <scheduler-map-infowindow
        v-if="map && infowindow && !dragging"
        :map="map"
        :marker="infowindow"
      />
      <template v-for="(loc, username) in techLocations">
        <gmap-marker
          v-bind="getTechMarkerOptions(loc, techsideColors[username])"
          :key="`username + ${username}`"
          :z-index="30"
          @mouseover="techInfowindow = { location: loc, username }"
          @mouseout="techInfowindow = null"
        />
      </template>
      <scheduler-tech-location-infowindow
        v-if="map && techInfowindow && !dragging"
        :map="map"
        v-bind="techInfowindow"
      />
    </gmap-map>
    <scheduler-map-notifications />
    <div class="center-map-button-container">
      <el-button plain @click="centerMap">Reset map to initial view</el-button>
    </div>
    <scheduler-unmapped-jobs @item-clicked="openDetails" />
    <map-tech-live-locations />
  </div>
</template>

<script>
import googleMapsConfig from '@/constants/google-maps.config'
import {
  getRouteMarkers,
  getStateBounds
} from '@/scripts/helpers/geometry_v3.helpers'
import { get, call, sync } from 'vuex-pathify'
import SchedulerMapTechFilters from './filters/SchedulerMapTechFilters'
import SchedulerMapInfowindow from './SchedulerMapInfowindow'
import SchedulerTechLocationInfowindow from './SchedulerTechLocationInfowindow'
import SchedulerMapRouteStopOverlay from './helpers/SchedulerMapRouteStopOverlay'
import SchedulerMapNotifications from './SchedulerMapNotifications'
import SchedulerUnmappedJobs from './SchedulerUnmappedJobs'
import actions from '@/store/modules/scheduler_v3/types'
import {
  getVisibleWarehouses,
  getWarehouseMarkerOptions,
  getTechMarkerOptions
} from '@/scripts/helpers/geometry.helpers'
import { getMapStopIcon } from '@/scripts/helpers/routing.helpers'
import {
  findClosestMarker,
  swapMarkersInRoute
} from '@/scripts/helpers/marker.helpers'
import * as R from 'ramda'
import MapTechLiveLocations from './helpers/MapTechLiveLocations'

export default {
  data() {
    return {
      map: null,
      infowindow: null,
      mapBounds: null,
      dragging: false,
      markersToSwap: null,
      techInfowindow: null
    }
  },
  computed: {
    ...get('sch_v3', [
      'routeStates',
      'filteredWarehouses',
      'combinedStops',
      'data',
      'polylines',
      'techsideColors'
    ]),
    dayCounters: get('sch_v3/filters@dayCounters'),
    bounds: sync('sch_v3/bounds'),
    defaultValues() {
      return googleMapsConfig.defaultValues
    },

    warehouseMarkerOptions() {
      return (warehouse) => {
        return getWarehouseMarkerOptions(warehouse)
      }
    },
    visibleWarehouses() {
      return this.mapBounds
        ? getVisibleWarehouses(this.filteredWarehouses)(this.mapBounds)
        : []
    },
    techLocations() {
      return R.filter(R.identity)(this.$techLocations.locations)
    }
  },
  methods: {
    getRouteMarkers(route) {
      return getRouteMarkers(route)
    },
    getSchedulerData: call(actions.getSchedulerData),
    getSchedulerWarehouses: call(actions.getSchedulerWarehouses),
    getJobDetails: call(actions.getJobDetails),
    mapUpdate: call(actions.mapUpdate),
    showInfowindow(marker) {
      this.infowindow = marker
    },
    getTechMarkerOptions(tech, color) {
      return getTechMarkerOptions(tech, color)
    },
    updateMarkerLocation(id, location) {
      this.$refs[id][0].$markerObject.setPosition(location)
    },
    centerMapTo(location) {
      if (this.$refs.mapRef) {
        this.$refs.mapRef.$mapPromise.then((map) => {
          map.setCenter(location)
          map.setZoom(15)
        })
      }
    },
    centerMap() {
      if (this.$refs.mapRef && this.bounds && !this.jobDetails) {
        this.$refs.mapRef.$mapPromise.then((map) => {
          const bounds = getStateBounds(this.bounds)(this.data)
          map.fitBounds(bounds)
        })
      }
    },
    openDetails(marker) {
      if (marker.job) {
        this.getJobDetails(marker.job.id)
      }
    },
    getMapStopIcon(stop) {
      return getMapStopIcon(stop)
    },
    dragmarker(marker, route, v) {
      const markers = R.pipe(
        R.map(
          R.applySpec({
            id: R.prop('id'),
            latLng: R.pipe(
              R.path(['job', 'install_g_address']),
              R.pick(['lat', 'lng'])
            )
          })
        ),
        R.reject(R.propEq('id', marker.id))
      )(route.route_entries)
      markers.forEach(({ id, latLng }) => {
        this.updateMarkerLocation(id, latLng)
      })
      const closest = findClosestMarker(marker, v.latLng.toJSON(), markers)
      if (closest && closest !== marker.id) {
        this.markersToSwap = closest
        const location = R.pipe(
          R.path(['job', 'install_g_address']),
          R.pick(['lat', 'lng'])
        )(marker)
        this.updateMarkerLocation(marker.id, closest.latLng)
        this.updateMarkerLocation(closest.id, location)
      } else {
        this.markersToSwap = null
      }
    },
    dragmarkerend(marker, route, v) {
      this.dragging = false
      if (this.markersToSwap) {
        const r = swapMarkersInRoute(marker.id, this.markersToSwap.id, route)
        this.mapUpdate(r)
        setTimeout(() => {
          this.getSchedulerData()
        }, 1000)
      }
    }
  },
  async mounted() {
    await this.getSchedulerData()
    await this.getSchedulerWarehouses()
    this.map = await this.$refs.mapRef.$mapPromise
    this.map.addListener('idle', () => {
      this.mapBounds = this.map.getBounds()
    })
    if (this.bounds) {
      this.centerMap()
    }
  },
  watch: {
    bounds(state) {
      this.centerMap()
    },
    newMapRequestFilters(filters, old) {
      if (!R.equals(filters, old)) this.getSchedulerData()
    },
    combineTechs(filters, old) {
      if (!R.equals(filters, old)) this.getSchedulerData()
    }
  },
  components: {
    SchedulerMapTechFilters,
    SchedulerMapInfowindow,
    SchedulerMapNotifications,
    SchedulerUnmappedJobs,
    SchedulerMapRouteStopOverlay,
    MapTechLiveLocations,
    SchedulerTechLocationInfowindow
  }
}
</script>

<style lang="scss" scoped>
@import "./styles/SchedulerMap";
</style>
