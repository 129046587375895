var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.form,
        "hide-required-asterisk": true,
        "label-width": "180px",
        size: "mini"
      }
    },
    [
      _c("el-form-item", [
        _c("span", { staticClass: "h6 mb-0" }, [_vm._v(" Send new request ")])
      ]),
      _c("el-form-item", [
        _c(
          "span",
          {
            staticClass: "undo-label-styles right-part-for-label",
            attrs: { slot: "label" },
            slot: "label"
          },
          [_vm._v(" VIN ")]
        ),
        _c("span", [_vm._v(_vm._s(_vm.vin))])
      ]),
      _c(
        "el-form-item",
        [
          _c(
            "span",
            {
              staticClass: "undo-label-styles right-part-for-label",
              attrs: { slot: "label" },
              slot: "label"
            },
            [_vm._v(" Kind ")]
          ),
          _c(
            "el-radio",
            {
              attrs: { label: "Windshield" },
              model: {
                value: _vm.kind,
                callback: function($$v) {
                  _vm.kind = $$v
                },
                expression: "kind"
              }
            },
            [_vm._v("Windshield")]
          ),
          _c(
            "el-radio",
            {
              attrs: { label: "Back" },
              model: {
                value: _vm.kind,
                callback: function($$v) {
                  _vm.kind = $$v
                },
                expression: "kind"
              }
            },
            [_vm._v("Back Glass")]
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-checkbox",
            {
              staticClass: "mb-3",
              staticStyle: { "text-transform": "none" },
              model: {
                value: _vm.form.checked,
                callback: function($$v) {
                  _vm.$set(_vm.form, "checked", $$v)
                },
                expression: "form.checked"
              }
            },
            [_vm._v(" I AGREE*: Fee $1 ")]
          ),
          _c("p", { staticStyle: { "word-break": "break-word" } }, [
            _vm._v(
              " * The information above is provided by independent 3rd parties. GlassBiller provides access to these resources to help users obtain potentially helpful information for business purposes. Please use this information as a guide and reference and not final verdict for what parts are required for proper installation, nor which parts are appropriate to bill customers or insurance companies. "
            ),
            _c("br")
          ])
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              staticClass: "mb-2 mr-2",
              attrs: {
                type: "primary",
                size: "medium",
                icon: "el-icon-search",
                disabled: !_vm.form.checked,
                loading: _vm.loading
              },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v(" Search ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "ml-0",
              attrs: { plain: "", size: "medium", disabled: _vm.loading },
              on: { click: _vm.close }
            },
            [_vm._v(" Close ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }