<template>
  <div class="job-details-commission">
    <div slot="reference">
      <el-popover
        title="Tech Commissions"
        trigger="click"
      >
        <div
          v-for="vendor in input.vendors"
          :key="vendor.id"
        >
          <commission-row 
            :vendor="vendor" 
            :total="getDollarValue(vendor)"
            @update-commission="updateVendorCommission"  
          />
        </div>
        <div class="" slot="reference">
          ${{ input.value || 0.00 }}
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import actions from '@/store/modules/scheduler_v3/types'
import { call } from 'vuex-pathify'
import { round } from '@/scripts/helpers/totals.helpers'
import CommissionRow from '@/components/JobPageV2/helpers/JobAssignments/CommissionRow.vue';

export default {
  components: {CommissionRow},
  data() {
    return {
      number: null,
      type: null
    }
  },
  props: ['input', 'disabled', 'vendors'],
  methods: {
    updateJobDetailsValues: call(actions.updateJobDetailsValues),
    setValues() {
      this.number = this.input.value
      this.type = this.input.type
    },
    saveValues() {
      this.$emit('update-tech-commission', {
        commission: this.number,
        commission_flat_or_percent: this.type
      })
    },
    getDollarValue(v) {
      return v.commission_total || 0
    },
    updateVendorCommission(v) {
      this.$emit('update-tech-commission', v)
    }
  },
  watch: {
    input(input, old) {
      if (input.value !== old.value) this.setValues()
    }
  },
  mounted() {
    this.setValues()
  }
}
</script>

<style lang="scss" scoped>
.job-details-commission {
  display: flex;
  align-items: center;
  .job-details-commission-input {
    width: 100px;
  }
  .comm-suffix {
    line-height: 30px;
  }
  .comm-save {
    color: $scheduler-icon-color;
    cursor: pointer;
    margin-left: $padding-md;
  }
}
</style>

<style lang="scss">
.job-details-commission {
  .el-input-group__append,
  .el-input-group__prepend {
    padding: 0 8px;
  }
  .el-input__inner {
    min-width: 100px;
    padding: 0 $padding-xs;
    text-align: right;
  }
}
</style>
