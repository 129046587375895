import Vue from 'vue'
import axios from 'axios'
import cfg from '@/config'
import { Message } from 'element-ui'

export const baseApiUrl = cfg.uri

const API = axios.create({
  baseURL: baseApiUrl
})

API.interceptors.request.use(async request => {
  const auth = Vue.prototype.$auth

  request.headers.Authorization = auth.getAuthorizationHeader()
  request.headers['Visitor-Fingerprint-Id'] = auth.getVisitorFingerprintId()

  return request
})

API.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const auth = Vue.prototype.$auth

    if (error.response?.status === 401) {
      if (error.response?.data?.type === 'suspended_visitor_fingerprint_id') {
        auth.handleSuspendedFingerprint()
      } else {
        auth.logout()
      }
      return
    }

    if (!axios.isCancel(error)) {
      Message.error(error.response?.data?.error)
      return Promise.reject(error)
    }
  }
)

export default API
