<template>
  <b-modal
    @show="dialogAboutToAppear"
    title="User Details"
    :id="modalName ? modalName : 'userDialog'"
    :modal-class="'usersAdminDialog'"
    no-close-on-backdrop
    no-close-on-esc
    header-bg-variant="primary"
    header-text-variant="light"
    size="lg"
    hide-header-close
  >
    <div class="form-row">
      <div class="form-group col-md-12">
        ID: <strong>{{ user && user.id > 0 ? user.id : 'NEW' }}</strong>
      </div>
    </div>
    <div class="form-row" v-if="adminCheck()">
      <div class="form-group col-md-4">
        <label for="statusSelect">STATUS</label>
        <select
          id="statusSelect"
          class="form-control form-control-sm autocomplete-off"
          v-model="user.status"
        >
          <option value="active">Active</option>
          <option value="disabled">Disabled</option>
        </select>
      </div>
      <div class="form-group col-md-8">
        <div class="float-right">
          <div class="form-row">
            <input
              id="accountManager"
              type="checkbox"
              class="form-control-sm autocomplete-off"
              v-model="user.account_manager"
            />
            <label for="accountManger">&nbsp;&nbsp;Account Manager</label>
          </div>
          <div class="form-row">
            <input
              id="concurrentLogins"
              type="checkbox"
              class="form-control-sm autocomplete-off"
              v-model="user.allow_concurrent_logins"
            />
            <label for="concurrentLogins">
              &nbsp;&nbsp;Allow Concurrent Logins
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="email">Email</label>
        <div>
          <b-input
            v-if="user && user.id > 0"
            id="email"
            ref="verifiedEmail"
            :disabled="true"
            :state="!!user.verification_finished"
            v-model="user.email"
            class="form-control form-control-sm autocomplete-off"
            @blur="emailCheck"
          />
          <b-input
            v-if="!user || user.id < 0"
            id="email"
            ref="verifiedEmail"
            :disabled="false"
            v-model="user.email"
            class="form-control form-control-sm autocomplete-off"
          />
        </div>
      </div>
      <div class="form-group col-md-6">
        <label for="userUsername">
          Username
          <span
            v-if="!usernameAvailable"
            class="t-text-red-400 t-text-xs t-ml-2"
          >
            Username is taken
          </span>
        </label>
        <input
          ref="username"
          id="userUsername"
          disabled
          class="form-control form-control-sm autocomplete-off"
          :class="{ 't-border-red-300': !usernameAvailable }"
          type="text"
          v-model="user.username"
        />
        <!-- <input
          ref="username"
          id="userUsername"
          :disabled="!adminCheck() && user.id > 0"
          class="form-control form-control-sm autocomplete-off"
          :class="{ 't-border-red-300': !usernameAvailable }"
          type="text"
          v-model="user.username"
        /> -->
      </div>
      <div
        v-if="user && user.id > 0 && adminCheck()"
        class="form-group col-md-6"
      >
        <div class="float-right">
          <span>
            <input
              id="overwritePass"
              type="checkbox"
              class="form-control-sm autocomplete-off"
              v-model="overwritePassword"
            />
            <label for="overwritePass">&nbsp;&nbsp;OVERWRITE PASSWORD</label>
          </span>
        </div>
        <div v-if="overwritePassword">
          <span>
            <label for="newPass">NEW PASSWORD</label>
            <input
              ref="newPass"
              id="newPass"
              type="text"
              class="form-control form-control-sm autocomplete-off"
              v-model="newPassword"
            />
            <span class="t-text-xs t-text-red-500">{{ newPasswordStrengthErrorMessage }}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="timezoneSelect">TIMEZONE</label>
        <MultiSelectDropdown
          :objects="timezones"
          :selectedValues.sync="selectedTimeZone"
          :title="'Select Timezone'"
          :singleSelection="true"
          :closeOnSelect="true"
        />
      </div>
    </div>
    <div v-if="!user || user.id < 0" class="form-row">
      <div class="form-group col-md-6">
        <label for="newPassword">Password</label>
        <div>
          <input
            ref="newPassword"
            id="newPassword"
            type="text"
            class="form-control form-control-sm autocomplete-off"
            v-model="user.password"
          />
          <span style="color:red">{{ passwordStrengthErrorMessage }}</span>
        </div>
      </div>
    </div>
    <div
      class="form-row"
      v-if="adminCheck() && user.id > 0"
      style="margin-top: 5px; margin-left: 2px;"
    >
      <input
        id="nagsUser"
        type="checkbox"
        class="form-control-sm autocomplete-off"
        v-model="user.nags_user"
      /><label for="nagsUser">&nbsp;&nbsp;Nags User</label>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6" v-if="adminCheck() && user.id >= 0">
        <label><strong>NAGS</strong></label
        ><br />
        <span v-if="user.id < 0"
          >Save this user first in order to add a NAGS License...</span
        >
        <span v-if="user.id >= 0 && loadingNagsLicense">Loading...</span>
        <span
          v-if="user.id >= 0 && !loadingNagsLicense && nagsLicense.length > 0"
        >
          <span v-for="(license, index) in nagsLicense" :key="index">
            <a
              @click="openNagsLicense(license)"
              href="javascript:void(0)"
              class="nags-license-badge"
              style="color: white; background: #299fd7; cursor: pointer;"
            >
              <flag
                :squared="false"
                :iso="license.nags_license_type.flag_iso"
              />
              License #: {{ license.id }}
            </a>
          </span>
        </span>
        <span
          v-if="user.id >= 0 && !loadingNagsLicense && nagsLicense.length === 0"
        >
          <a @click="openNagsLicense(null)" href="javascript:void(0)"
            >Create New License</a
          >
        </span>
      </div>
      <div class="form-group col-md-6" v-else-if="user.id < 0">
        <div class="form-row">
          <div class="form-group col-md-5">
            <label><strong>NAGS User</strong></label
            ><br />
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input
                  class="form-check-input"
                  type="radio"
                  value="yes"
                  v-model="createNagsLicense"
                />
                Yes
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input
                  class="form-check-input"
                  type="radio"
                  value="no"
                  v-model="createNagsLicense"
                />
                No
              </label>
            </div>
          </div>
          <div v-if="createNagsLicense === 'yes'" class="form-group col-md-7">
            <label><strong>NAGS Type</strong></label
            ><br />
            <div class="form-check form-check-inline">
              <select
                v-model="nagsLicenseTypeId"
                class="form-control form-control-sm"
              >
                <option
                  v-for="licenseType in nagsLicenseTypes"
                  :key="licenseType.id"
                  :value="licenseType.id"
                  >{{ licenseType.name }}</option
                >
              </select>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="form-group col-md-6">
        &nbsp;
      </div>
      <div class="form-group col-md-6">
        <div class="float-right">
          <label>Note color</label>
          <br />
          <Sketch id="sketch-picker" v-model="userColor" />
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <label for="userNotes">Notes</label>
        <textarea
          id="userNotes"
          class="form-control form-control-sm autocomplete-off"
          rows="4"
          v-model="user.notes"
        ></textarea>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <user-dialog-shop-transfer
          v-if="attachedShops"
          :options="localMasterList"
          :attached.sync="attachedShops"
          :userId="user.id"
          @changed="handleUserShopsChange"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label for="defaultShop">Default Shop</label>
          <select
            id="defaultShop"
            class="form-control form-control-sm"
            v-model="user.shop_id"
          >
            <option value="">Select shop...</option>
            <option
              v-for="shop in attachedShops"
              :key="'default-shop' + shop.id"
              :value="shop.id"
              >{{ shop.name }}</option
            >
          </select>
        </div>
      </div>
      <div class="form-group col-md-8">
        <multi-select-with-group-dropdown
          :objects="csrs"
          :selectedValues.sync="selectedCSRs"
          title="Default CSR"
          :trackBy="'shopIdCombo'"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <MultiSelectDropdown
          :objects="relatedTwilioPhoneNumbers"
          :selectedValues.sync="selectedTwilioPhoneNumbers"
          title="Select text messaging phone numbers"
        />
      </div>
    </div>
    <div class="row" v-if="adminCheck()">
      <div class="col-md-4">
        <label for="anniversaryDate">Anniversary Date</label>
        <DatePickerShortcut
          id="anniversaryDate"
          :value.sync="user.anniversary_date"
          displayFormat="MM/dd/yyyy"
          saveFormat="yyyy-MM-dd"
        />
      </div>
      <div class="col-md-4">
        <label for="reportedDate">Reported Date</label>
        <DatePickerShortcut
          id="reportedDate"
          :value.sync="user.reported_date"
          displayFormat="MM/dd/yyyy"
          saveFormat="yyyy-MM-dd"
        />
      </div>
      <div class="col-md-4">
        <label for="chargeAmount">Charge Amount</label>
        <FloatInput
          ref="userChargeAmount"
          :value.sync="user.charge"
          :minimumDecimalPlaces="2"
          :maximumDecimalPlaces="2"
          :formattedPrefix="'$ '"
          :textAlign="'left'"
        />
      </div>
    </div>
    <div class="row" v-if="adminCheck()">
      <div class="col-md-6 mt-3">
        <label for="faxAddButton">Fax Numbers</label>
        <b-button
          id="faxAddButton"
          class="label label-gray label-sm"
          size="sm"
          @click="addFaxClicked"
          >+ NEW</b-button
        >
      </div>
      <div v-if="faxNumbers.length > 1" class="col-md-6">
        <label>Default Outgoing Fax</label>
        <select
          class="form-control form-control-sm autocomplete-off"
          v-model="userFaxNumber"
        >
          <option
            v-for="faxNumber in faxNumbers"
            :key="faxNumber.id"
            :value="faxNumber.id"
            >{{ phoneFormat(faxNumber.fax) }}</option
          >
        </select>
      </div>
    </div>
    <div class="row" v-if="adminCheck()">
      <div
        class="form-group col-md-4 mt-2"
        v-for="(fax, index) in faxNumbers"
        :key="index"
      >
        <InputWithDelete
          type="phone"
          :parentValue.sync="fax.fax"
          @remove="removeFax(index)"
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-4 mt-2">
        <CheckBoxLabel
          label="Auto Search Nags"
          :value.sync="user.auto_redo_nags_search"
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-4 mt-2">
        <label for="userRecentJobCount"
          >Recent Job Count (Set to 0 to disable)</label
        >
        <input
          ref="userRecentJobCount"
          class="form-control form-control-sm"
          type="number"
          min="0"
          max="30"
          v-model="user.recent_job_count"
          style="width:100%"
          @blur="recentJobCountCheck"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-2">
        <label>User Permissions</label>
        <a href="#" @click.prevent="showPermissions = !showPermissions">
          <i
            v-if="!showPermissions"
            style="color:dodgerblue"
            class="fa fa-caret-left fa-2x"
          ></i>
          <i
            v-if="showPermissions"
            style="color:dodgerblue"
            class="fa fa-caret-down fa-2x"
          ></i>
        </a>
        <b-collapse id="permissions-collapse" v-model="showPermissions">
          <div
            style="border: 2px solid lightgray; border-radius: 5px;"
            class="p-2"
          >
            <div class="row">
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="job"
                  :checked="isPermissionChecked('job')"
                  @change="changePermission"
                />&nbsp;<label for="jobPermission">Job</label>
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="jobmanager"
                  :checked="isPermissionChecked('jobmanager')"
                  @change="changePermission"
                />&nbsp;<label for="jobmanagerPermission">Job Manager</label>
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="managecsrs"
                  :checked="isPermissionChecked('managecsrs')"
                  @change="changePermission"
                />&nbsp;<label for="managecsrsPermission">CSR</label>
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="managesalesreps"
                  :checked="isPermissionChecked('managesalesreps')"
                  @change="changePermission"
                />&nbsp;<label for="managesalesrepsPermission">Sales Rep</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="managetechs"
                  :checked="isPermissionChecked('managetechs')"
                  @change="changePermission"
                />&nbsp;<label for="managetechsPermission">Tech</label>
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="managesalesources"
                  :checked="isPermissionChecked('managesalesources')"
                  @change="changePermission"
                />&nbsp;<label for="managesalesourcesPermission"
                  >Sale Source</label
                >
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="managelocations"
                  :checked="isPermissionChecked('managelocations')"
                  @change="changePermission"
                />&nbsp;<label for="managelocationsPermission">Location</label>
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="manageinsurance"
                  :checked="isPermissionChecked('manageinsurance')"
                  @change="changePermission"
                />&nbsp;<label for="manageinsurancePermission"
                  >Insurance/Fleet</label
                >
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="managecommercialaccounts"
                  :checked="isPermissionChecked('managecommercialaccounts')"
                  @change="changePermission"
                />&nbsp;<label for="managecommercialaccountsPermission"
                  >&nbsp;&nbsp;Comm. Acct</label
                >
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="managetaxes"
                  :checked="isPermissionChecked('managetaxes')"
                  @change="changePermission"
                />&nbsp;<label for="managetaxesPermission">Shop Taxes</label>
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="ar_ap"
                  :checked="isPermissionChecked('ar_ap')"
                  @change="changePermission"
                />&nbsp;<label for="ar_apPermission">AR/AP</label>
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="reports"
                  :checked="isPermissionChecked('reports')"
                  @change="changePermission"
                />
                &nbsp;
                <label for="reportsPermission">
                  <a href="javascript:void(0)" @click="openReportPermissions"
                    >Reports <v-icon name="cog" scale="0.6"
                  /></a>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="accounts"
                  :checked="isPermissionChecked('accounts')"
                  @change="changePermission"
                />&nbsp;<label for="accountsPermission">Accounts</label>
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="shops"
                  :checked="isPermissionChecked('shops')"
                  @change="changePermission"
                />&nbsp;<label for="shopsPermission">Shops</label>
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="users"
                  :checked="isPermissionChecked('users')"
                  @change="changePermission"
                />&nbsp;<label for="usersPermission">Users</label>
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="jobvoid"
                  :checked="isPermissionChecked('jobvoid')"
                  @change="changePermission"
                />&nbsp;<label for="jobvoid">Void Job</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="graph"
                  :checked="isPermissionChecked('graph')"
                  @change="changePermission"
                />&nbsp;<label for="graphPermission">Revenue Dashboard</label>
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="pricing"
                  :checked="isPermissionChecked('pricing')"
                  @change="changePermission"
                />&nbsp;<label for="pricingPermission">Pricing</label>
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="commission"
                  :checked="isPermissionChecked('commission')"
                  @change="changePermission"
                />&nbsp;<label for="commissionPermission"
                  >View Commissions</label
                >
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="jobunvoid"
                  :checked="isPermissionChecked('jobunvoid')"
                  @change="changePermission"
                />&nbsp;<label for="jobunvoid">Unvoid Job</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="viewparts"
                  :checked="isPermissionChecked('viewparts')"
                  @change="changePermission"
                />&nbsp;<label for="viewpartsPermission">View Parts</label>
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="accountingpackages"
                  :checked="isPermissionChecked('accountingpackages')"
                  @change="changePermission"
                />&nbsp;<label for="accountingpackagesPermission"
                  >Accounting Packages</label
                >
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="rightpart"
                  :checked="isPermissionChecked('rightpart')"
                  @change="changePermission"
                />&nbsp;<label for="rightpart">Right Part</label>
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="jobviewonly"
                  :checked="isPermissionChecked('jobviewonly')"
                  @change="changePermission"
                />&nbsp;<label for="jobviewonly">Job View Only</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="warehouse"
                  :checked="isPermissionChecked('warehouse')"
                  @change="changePermission"
                />&nbsp;<label for="warehousePermission">Warehouse</label>
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="scheduler"
                  :checked="isPermissionChecked('scheduler')"
                  @change="changePermission"
                />&nbsp;<label for="scheduler">Scheduler</label>
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="setjobdate"
                  :checked="isPermissionChecked('setjobdate')"
                  @change="changePermission"
                />&nbsp;<label for="setjobdate">Set Job Date</label>
              </div>
              <!-- <div class='col-md-3'>
                <input type='checkbox' id='techcommission' :checked='isPermissionChecked("techcommission")' @change='changePermission' />&nbsp;<label for='rightpart'>Tech Commission Only</label>
              </div> -->
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="discarddraft"
                  :checked="isPermissionChecked('discarddraft')"
                  @change="changePermission"
                />&nbsp;<label for="discarddraft">Discard Draft</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="datatab"
                  :checked="isPermissionChecked('datatab')"
                  @change="changePermission"
                />
                &nbsp;
                <label for="datatabPermission">
                  <a href="javascript:void(0)" @click="openDatatabPermissions"
                    >Data <v-icon name="cog" scale="0.6"
                  /></a>
                </label>
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="notificationsettings"
                  :checked="isPermissionChecked('notificationsettings')"
                  @change="changePermission"
                />
                &nbsp;
                <label for="notificationsettings">
                  <a
                    href="javascript:void(0)"
                    @click="openNotificationSettingsPermissions"
                    >Notification Settings <v-icon name="cog" scale="0.6"
                  /></a>
                </label>
              </div>
              <div class="col-md-3">
                <input
                  type="checkbox"
                  id="jobmargin"
                  :checked="isPermissionChecked('jobmargin')"
                  @change="changePermission"
                />&nbsp;<label for="jobmargin">Job Margin</label>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
    <div v-if="user.id < 0" class="row">
      <div class="col-md-12 mt-2">
        <label>Billing</label>
        <p v-if="loadingBillingDetails">Loading...</p>
        <p v-if="!loadingBillingDetails"><b>Account:</b> {{ account_name }}</p>
        <p v-if="!loadingBillingDetails">
          <b>Current Fee:</b> {{ current_fee }}
        </p>
        <p v-if="!loadingBillingDetails">
          <b>Price for New User:</b> {{ new_user_fee }}
        </p>
        <p v-if="!loadingBillingDetails"><b>New Fee:</b> {{ new_fee }}</p>
      </div>
    </div>
    <div slot="modal-footer" class="w-100">
      <div v-if="saving || loading" class="float-left">
        <i class="fa fa-spinner fa-spin" style="font-size:24px"></i>
        {{ spinnerTitle }}
      </div>
      <div class="float-right">
        <button
          type="button"
          class="btn btn-primary"
          variant="primary"
          :disabled="!usernameAvailable"
          @click="saveClicked"
        >
          {{ user && user.id >= 0 ? 'Save' : 'Confirm & Create' }}
        </button>
        &nbsp;&nbsp;&nbsp;
        <button
          type="button"
          class="btn btn-secondary"
          variant="primary"
          @click="cancelClicked"
        >
          Cancel
        </button>
      </div>
    </div>
    <report-permissions-dialog
      :visible.sync="reportPermissionsOpen"
      :userId="user.id"
      :userPermissions="userPermissions"
      @refreshPermissions="refreshPermissions"
    />
    <data-tab-permissions-dialog
      :visible.sync="datatabPermissionsOpen"
      :userId="user.id"
      :userPermissions="userPermissions"
      @refreshPermissions="refreshPermissions"
    />
    <notification-setting-permissions-dialog
      :visible.sync="notificationSettingPermissionsOpen"
      :userId="user.id"
      :userPermissions="userPermissions"
      @refreshPermissions="refreshPermissions"
    />
  </b-modal>
</template>

<script>
import User from '@/scripts/objects/user'
import Account from '@/scripts/objects/account'
import Shop from '@/scripts/objects/shop'
import Sketch from '@/assets/customComponents/sketchColorPicker/components/Sketch.vue'
import MultiSelectDropdown from '@/components/Helpers/MultiSelectDropdown.vue'
import MultiSelectWithGroupDropdown from '@/components/Helpers/MultiSelectWithGroupDropdown.vue'
import CSR from '@/scripts/objects/csr'
import CSRDefaults from '@/scripts/objects/csrdefaults'
import DatePickerShortcut from '@/components/Helpers/DatePickerShortcut.vue'
import FloatInput from '@/components/Helpers/FloatInput.vue'
import Faxnumber from '@/scripts/objects/faxnumber'
import InputWithDelete from '@/components/Helpers/InputWithDelete.vue'
import CheckBoxLabel from '@/components/Helpers/CheckBoxLabel'
import RoleUser from '@/scripts/objects/roleuser'
import Role from '@/scripts/objects/role'
import { UtilityMixin, testEmail } from '@/scripts/helpers/utilities'
import * as R from 'ramda'
import NagsLicense from '@/scripts/objects/nags_license'
import ReportPermissionsDialog from './ReportPermissionsDialog.vue'
import DataTabPermissionsDialog from './DataTabPermissionsDialog.vue'
import NotificationSettingPermissionsDialog from './NotificationSettingPermissionsDialog.vue'
import moment from 'moment-timezone'
import UserDialogShopTransfer from '@/components/modals/UserDialogShopTransfer.vue'
import Api from '@/services/Api'

export default {
  props: ['parentUser', 'shopsMasterList', 'isExpanded', 'modalName'],
  data() {
    return {
      user: new User(),
      attachedShops: null,
      overwritePassword: false,
      newPassword: '',
      usernameAvailable: true,
      csrs: [],
      csrDefaults: [],
      // for now just because this needs to get done but will refactor adding to the user object
      initialCSRDefaults: [],
      selectedCSRs: [],
      faxNumbers: [],
      showPermissions: false,
      userPermissions: {},
      initialPermissions: {},
      permissionsToAdd: [],
      permissionsToDelete: [],
      roles: {},
      loading: false,
      loadingNagsLicense: false,
      loadingBillingDetails: false,
      nagsLicense: [],
      saving: false,
      userVerifiedEmail: '',
      relatedTwilioPhoneNumbers: [],
      selectedTwilioPhoneNumbers: [],
      account_name: '',

      subscriptionPlanDetails: null,
      subscriptionInterval: 0,
      subscriptionIntervalUnit: '',
      subscriptionTotal: 0,
      addOns: [],

      current_fee: '',

      createNagsLicense: 'yes',
      nagsLicenseTypeId: -1,
      nagsLicenseTypes: [],
      localMasterList: [],

      loadingOldCSRs: false,
      loadingNewCSRs: false,

      reportPermissionsOpen: false,
      datatabPermissionsOpen: false,
      notificationSettingPermissionsOpen: false
    }
  },
  created() {
    this.$root.$on('reloadNagsLicense', this.loadNagsLicense)
    this.$root.$on('newNagsLicenseCreatedUpdated', this.loadNagsLicense)
  },
  beforeDestroy: function() {
    this.$root.$off('reloadNagsLicense', this.loadNagsLicense)
    this.$root.$off('newNagsLicenseCreatedUpdated', this.loadNagsLicense)
  },
  methods: {
    handleUserShopsChange(val) {
      this.attachedShops = this.localMasterList
        ?.filter(shop => val.includes(shop.id))
        .map(obj => {
          return {
            id: obj.id,
            name: obj.name,
            shopUserId: -1,
            organization_id: obj.organization_id
          }
        })
    },
    loadNagsLicense() {
      if (this.adminCheck() && this.user.id >= 0) {
        const self = this
        // get user nagsLicense
        self.loadingNagsLicense = true
        NagsLicense.getUserNagsLicense(this.user.id, function(nagsLicense) {
          self.loadingNagsLicense = false
          if (nagsLicense && nagsLicense.length) {
            self.nagsLicense = nagsLicense
            self.$root.$emit('updateUserNags', self.user.id, 1)
          } else {
            self.nagsLicense = []
            self.$root.$emit('updateUserNags', self.user.id, 0)
          }
        })
      }
    },
    loadAddUserBillingDetails() {
      if (this.user.id < 0) {
        const self = this
        // get user nagsLicense
        self.loadingBillingDetails = true
        Account.getBillingDetails(this.user.account_id, false, true, function(
          data
        ) {
          self.loadingBillingDetails = false
          if (data.response === 'success') {
            self.account_name = data.accountName

            self.subscriptionPlanDetails = data.subscription_plan
            self.subscriptionInterval = data.interval
            self.subscriptionIntervalUnit = data.interval_unit
            self.subscriptionTotal = data.amount

            self.addOns = data.addons
            self.nagsLicenseTypes = data.nagsLicenseTypes
            if (
              self.nagsLicenseTypes &&
              self.nagsLicenseTypes.length > 0 &&
              (!self.nagsLicenseType ||
                !self.nagsLicenseType.id ||
                self.nagsLicenseType.id < 0)
            ) {
              self.nagsLicenseTypeId = self.nagsLicenseTypes[0].id
            } else {
              self.nagsLicenseType = -1
            }

            let billingDisplayAmount = self.formatCurrency(
              data.amount,
              false,
              true
            )
            if (data.interval === 1 && data.interval_unit === 'months') {
              billingDisplayAmount += '/Month'
            } else {
              billingDisplayAmount +=
                ' every ' + data.interval + ' ' + data.interval_unit
            }
            self.current_fee = billingDisplayAmount
          } else {
            if (data.errorMessage && data.errorMessage.length > 0) {
              alert(data.errorMessage)
            } else {
              alert(
                'Something went wrong loading billing details, please try again. If the error persists, you may need to reach out to Support in order to add additional users to your account.'
              )
            }
            self.$root.$emit(
              'bv::hide::modal',
              this.modalName ? this.modalName : 'userDialog'
            )
          }
        })
      }
    },
    openNagsLicense(license) {
      if (license === null) {
        license = new NagsLicense()
      }
      this.$root.$emit('openNagsLicense', license.id, this.parentUser)
    },
    dialogAboutToAppear() {
      this.initialize()
    },
    async initialize() {
      this.userVerifiedEmail = ''
      this.usernameAvailable = true
      this.loading = true
      this.saving = false
      // need to get additional information for user
      this.csrs = []
      if (!this.parentUser) {
        const { user, shops } = await User.getUserData(
          this.$store.state.user.id
        )
        this.user = new User({ ...user, shops })
      } else {
        this.user = new User(this.parentUser)
      }

      this.loadNagsLicense()
      this.loadAddUserBillingDetails()
      this.userVerifiedEmail = this.user.email
      this.attachedShops = this.user.shops
      const gbCSRTempOrganizationIds = []
      const gbCSRTempShopArray = {}
      const csrOldTempShopIds = []
      const csrOldTempShopArray = {}
      this.faxNumbers = []
      this.csrDefaults = []
      this.selectedCSRs = []
      // this.faxNumbers = []
      this.newPassword = ''
      this.overwritePassword = false
      this.userPermissions = {}
      this.showPermissions = false
      this.permissionsToAdd = []
      this.permissionsToDelete = []
      this.initialCSRDefaults = []
      this.initialPermissions = {}
      this.roles = {}
      // put all shopIds in a string so we can get csrs
      this.loadingOldCSRs = true
      this.loadingNewCSRs = true

      for (let i = 0; i < this.attachedShops.length; i++) {
        if (this.attachedShops[i]) {
          if (
            gbCSRTempOrganizationIds.indexOf(
              this.attachedShops[i].organization_id
            ) < 0
          ) {
            gbCSRTempOrganizationIds.push(this.attachedShops[i].organization_id)
          }
          gbCSRTempShopArray[this.attachedShops[i].id.toString()] = {}
          gbCSRTempShopArray[
            this.attachedShops[i].id.toString()
          ].id = this.attachedShops[i].id
          gbCSRTempShopArray[
            this.attachedShops[i].id.toString()
          ].name = this.attachedShops[i].name
          gbCSRTempShopArray[
            this.attachedShops[i].id.toString()
          ].organization_id = this.attachedShops[i].organization_id
          gbCSRTempShopArray[this.attachedShops[i].id.toString()].csrs = []
        }
      }

      // go get CSRs for groupmultiselect
      const self = this
      if (this.shopsMasterList) {
        this.localMasterList = this.shopsMasterList
      } else {
        const { data } = await Shop.getShopsForUser()
        const localList = []
        if (data && data.shops && data.shops.length) {
          for (let i = 0; i < data.shops.length; i++) {
            localList.push({
              ...data.shops[i],
              name: `(${data.shops[i].id}) ${data.shops[i].name}`,
              saveName: `${data.shops[i].name}`
            })
          }
        }
        this.localMasterList = localList
      }
      if (this.user.id > 0) {
        CSRDefaults.getCSRDefaults(this.user.id, async function(res) {
          self.csrs = []
          self.csrDefaults = []
          self.csrDefaults = res
          res.forEach(resItem => {
            self.initialCSRDefaults.push(new CSRDefaults(resItem))
          })

          if (gbCSRTempOrganizationIds.length > 0) {
            const data = await self.$unum
              .vendor()
              .get(`organization/gb_csr/${gbCSRTempOrganizationIds.toString()}`)

            // let's go through and build grouped list for the csrs
            for (let i = 0; i < data.data.length; i++) {
              for (let k = 0; k < self.csrDefaults.length; k++) {
                if (self.csrDefaults[k].gb_csr_id === data.data[i].id) {
                  data.data[i].shopIdCombo =
                    self.csrDefaults[k].shop_id +
                    '-' +
                    self.csrDefaults[k].gb_csr_id
                  self.selectedCSRs.push(R.clone(data.data[i]))
                }
              }

              for (const shopKey in gbCSRTempShopArray) {
                if (
                  gbCSRTempShopArray[shopKey].organization_id ===
                  data.data[i].organization_id
                ) {
                  data.data[i].shopIdCombo = shopKey + '-' + data.data[i].id
                  gbCSRTempShopArray[shopKey].csrs.push(R.clone(data.data[i]))
                }
              }
            }

            // now that we have all these built we can put into an array
            const tempKeys = Object.keys(gbCSRTempShopArray)
            for (let j = 0; j < tempKeys.length; j++) {
              self.csrs.push(gbCSRTempShopArray[tempKeys[j]])
            }
            // we'll go here to turn off the loading
            self.loading = false
            self.loadingNewCSRs = false
          } else {
            self.loadingNewCSRs = false
          }

          if (csrOldTempShopIds.length > 0) {
            CSR.getAll(csrOldTempShopIds.toString(), res => {
              // let's go through and build grouped list for the csrs
              for (let i = 0; i < res.length; i++) {
                for (let k = 0; k < self.csrDefaults.length; k++) {
                  if (self.csrDefaults[k].csr_id === res[i].id) {
                    self.selectedCSRs.push(res[i])
                  }
                }
                csrOldTempShopArray[res[i].shop_id.toString()].csrs.push(res[i])
              }
              // now that we have all these built we can put into an array
              const tempKeys = Object.keys(csrOldTempShopArray)
              for (let j = 0; j < tempKeys.length; j++) {
                self.csrs.push(csrOldTempShopArray[tempKeys[j]])
              }

              // we'll go here to turn off the loading
              self.loading = false
              self.loadingOldCSRs = false
            })
          } else {
            self.loadingOldCSRs = false
          }

          if (
            csrOldTempShopIds.length === 0 &&
            gbCSRTempOrganizationIds.length === 0
          ) {
            self.loading = false
          }
        })

        Faxnumber.getUserFaxes(this.user.id, res => {
          for (let l = 0; l < res.length; l++) {
            self.faxNumbers.push(res[l])
          }
          self.loading = false
        })

        RoleUser.getUserRoles(this.user.id, res => {
          for (let i = 0; i < res.length; i++) {
            self.userPermissions[res[i].name] = res[i]
            self.initialPermissions[res[i].name] = res[i]
          }
          self.loading = false
        })

        Role.getRoles(res => {
          self.roles = {}
          for (let i = 2; i < res.length; i++) {
            self.roles[res[i].name] = res[i]
          }
          self.loading = false
        })

        this.relatedTwilioPhoneNumbers = await User.getRelatedTwilioPhoneNumbers(
          this.user.id
        )
        this.selectedTwilioPhoneNumbers = await User.getSelectedTwilioPhoneNumbers(
          this.user.id
        )
      } else {
        self.loadingOldCSRs = false
        self.loadingNewCSRs = false

        // by default we add all roles
        Role.getRoles(res => {
          self.roles = {}
          for (let i = 2; i < res.length; i++) {
            if (i !== 22 && i !== 26) {
              self.roles[res[i].name] = res[i]
            }
          }

          RoleUser.getUserRolesDefault(self.user.id, self.roles, response => {
            self.userPermissions = { ...response }
          })
          self.loading = false
        })
      }
    },
    addFaxClicked() {
      const tempFax = new Faxnumber({ user_id: this.user.id })
      this.faxNumbers.push(tempFax)
    },
    removeFax(val) {
      this.faxNumbers.splice(val, 1)
    },
    updateFax(val) {},
    isPermissionChecked(val) {
      return Object.prototype.hasOwnProperty.call(this.userPermissions, val)
    },
    changePermission(val) {
      if (val.target.checked) {
        // verify this isn't in the permissions to delete
        // for (let i = 0; i < this.permissionsToDelete.length; i++) {
        //   if (this.permissionsToDelete[i].role_id === this.roles[val.target.id].id) {
        //     this.permissionsToDelete.splice(i, 1)
        //   }
        // }
        // this.permissionsToAdd.push(new RoleUser({id: -1, role_id: this.roles[val.target.id].id, user_id: this.user.id}))
        this.$set(
          this.userPermissions,
          val.target.id,
          new RoleUser({
            id: -1,
            role_id: this.roles[val.target.id].id,
            user_id: this.user.id
          })
        )
      } else {
        // verify this isn't in the permissions to delete
        // for (let i = 0; i < this.permissionsToAdd.length; i++) {
        //   if (this.permissionsToAdd[i].role_id === this.roles[val.target.id].id) {
        //     this.permissionsToAdd.splice(i, 1)
        //   }
        // }
        // this.permissionsToDelete.push(this.userPermissions[val.target.id])
        this.$delete(this.userPermissions, val.target.id)
      }
    },
    cancelClicked() {
      if (!this.cleanCheck()) {
        if (
          !confirm(
            'There are changes to this user that have not been saved. Press cancel to go back and save these changes.'
          )
        ) {
          return
        }
      }

      this.$root.$emit('refreshUsersTable')

      if (this.isExpanded) {
        this.$root.$emit(
          'bv::hide::modal',
          this.modalName ? this.modalName : 'userDialog'
        )
      } else {
        this.$root.$emit('bv::show::modal', 'accountDialog')
      }
    },
    saveClicked() {
      if (this.saving) {
        return
      }

      if (this.validityCheck().length !== 0) {
        const tempErrors =
          'There are errors and user cannot be saved. Press fix these issues to save these changes:\n-' +
          this.validityCheck().join('\n-')
        alert(tempErrors)
        return
      }

      const isNewUser = this.user.id < 0
      if (isNewUser) {
        if (this.user.password.length === 0) {
          alert('Please enter in a password for this user.')
          return
        } else if (this.passwordStrengthErrorMessage.length > 0) {
          this.$refs.newPassword.focus()
          return
        }
      }

      if (this.overwritePassword && this.newPasswordStrengthErrorMessage) {
        this.$refs.newPass.focus()
        return
      }

      if (!this.user.shop_id || this.user.shop_id < 0) {
        alert('Please choose a default shop for this user.')
        return
      }

      this.saving = true

      // let's get permissions to an array
      const permissions = []
      const tempKeys = Object.keys(this.userPermissions)
      for (let i = 0; i < tempKeys.length; i++) {
        permissions.push(this.userPermissions[tempKeys[i]])
      }

      // remove any faxes that don't actually have data
      for (let j = 0; j < this.faxNumbers.length; j++) {
        if (this.faxNumbers[j].fax === '') {
          this.faxNumbers.splice(j, 1)
          j--
        }
      }

      if (!this.parentUser) {
        for (let h = 0; h < this.attachedShops.length; h++) {
          if (!this.attachedShops[h].shopUserId) {
            this.attachedShops.splice(h, 1, {
              id: this.attachedShops[h].id,
              name: this.attachedShops[h].name,
              shopUserId: -1
            })
          }
        }
      } else {
        for (let k = 0; k < this.attachedShops.length; k++) {
          if (!this.attachedShops[k].shopUserId) {
            this.attachedShops.splice(k, 1, {
              id: this.attachedShops[k].id,
              name: this.attachedShops[k].saveName,
              shopUserId: -1
            })
          }
        }
      }

      const self = this
      const dataToSend = {}
      dataToSend.csrDefaults = this.csrDefaults
      dataToSend.faxNumbers = this.faxNumbers
      dataToSend.permissions = permissions
      dataToSend.password = this.newPassword
      if (this.user.id < 0) {
        dataToSend.password = this.user.password
        dataToSend.attachedShops = this.attachedShops
      }
      dataToSend.selectedTwilioPhoneNumbers = this.selectedTwilioPhoneNumbers
      dataToSend.csrDefaultsUpdated = !this.csrDefaultCleanCheck
      dataToSend.faxNumbersUpdated = !this.faxCleanCheck
      dataToSend.permissionsUpdated = !this.permissionCleanCheck
      dataToSend.userUpdated = !this.userCleanCheck
      this.user.nags_user = this.user.nags_user ? 1 : 0
      dataToSend.user = this.user
      dataToSend.isNewUser = isNewUser
      dataToSend.newUserCreateNagsLicense = this.createNagsLicense
      dataToSend.newUserNagsLicenseTypeId = this.nagsLicenseTypeId
      User.saveUserInternal(
        [dataToSend],
        this,
        val => {
          self.saving = false
          if (val.data && val.data.response === 'error') {
            alert('Error: ' + val.data.message)
          } else {
            if (val.data.result && val.data.result === 'newUserCreated') {
              alert('This user was successfully created.')

              self.$root.$emit(
                'newUserCreated',
                val.data.newUserId,
                val.data[val.data.newUserId]
              )
              if (self.isExpanded) {
                self.$root.$emit(
                  'bv::hide::modal',
                  this.modalName ? this.modalName : 'userDialog'
                )
              } else {
                self.$root.$emit('bv::show::modal', 'accountDialog')
              }
              self.$root.$emit(
                'showGlobalNotification',
                'Success',
                `User '${self.user.username}' ` +
                  (isNewUser ? 'Created' : 'Updated')
              )
            } else {
              self.$root.$emit(
                'updateUser',
                self.user.id,
                val.data[self.user.id]
              )
              if (self.isExpanded) {
                self.$root.$emit(
                  'bv::hide::modal',
                  this.modalName ? this.modalName : 'userDialog'
                )
              } else {
                self.$root.$emit('bv::show::modal', 'accountDialog')
              }
              self.$root.$emit(
                'showGlobalNotification',
                'Success',
                `User '${self.user.username}' ` +
                  (isNewUser ? 'Created' : 'Updated')
              )
            }
          }
        },
        val => {
          self.saving = false
          alert(
            'There was an issue saving the user. Please try again. \nError: ' +
              val.message
          )
        }
      )
    },
    cleanCheck() {
      // check user
      if (!this.userCleanCheck) {
        return false
      }

      if (!this.permissionCleanCheck) {
        return false
      }

      // check fax numbers
      if (!this.faxCleanCheck) {
        return false
      }

      if (!this.csrDefaultCleanCheck) {
        return false
      }

      if (!this.emailCleanCheck) {
        return false
      }

      return true
    },
    validityCheck() {
      const errors = []
      if (this.user.id === -1) {
        if (!testEmail(this.user.email)) {
          errors.push('Invalid email.')
        }
        if (!this.user.username || this.user.username.length === 0) {
          errors.push('Invalid username.')
        }
      }
      return errors
    },
    passwordCheck(password) {
      // no spaces and 6 chars or more
      if (password.includes(' ')) return false
      if (password.length < 6) return false

      return true
    },
    adminCheck() {
      return this.$store.state.user.admin
    },
    emailCheck() {
      if (this.adminCheck()) {
        const self = this
        User.checkEmail(this.user.id, this.user.email, res => {
          if (res.result === 'error') {
            alert('Email not available. Please enter a different email.')
            setTimeout(function() {
              self.$refs.verifiedEmail.focus()
            }, 100)
          } else {
            if (!this.user.username.length) {
              this.user.username = this.user.email.split('@')[0]
            }
          }
        })
      } else {
        this.userVerifiedEmail = this.user.email
      }
    },
    async checkUsername() {
      if (this.user.username.length > 0) {
        const self = this
        const res = await Api({
          url: `/api/usernamecheck/${this.user.username}/${this.user.id}`
        })
        this.usernameAvailable = res.data

        if (!this.usernameAvailable) {
          setTimeout(function() {
            self.$refs.username.focus()
          }, 100)
        }
      }
    },
    resetEmailVerification() {
      if (
        confirm(
          `Clicking okay will logout the user '${this.user.username}' and require them to reverify their email address. Please click cancel to leave the email as is.`
        )
      ) {
        const self = this
        User.resetVerifiedEmail(this.user.id, res => {
          self.userVerifiedEmail = ''
          self.user.email = ''
          self.user.objectTrackerInitial.email = ''
          self.$root.$emit('resetEmail', self.user.id)
        })
      }
    },
    phoneFormat(val) {
      // const ac = val.substr(0, 3)
      // const pf = val.substr(3, 3)
      // const ld = val.substr(6, 4)
      // return '('+ac+') '+pf+'-'+ld
      if (val) {
        if (val.toString().length > 0) {
          const temp = ('' + val)
            .replace(/\D/g, '')
            .match(/(\d{0,3})(\d{0,3})(\d{0,4})$/)

          return !temp[2]
            ? temp[1]
            : '(' + temp[1] + ') ' + temp[2] + (temp[3] ? '-' + temp[3] : '')
        }
      }
    },
    recentJobCountCheck() {
      if (this.user.recent_job_count > 30) {
        this.user.recent_job_count = 30
      } else if (this.user.recent_job_count < 0) {
        this.user.recent_job_count = 0
      }
    },
    moveToBeginningOfArray(array, values) {
      for (let i = values.length - 1; i >= 0; i--) {
        const value = values[i]
        const index = array.indexOf(value)
        if (index > -1) {
          array.splice(index, 1)
          array.splice(0, 0, value)
        }
      }
      return array
    },
    openReportPermissions() {
      if (this.user.id > 0) {
        this.reportPermissionsOpen = true
      } else {
        alert(
          'Please complete user creation before adjusting individual report permissions.'
        )
      }
    },
    openDatatabPermissions() {
      if (this.user.id > 0) {
        this.datatabPermissionsOpen = true
      } else {
        alert(
          'Please complete user creation before adjusting individual data permissions.'
        )
      }
    },
    openNotificationSettingsPermissions() {
      if (this.user.id > 0) {
        this.notificationSettingPermissionsOpen = true
      } else {
        alert(
          'Please complete user creation before adjusting individual notification settings permissions.'
        )
      }
    },
    refreshPermissions() {
      const self = this
      self.loading = true
      RoleUser.getUserRoles(this.user.id, res => {
        self.userPermissions = {}
        for (let i = 0; i < res.length; i++) {
          self.userPermissions[res[i].name] = res[i]
          // self.initialPermissions[res[i].name] = res[i]
        }
        self.loading = false
      })
    }
  },
  computed: {
    selectedTimeZone: {
      get() {
        if (this.user.timezone) {
          return {
            id: this.user.timezone,
            name: this.user.timezone
          }
        } else {
          return null
        }
      },
      set(val) {
        this.user.timezone = val.id
      }
    },
    timezones() {
      let timeZones = moment.tz.names()
      timeZones = this.moveToBeginningOfArray(timeZones, [
        'America/New_York',
        'America/Chicago',
        'America/Denver',
        'America/Phoenix',
        'America/Los_Angeles',
        'America/Anchorage',
        'America/Adak',
        'Pacific/Honolulu'
      ])

      const returnTimeZones = []
      for (let i = 0; i < timeZones.length; i++) {
        returnTimeZones.push({
          id: timeZones[i],
          name: timeZones[i]
        })
      }

      return returnTimeZones
    },
    passwordStrengthErrorMessage() {
      if (!this.user.password || this.user.password.length === 0) {
        return ''
      }
      const PasswordPolicy = require('password-sheriff').PasswordPolicy
      // Create a length password policy
      const charsets = require('password-sheriff').charsets

      const passwordPolicy = new PasswordPolicy({
        length: { minLength: 8 },
        contains: {
          expressions: [
            charsets.lowerCase,
            charsets.upperCase,
            charsets.numbers,
            charsets.specialCharacters
          ]
        }
      })

      if (passwordPolicy.check(this.user.password)) {
        return ''
      }

      return 'Password must be at least 8 characters long and include a lower case character (a-z), upper case character (A-Z), number (0-9), and special character (e.g. !@#$%^&*).'
    },
    newPasswordStrengthErrorMessage() {
      const PasswordPolicy = require('password-sheriff').PasswordPolicy
      // Create a length password policy
      const charsets = require('password-sheriff').charsets

      const passwordPolicy = new PasswordPolicy({
        length: { minLength: 8 },
        contains: {
          expressions: [
            charsets.lowerCase,
            charsets.upperCase,
            charsets.numbers,
            charsets.specialCharacters
          ]
        }
      })

      if (passwordPolicy.check(this.newPassword)) {
        return ''
      }

      return 'Password must be at least 8 characters long and include a lower case character (a-z), upper case character (A-Z), number (0-9), and special character (e.g. !@#$%^&*).'
    },
    currentAdditionalPrice() {
      let planToLookFor = ''
      let addOnToLookFor = ''
      if (this.createNagsLicense === 'yes') {
        for (let i = 0; i < this.nagsLicenseTypes.length; i++) {
          if (this.nagsLicenseTypes[i].id === this.nagsLicenseTypeId) {
            planToLookFor = this.nagsLicenseTypes[i].zoho_plan_code
            addOnToLookFor = this.nagsLicenseTypes[i].zoho_addon_code
          }
        }
      } else {
        planToLookFor = 'Repair'
        addOnToLookFor = 'Non_NAGS_User'
      }

      let individualPrice = 0
      if (
        this.subscriptionPlanDetails &&
        this.subscriptionPlanDetails.plan_code === planToLookFor
      ) {
        individualPrice = this.subscriptionPlanDetails.price
      } else {
        for (let i = 0; i < this.addOns.length; i++) {
          if (this.addOns[i].addon_code === addOnToLookFor) {
            individualPrice = this.addOns[i].price_brackets[0].price
          }
        }
      }
      return individualPrice
    },
    new_user_fee() {
      let newUserBillingDisplayAmount = this.formatCurrency(
        this.currentAdditionalPrice,
        false,
        true
      )
      if (
        this.subscriptionInterval === 1 &&
        this.subscriptionIntervalUnit === 'months'
      ) {
        newUserBillingDisplayAmount += '/Month'
      } else {
        newUserBillingDisplayAmount +=
          ' every ' +
          this.subscriptionInterval +
          ' ' +
          this.subscriptionIntervalUnit
      }
      return newUserBillingDisplayAmount
    },
    new_fee() {
      let newBillingDisplayAmount = this.formatCurrency(
        this.currentAdditionalPrice + this.subscriptionTotal,
        false,
        true
      )
      if (
        this.subscriptionInterval === 1 &&
        this.subscriptionIntervalUnit === 'months'
      ) {
        newBillingDisplayAmount += '/Month'
      } else {
        newBillingDisplayAmount +=
          ' every ' +
          this.subscriptionInterval +
          ' ' +
          this.subscriptionIntervalUnit
      }
      return newBillingDisplayAmount
    },
    accountManger: {
      get() {
        return this.user.account_manager === 1
      },
      set(val) {
        this.user.account_manager = val ? 1 : 0
      }
    },
    userFaxNumber: {
      get() {
        return this.user.faxnumber_id
      },
      set(val) {
        this.user.faxnumber_id = val
      }
    },
    spinnerTitle() {
      if (this.loading) {
        return 'Loading User Data...'
      } else if (this.saving) {
        return 'Saving User Data...'
      } else {
        return ''
      }
    },
    userColor: {
      get() {
        return this.user.color
      },
      set(val) {
        if (val.hex) {
          this.user.color = val.hex
        } else {
          this.user.color = val
        }
      }
    },
    emailVerified() {
      if (this.userVerifiedEmail === this.user.email) {
        if (this.user.email.length > 0) {
        }
      }

      return false
    },
    permissionCleanCheck() {
      // check permissions
      const tempCur = Object.keys(this.userPermissions)
      const tempIni = Object.keys(this.initialPermissions)
      if (tempCur.length !== tempIni.length) {
        return false
      }
      for (let i = 0; i < tempCur.length; i++) {
        let found = false
        for (let j = 0; j < tempIni.length; j++) {
          if (tempCur[i] === tempIni[j]) {
            found = true
          }
        }
        if (!found) return false
      }
      return true
    },
    faxCleanCheck() {
      // check fax numbers
      for (let i = 0; i < this.faxNumbers.length; i++) {
        if (this.faxNumbers[i].id < 0 || !this.faxNumbers[i].cleanCheck) {
          return false
        }
      }
      return true
    },
    userCleanCheck() {
      return this.user.cleanCheck
    },
    csrDefaultCleanCheck() {
      return R.equals(this.initialCSRDefaults, this.csrDefaults)
    },
    emailCleanCheck() {
      if (this.adminCheck() && this.user.email !== this.userVerifiedEmail) {
        return false
      }
      return true
    },
    emailValidation() {
      return testEmail(this.user.email)
    }
  },
  watch: {
    'user.username': {
      handler() {
        this.checkUsername()
      }
    },
    selectedCSRs(val) {
      // should always be the last but we need to handle deselects too
      if (this.loadingNewCSRs || this.loadingOldCSRs) {
        return
      }
      let foundInDefaults = false
      let shopId = -1
      let csrId = -1
      for (let i = 0; i < val.length; i++) {
        if (val[i].shopIdCombo && val[i].shopIdCombo.length > 0) {
          shopId = val[i].shopIdCombo.split('-')[0]
          csrId = val[i].shopIdCombo.split('-')[1]
        }
        foundInDefaults = false
        for (let j = 0; j < this.csrDefaults.length; j++) {
          if (val[i].is_csr) {
            if (
              this.csrDefaults[j].gb_csr_id === csrId &&
              this.csrDefaults[j].shop_id === shopId
            ) {
              foundInDefaults = true
            }
          } else {
            if (this.csrDefaults[j].csr_id === val[i].id) {
              foundInDefaults = true
            }
          }
        }
        if (!foundInDefaults) {
          if (val[i].is_csr) {
            this.csrDefaults.push(
              new CSRDefaults({
                id: -1,
                gb_csr_id: csrId,
                shop_id: shopId,
                user_id: this.user.id
              })
            )
          } else {
            this.csrDefaults.push(
              new CSRDefaults({
                id: -1,
                csr_id: val[i].id,
                shop_id: val[i].shop_id,
                user_id: this.user.id
              })
            )
          }
        }
      }
      let foundInSelected = false
      for (let k = 0; k < this.csrDefaults.length; k++) {
        foundInSelected = false
        for (let l = 0; l < val.length; l++) {
          if (val[l].shopIdCombo && val[l].shopIdCombo.length > 0) {
            shopId = val[l].shopIdCombo.split('-')[0]
            csrId = val[l].shopIdCombo.split('-')[1]
          }

          if (val[l].is_csr) {
            if (
              this.csrDefaults[k].gb_csr_id === csrId &&
              this.csrDefaults[k].shop_id === shopId
            ) {
              foundInSelected = true
            }
          } else {
            const idToCheck = this.csrDefaults[k].csr_id
            if (idToCheck === val[l].id) {
              foundInSelected = true
            }
          }
        }
        if (!foundInSelected) {
          this.csrDefaults.splice(k, 1)
          k--
        }
      }
    },
    attachedShops: async function(val) {
      if (val.length > 0) {
        for (let i = 0; i < val.length; i++) {
          if (val[i] instanceof Shop) {
            val.splice(i, 1, {
              id: val[i].id,
              name: val[i].name,
              shopUserId: -1
            })
          }
        }

        const gbCSRTempOrganizationIds = []
        const gbCSRTempShopArray = {}
        const csrOldTempShopIds = []
        const csrOldTempShopArray = {}
        this.csrs = []
        const self = this

        if (self.loadingNewCSRs || self.loadingOldCSRs) {
          return
        }
        self.loadingNewCSRs = true
        self.loadingOldCSRs = true
        // put all shopIds in a string so we can get csrs
        for (let l = 0; l < this.attachedShops.length; l++) {
          if (this.attachedShops[l]) {
            if (
              gbCSRTempOrganizationIds.indexOf(
                this.attachedShops[l].organization_id
              ) < 0
            ) {
              gbCSRTempOrganizationIds.push(
                this.attachedShops[l].organization_id
              )
            }
            gbCSRTempShopArray[this.attachedShops[l].id.toString()] = {}
            gbCSRTempShopArray[
              this.attachedShops[l].id.toString()
            ].name = this.attachedShops[l].name
            gbCSRTempShopArray[
              this.attachedShops[l].id.toString()
            ].organization_id = this.attachedShops[l].organization_id
            gbCSRTempShopArray[this.attachedShops[l].id.toString()].csrs = []
          } else {
            console.log('error in l', l)
          }
        }

        self.csrs = []
        if (gbCSRTempOrganizationIds.length > 0) {
          const data = await self.$unum
            .vendor()
            .get(`organization/gb_csr/${gbCSRTempOrganizationIds.toString()}`)

          for (let i = 0; i < data.data.length; i++) {
            // let's go through and build grouped list for the csrs
            for (const shopKey in gbCSRTempShopArray) {
              if (
                gbCSRTempShopArray[shopKey].organization_id ===
                data.data[i].organization_id
              ) {
                data.data[i].shopIdCombo = shopKey + '-' + data.data[i].id
                gbCSRTempShopArray[shopKey].csrs.push(R.clone(data.data[i]))
              }
            }
          }

          // now that we have all these built we can put into an array
          const tempKeys = Object.keys(gbCSRTempShopArray)
          for (let j = 0; j < tempKeys.length; j++) {
            self.csrs.push(gbCSRTempShopArray[tempKeys[j]])
          }

          // we'll go here to turn off the loading
          self.loading = false
          self.loadingNewCSRs = false
        } else {
          self.loadingNewCSRs = false
        }

        if (csrOldTempShopIds.length > 0) {
          // we should refresh the csrs we can choose for the default csr dropdown
          CSR.getAll(csrOldTempShopIds.toString(), res => {
            // let's go through and build grouped list for the csrs
            for (let i = 0; i < res.length; i++) {
              // for (let k = 0; k < self.csrDefaults.length; k++) {
              //   if (self.csrDefaults[k].csr_id === res[i].id) {
              //     self.selectedCSRs.push(res[i])
              //   }
              // }
              csrOldTempShopArray[res[i].shop_id.toString()].csrs.push(res[i])
            }
            // now that we have all these built we can put into an array
            const tempKeys = Object.keys(csrOldTempShopArray)
            for (let j = 0; j < tempKeys.length; j++) {
              self.csrs.push(csrOldTempShopArray[tempKeys[j]])
            }

            // we'll go here to turn off the loading
            self.loading = false
            self.loadingOldCSRs = false
          })
        } else {
          self.loadingOldCSRs = false
        }

        if (
          csrOldTempShopIds.length === 0 &&
          gbCSRTempOrganizationIds.length === 0
        ) {
          self.loading = false
        }
      }
    },
    faxNumbers(val) {
      if (
        (this.user.faxnumber_id === null || this.user.faxnumber_id !== '') &&
        val.length > 0
      ) {
        this.user.faxnumber_id = val[0].id
      }
    }
  },
  components: {
    Sketch,
    MultiSelectDropdown,
    MultiSelectWithGroupDropdown,
    DatePickerShortcut,
    FloatInput,
    InputWithDelete,
    CheckBoxLabel,
    ReportPermissionsDialog,
    DataTabPermissionsDialog,
    NotificationSettingPermissionsDialog,
    UserDialogShopTransfer
  },
  mixins: [UtilityMixin]
}
</script>

<style>
.usersAdminDialog > div {
  margin-top: 100px !important;
}
</style>

<style scoped>
.isYes {
  color: green;
}

.isNo {
  color: red;
}

input.noBorder {
  background-color: transparent;
  border: 0px solid;
  width: 90%;
  margin-left: 5px;
  color: #999999;
}

.nags-license-badge {
  text-align: center;
  border-radius: 10px;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
