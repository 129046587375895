var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      class: { isMobileNavBar: _vm.isMobile, topMenuNavBar: !_vm.isMobile },
      attrs: { toggleable: "lg", type: "dark", variant: "dark" }
    },
    [
      _c(
        "div",
        { staticStyle: { height: "0px", width: "0px", overflow: "hidden" } },
        [
          _c("input", { attrs: { name: "autofillEmailBlocker" } }),
          _c("input", {
            attrs: { name: "autofillPasswordBlocker", type: "password" }
          })
        ]
      ),
      _c(
        "b-navbar-brand",
        {
          staticStyle: { cursor: "pointer" },
          attrs: { tag: "a" },
          on: {
            click: function($event) {
              return _vm.changeRouteWithCheck("/")
            }
          }
        },
        [
          !_vm.isMobile
            ? _c(
                "div",
                {
                  staticClass: "newVersionComing",
                  on: {
                    click: function() {
                      return (_vm.showNewVersionDialog = true)
                    }
                  }
                },
                [_vm._v(" New Version ")]
              )
            : _vm._e(),
          _vm.isMobile
            ? _c(
                "div",
                {
                  staticClass: "newVersionComing-sm",
                  on: {
                    click: function() {
                      return (_vm.showNewVersionDialog = true)
                    }
                  }
                },
                [_vm._v(" New Version ")]
              )
            : _vm._e()
        ]
      ),
      _vm.showLoading
        ? _c("v-icon", {
            staticStyle: { color: "white" },
            attrs: { name: "spinner", spin: "", scale: "1.5" }
          })
        : _vm._e(),
      _c("b-navbar-toggle", {
        staticStyle: { "margin-left": "auto" },
        attrs: {
          id: "navBurger",
          target: "navbarCollapse",
          "data-toggle": "collapse",
          "data-target": "#navbarCollapse"
        }
      }),
      _c(
        "b-collapse",
        {
          staticClass: "top-menu-options",
          attrs: { "is-nav": "", id: "navbarCollapse" }
        },
        [
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto", staticStyle: { "margin-right": "0px" } },
            [
              _vm.isMobile &&
              !_vm.localPermissionCheck(
                _vm.permissionEnum.TECHCOMMISSION,
                _vm.$store.state
              )
                ? _c(
                    "li",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "nav-item nav-search mx-auto",
                              attrs: { cols: "6" }
                            },
                            [
                              _vm.localPermissionCheck(
                                _vm.permissionEnum.JOB,
                                _vm.$store.state
                              )
                                ? _c("GlobalSearch", {
                                    on: { selected: _vm.selectedJob }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.localPermissionCheck(
                            _vm.permissionEnum.JOB,
                            _vm.$store.state
                          )
                            ? _c(
                                "b-col",
                                {
                                  staticClass: "nav-item nav-search mx-auto",
                                  attrs: { cols: "3" }
                                },
                                [
                                  _c("JobNav", {
                                    attrs: { filter: _vm.newSearchFilter },
                                    on: { toggleNavSearch: _vm.toggleNavSearch }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.localPermissionCheck(
                            _vm.permissionEnum.JOB,
                            _vm.$store.state
                          )
                            ? _c(
                                "b-col",
                                {
                                  staticClass: "nav-item mx-auto my-auto",
                                  attrs: { cols: "2" }
                                },
                                [
                                  _c(
                                    "b-dropdown",
                                    {
                                      attrs: {
                                        size: "sm",
                                        "toggle-class": "text-decoration-none",
                                        "no-caret": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "button-content" },
                                        [
                                          !_vm.navSearching
                                            ? _c("i", {
                                                staticClass: "fa fa-caret-down"
                                              })
                                            : _vm._e(),
                                          _vm.navSearching
                                            ? _c("loader")
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            { staticClass: "sr-only" },
                                            [_vm._v("Search")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          staticClass: "ignore-click",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              return _vm.updateNewSearchFilter(
                                                "JOB #"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("JOB #")]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          staticClass: "ignore-click",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              return _vm.updateNewSearchFilter(
                                                "VIN #"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("VIN # (6 Char Min)")]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          staticClass: "ignore-click",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              return _vm.updateNewSearchFilter(
                                                "REF #"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("REFERRAL #")]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          staticClass: "ignore-click",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              return _vm.updateNewSearchFilter(
                                                "RO #"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("RO #")]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          staticClass: "ignore-click",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              return _vm.updateNewSearchFilter(
                                                "PO #"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("PO #")]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          staticClass: "ignore-click",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              return _vm.updateNewSearchFilter(
                                                "ADH #"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("ADH #/STOCK #")]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          staticClass: "ignore-click",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              return _vm.updateNewSearchFilter(
                                                "PLATE #"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("PLATE #")]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          staticClass: "ignore-click",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              return _vm.updateNewSearchFilter(
                                                "UNIT #"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("UNIT #")]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          staticClass: "ignore-click",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              return _vm.updateNewSearchFilter(
                                                "VEHICLE"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("VEHICLE")]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          staticClass: "ignore-click",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              return _vm.updateNewSearchFilter(
                                                "MOBILE #"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("MOBILE #")]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          staticClass: "ignore-click",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              return _vm.openDraftsDialog()
                                            }
                                          }
                                        },
                                        [_vm._v("Drafts")]
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.localPermissionCheck(
                _vm.permissionEnum.JOB,
                _vm.$store.state
              ) && !_vm.localPermissionCheck(_vm.permissionEnum.JOBVIEWONLY)
                ? _c(
                    "b-nav-item",
                    {
                      staticClass: "ignore-click toggle-collapse",
                      attrs: { id: "newJobNav", href: "/jobs/new", right: "" },
                      on: {
                        click: function($event) {
                          return _vm.changeWithPermissionCheck(
                            _vm.permissionEnum.JOB,
                            "/jobs/new"
                          )
                        }
                      }
                    },
                    [_vm._v("New Job")]
                  )
                : _vm._e(),
              _vm.localPermissionCheck(_vm.permissionEnum.JOB, _vm.$store.state)
                ? _c(
                    "b-popover",
                    {
                      attrs: {
                        triggers: "hover",
                        delay: { show: 400, hide: 2000 },
                        placement: "bottom",
                        target: "newJobNav"
                      }
                    },
                    [
                      _c("div", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              id: "newTabLink",
                              target: "_blank",
                              href: "/jobs/new"
                            }
                          },
                          [_vm._v("Open in new tab")]
                        ),
                        _c("br"),
                        _c(
                          "a",
                          {
                            attrs: {
                              id: "newTabLink",
                              target: "",
                              href: "/jobs/creditmemo/none/none"
                            }
                          },
                          [_vm._v("Create Credit Memo")]
                        ),
                        _c("br")
                      ])
                    ]
                  )
                : _vm._e(),
              !_vm.localPermissionCheck(
                _vm.permissionEnum.TECHCOMMISSION,
                _vm.$store.state
              )
                ? _c(
                    "b-nav-item",
                    {
                      staticClass: "ignore-click toggle-collapse",
                      attrs: { href: "/jobs", right: "" },
                      on: {
                        click: function($event) {
                          false
                          _vm.changeWithPermissionCheck(
                            _vm.permissionEnum.JOBMANAGER,
                            "/jobs"
                          )
                        }
                      }
                    },
                    [_vm._v("Jobs")]
                  )
                : _vm._e(),
              !_vm.localPermissionCheck(
                _vm.permissionEnum.TECHCOMMISSION,
                _vm.$store.state
              )
                ? _c(
                    "b-nav-item-dropdown",
                    { attrs: { text: "Manage" } },
                    [
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "ignore-click toggle-collapse",
                          attrs: { href: "/manage?tab=4" },
                          on: {
                            click: function($event) {
                              return _vm.changeWithPermissionCheck(
                                _vm.permissionEnum.MANAGECSRS,
                                "/manage?tab=4"
                              )
                            }
                          }
                        },
                        [_vm._v("CSRS")]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "ignore-click toggle-collapse",
                          attrs: { href: "/manage?tab=5" },
                          on: {
                            click: function($event) {
                              return _vm.changeWithPermissionCheck(
                                _vm.permissionEnum.MANAGESALESREPS,
                                "/manage?tab=5"
                              )
                            }
                          }
                        },
                        [_vm._v("Sales Reps")]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "ignore-click toggle-collapse",
                          attrs: { href: "/manage?tab=6" },
                          on: {
                            click: function($event) {
                              return _vm.changeWithPermissionCheck(
                                _vm.permissionEnum.MANAGETECHS,
                                "/manage?tab=6"
                              )
                            }
                          }
                        },
                        [_vm._v("Techs")]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "ignore-click toggle-collapse",
                          attrs: { href: "/manage?tab=7" },
                          on: {
                            click: function($event) {
                              return _vm.changeWithPermissionCheck(
                                _vm.permissionEnum.MANAGESALESSOURCES,
                                "/manage?tab=7"
                              )
                            }
                          }
                        },
                        [_vm._v("Sale Sources")]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "ignore-click toggle-collapse",
                          attrs: { href: "/manage?tab=8" },
                          on: {
                            click: function($event) {
                              return _vm.changeWithPermissionCheck(
                                _vm.permissionEnum.MANAGELOCATIONS,
                                "/manage?tab=8"
                              )
                            }
                          }
                        },
                        [_vm._v("Locations")]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "ignore-click toggle-collapse",
                          attrs: { href: "/manage?tab=9" },
                          on: {
                            click: function($event) {
                              return _vm.changeWithPermissionCheck(
                                _vm.permissionEnum.MANAGEINSURANCE,
                                "/manage?tab=9"
                              )
                            }
                          }
                        },
                        [_vm._v("Insurance/Fleet")]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "ignore-click toggle-collapse",
                          attrs: { href: "/manage?tab=10" },
                          on: {
                            click: function($event) {
                              return _vm.changeWithPermissionCheck(
                                _vm.permissionEnum.MANAGECOMMERCIALACCOUNTS,
                                "/manage?tab=10"
                              )
                            }
                          }
                        },
                        [_vm._v("Comm. Accts")]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "ignore-click toggle-collapse",
                          attrs: { href: "/manage?tab=11" },
                          on: {
                            click: function($event) {
                              return _vm.changeWithPermissionCheck(
                                _vm.permissionEnum.MANAGETAXES,
                                "/manage?tab=11"
                              )
                            }
                          }
                        },
                        [_vm._v("Taxes")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.localPermissionCheck(
                _vm.permissionEnum.ARAP,
                _vm.$store.state
              )
                ? _c(
                    "b-nav-item",
                    {
                      staticClass: "ignore-click toggle-collapse",
                      attrs: { href: "/ar", right: "" },
                      on: {
                        click: function($event) {
                          return _vm.changeWithPermissionCheck(
                            _vm.permissionEnum.ARAP,
                            "/ar"
                          )
                        }
                      }
                    },
                    [_vm._v("AR/AP")]
                  )
                : _vm._e(),
              !_vm.localPermissionCheck(
                _vm.permissionEnum.TECHCOMMISSION,
                _vm.$store.state
              )
                ? _c(
                    "b-nav-item",
                    {
                      staticClass: "ignore-click toggle-collapse",
                      attrs: { href: "/reports", right: "" },
                      on: {
                        click: function($event) {
                          return _vm.changeWithPermissionCheck(
                            _vm.permissionEnum.REPORTS,
                            "/reports"
                          )
                        }
                      }
                    },
                    [_vm._v("Reports")]
                  )
                : _vm._e(),
              _vm.roleCheck("account_manager")
                ? _c(
                    "b-nav-item-dropdown",
                    { attrs: { text: "Admin" } },
                    [
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "ignore-click toggle-collapse",
                          attrs: { href: "/admin?tab=14" },
                          on: {
                            click: function($event) {
                              return _vm.changeWithPermissionCheck(
                                _vm.permissionEnum.ACCOUNTS,
                                "/admin?tab=14"
                              )
                            }
                          }
                        },
                        [_vm._v("Accounts")]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "ignore-click toggle-collapse",
                          attrs: { href: "/admin?tab=16" },
                          on: {
                            click: function($event) {
                              return _vm.changeWithPermissionCheck(
                                _vm.permissionEnum.USERS,
                                "/admin?tab=16"
                              )
                            }
                          }
                        },
                        [_vm._v("Users")]
                      ),
                      _vm.roleCheck("admin")
                        ? _c(
                            "b-dropdown-item",
                            {
                              staticClass: "ignore-click toggle-collapse",
                              attrs: { href: "/admin?tab=17" },
                              on: {
                                click: function($event) {
                                  return _vm.changeRouteWithCheck(
                                    "/admin?tab=17"
                                  )
                                }
                              }
                            },
                            [_vm._v("TechSide Users")]
                          )
                        : _vm._e(),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "ignore-click toggle-collapse",
                          attrs: { href: "/admin?tab=15" },
                          on: {
                            click: function($event) {
                              return _vm.changeWithPermissionCheck(
                                _vm.permissionEnum.SHOPS,
                                "/admin?tab=15"
                              )
                            }
                          }
                        },
                        [_vm._v("Shops")]
                      ),
                      _vm.roleCheck("admin")
                        ? _c(
                            "b-dropdown-item",
                            {
                              staticClass: "ignore-click toggle-collapse",
                              attrs: { href: "/admin?tab=24" },
                              on: {
                                click: function($event) {
                                  return _vm.changeWithPermissionCheck(
                                    _vm.permissionEnum.ACCOUNTINGPACKAGES,
                                    "/admin?tab=24"
                                  )
                                }
                              }
                            },
                            [_vm._v("Accounting Packages")]
                          )
                        : _vm._e(),
                      _vm.roleCheck("admin")
                        ? _c(
                            "b-dropdown-item",
                            {
                              staticClass: "ignore-click toggle-collapse",
                              attrs: { href: "/admin?tab=25" },
                              on: {
                                click: function($event) {
                                  return _vm.changeRouteWithCheck(
                                    "/admin?tab=25"
                                  )
                                }
                              }
                            },
                            [_vm._v("Organizations")]
                          )
                        : _vm._e(),
                      _vm.roleCheck("admin")
                        ? _c(
                            "b-dropdown-item",
                            {
                              staticClass: "ignore-click toggle-collapse",
                              attrs: { href: "/admin?tab=23" },
                              on: {
                                click: function($event) {
                                  return _vm.changeRouteWithCheck(
                                    "/admin?tab=23"
                                  )
                                }
                              }
                            },
                            [_vm._v("NAGS Licenses")]
                          )
                        : _vm._e(),
                      _vm.roleCheck("admin")
                        ? _c(
                            "b-dropdown-item",
                            {
                              staticClass: "ignore-click toggle-collapse",
                              attrs: { href: "/fundingCenter", right: "" },
                              on: {
                                click: function($event) {
                                  return _vm.changeRouteWithCheck(
                                    "/fundingCenter"
                                  )
                                }
                              }
                            },
                            [_vm._v("Funding Center")]
                          )
                        : _vm._e(),
                      _vm.roleCheck("admin")
                        ? _c(
                            "b-dropdown-item",
                            {
                              staticClass: "ignore-click toggle-collapse",
                              attrs: { href: "/shopAnalytics", right: "" },
                              on: {
                                click: function($event) {
                                  return _vm.changeRouteWithCheck(
                                    "/shopAnalytics"
                                  )
                                }
                              }
                            },
                            [_vm._v("Shop Analytics")]
                          )
                        : _vm._e(),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "ignore-click toggle-collapse",
                          on: { click: _vm.openUserAuditDialog }
                        },
                        [_vm._v("User Audit")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.localPermissionCheck(
                _vm.permissionEnum.WAREHOUSE,
                _vm.$store.state
              )
                ? _c(
                    "b-nav-item",
                    {
                      staticClass: "ignore-click toggle-collapse",
                      attrs: { href: "/warehouse", right: "" },
                      on: {
                        click: function($event) {
                          return _vm.changeWithPermissionCheck(
                            _vm.permissionEnum.WAREHOUSE,
                            "/warehouse"
                          )
                        }
                      }
                    },
                    [_vm._v("Warehouse")]
                  )
                : _vm._e(),
              _vm.localPermissionCheck(
                _vm.permissionEnum.JOBMANAGER,
                _vm.$store.state
              )
                ? _c(
                    "b-nav-item",
                    {
                      staticClass: "ignore-click toggle-collapse",
                      attrs: { href: "/activity", right: "" },
                      on: {
                        click: function($event) {
                          return _vm.changeWithPermissionCheck(
                            _vm.permissionEnum.JOBMANAGER,
                            "/activity"
                          )
                        }
                      }
                    },
                    [_vm._v("TS Activity")]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "t-flex t-items-center t-justify-center t-ml-3 t-mr-5",
                  staticStyle: { position: "relative !important" },
                  on: { click: _vm.openShoppingCart }
                },
                [
                  _vm.cartItemsCount > 0
                    ? _c(
                        "span",
                        {
                          staticClass: "t-flex t-items-center t-justify-center",
                          attrs: { id: "cart-items-count" }
                        },
                        [_vm._v(" " + _vm._s(_vm.cartItemsCount) + " ")]
                      )
                    : _vm._e(),
                  _c("i", {
                    staticClass:
                      "el-icon-shopping-cart-1 t-text-xl t-bg-white t-p-2 t-rounded t-cursor-pointer hover:t-bg-gray-300"
                  })
                ]
              ),
              _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "dropdown-account",
                  attrs: {
                    text:
                      _vm.$store.state.user != null
                        ? _vm.$store.state.user.username +
                          (!_vm.isNagsUser ? " (Non-NAGS)" : " (NAGS)")
                        : "",
                    right: ""
                  }
                },
                [
                  _vm.roleCheck("account_manager")
                    ? _c(
                        "b-dropdown-item",
                        {
                          staticClass: "ignore-click toggle-collapse",
                          on: { click: _vm.openUserDialog }
                        },
                        [_vm._v("Settings")]
                      )
                    : _vm._e(),
                  _vm.$store.state.user.account_manager === 1
                    ? _c(
                        "b-dropdown-item",
                        {
                          staticClass: "ignore-click toggle-collapse",
                          on: { click: _vm.openBillingDetails }
                        },
                        [_vm._v("Billing Details")]
                      )
                    : _vm._e(),
                  _c(
                    "b-dropdown-item",
                    { attrs: { id: "column-width-toggler" } },
                    [_vm._v("Wider Panels")]
                  ),
                  _c(
                    "b-dropdown-item",
                    { attrs: { id: "column-height-toggler" } },
                    [_vm._v("Collapse Panels")]
                  ),
                  _vm.allowQuickBooks &&
                  !_vm.localPermissionCheck(_vm.permissionEnum.TECHCOMMISSION)
                    ? _c(
                        "b-dropdown-item",
                        {
                          staticClass: "ignore-click toggle-collapse",
                          on: { click: _vm.callQuickBooksDialog }
                        },
                        [_vm._v("QuickBooks")]
                      )
                    : _vm._e(),
                  _c(
                    "b-dropdown-item",
                    {
                      staticStyle: { display: "none" },
                      attrs: { id: "accordion-totals-toggler" }
                    },
                    [_vm._v("Totals")]
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      staticClass: "ignore-click toggle-collapse",
                      attrs: { href: "/tutorials" },
                      on: {
                        click: function($event) {
                          return _vm.changeRouteWithCheck("/tutorials")
                        }
                      }
                    },
                    [_vm._v("Help Videos")]
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      staticClass: "ignore-click toggle-collapse",
                      on: { click: _vm.callDashboardDialog }
                    },
                    [_vm._v("Dashboard")]
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      staticClass: "ignore-click toggle-collapse",
                      on: { click: _vm.joinMeeting }
                    },
                    [_vm._v("Join Meeting")]
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      staticClass: "ignore-click toggle-collapse",
                      on: { click: _vm.logoutUser }
                    },
                    [_vm._v("Logout")]
                  )
                ],
                1
              ),
              !_vm.isMobile &&
              _vm.localPermissionCheck(
                _vm.permissionEnum.JOB,
                _vm.$store.state
              ) &&
              !_vm.localPermissionCheck(
                _vm.permissionEnum.TECHCOMMISSION,
                _vm.$store.state
              )
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item nav-search",
                      attrs: { id: "globalSearch" }
                    },
                    [_c("GlobalSearch", { on: { selected: _vm.selectedJob } })],
                    1
                  )
                : _vm._e(),
              !_vm.isMobile &&
              _vm.localPermissionCheck(
                _vm.permissionEnum.JOB,
                _vm.$store.state
              ) &&
              !_vm.localPermissionCheck(
                _vm.permissionEnum.TECHCOMMISSION,
                _vm.$store.state
              )
                ? _c(
                    "li",
                    { staticClass: "nav-item nav-search" },
                    [
                      _c("JobNav", {
                        attrs: { filter: _vm.newSearchFilter },
                        on: { toggleNavSearch: _vm.toggleNavSearch }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isMobile &&
              _vm.localPermissionCheck(
                _vm.permissionEnum.JOB,
                _vm.$store.state
              ) &&
              !_vm.localPermissionCheck(
                _vm.permissionEnum.TECHCOMMISSION,
                _vm.$store.state
              )
                ? _c(
                    "b-nav-item",
                    { attrs: { right: "", id: "newSearchFilterButton" } },
                    [
                      _c(
                        "b-dropdown",
                        {
                          attrs: {
                            size: "sm",
                            "toggle-class": "text-decoration-none",
                            right: "",
                            "no-caret": ""
                          }
                        },
                        [
                          _c(
                            "template",
                            { slot: "button-content" },
                            [
                              !_vm.navSearching
                                ? _c("i", { staticClass: "fa fa-caret-down" })
                                : _vm._e(),
                              _vm.navSearching ? _c("loader") : _vm._e(),
                              _c("span", { staticClass: "sr-only" }, [
                                _vm._v("Search")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.updateNewSearchFilter("JOB #")
                                }
                              }
                            },
                            [_vm._v("JOB #")]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.updateNewSearchFilter("VIN #")
                                }
                              }
                            },
                            [_vm._v("VIN # (6 Char Min)")]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.updateNewSearchFilter("REF #")
                                }
                              }
                            },
                            [_vm._v("REFERRAL #")]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.updateNewSearchFilter("RO #")
                                }
                              }
                            },
                            [_vm._v("RO #")]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.updateNewSearchFilter("PO #")
                                }
                              }
                            },
                            [_vm._v("PO #")]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.updateNewSearchFilter("ADH #")
                                }
                              }
                            },
                            [_vm._v("ADH #/STOCK #")]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.updateNewSearchFilter("PLATE #")
                                }
                              }
                            },
                            [_vm._v("PLATE #")]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.updateNewSearchFilter("UNIT #")
                                }
                              }
                            },
                            [_vm._v("UNIT #")]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.updateNewSearchFilter("VEHICLE")
                                }
                              }
                            },
                            [_vm._v("VEHICLE")]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.updateNewSearchFilter("MOBILE #")
                                }
                              }
                            },
                            [_vm._v("MOBILE #")]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              staticClass: "ignore-click",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.openDraftsDialog()
                                }
                              }
                            },
                            [_vm._v("Drafts")]
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          !_vm.localPermissionCheck(
            _vm.permissionEnum.TECHCOMMISSION,
            _vm.$store.state
          )
            ? _c("SideBar", { attrs: { location: "top" } })
            : _vm._e()
        ],
        1
      ),
      _c("GlaxisDialog"),
      _c("GlaxisClaimDialog"),
      _c("GlaxisReviewCoverageDialog"),
      _c("DashboardDialog"),
      _vm.allowQuickBooks ? _c("QuickBooksDialog") : _vm._e(),
      _c("TextMessagesDialog"),
      _c("TextMessageTemplatesDialog"),
      _c("TextMessageEmailTemplatesDialog"),
      _c("TextMessageGbFormTemplatesDialog"),
      _vm.showConfirmationDialog
        ? _c("TextMessageConfirmationDialog")
        : _vm._e(),
      _c("TextMessageInvoiceDialog"),
      _c("TextMessagePaymentDialog", {
        attrs: { filePickerKey: _vm.filePickerKey }
      }),
      _c("TextMessageSignatureDialog"),
      _c("adDialog"),
      _c("TextMessageTssInviteDialog"),
      _c("SalesideJobDialog", {
        attrs: {
          visible: _vm.salesideJobDialogVisible,
          mobileJobId: _vm.mobileJobNumber,
          shouldOpenMobileInbox: _vm.shouldOpenMobileInbox,
          title: "Unsent Job"
        },
        on: {
          close: function($event) {
            _vm.salesideJobDialogVisible = false
          }
        }
      }),
      _c("BillingDetailsDialog", {
        attrs: {
          accountId: _vm.$store.state.user.account_id,
          modalId: "userBillingDetailsDialog"
        }
      }),
      _c("ShoppingCartDialog"),
      _c("NotificationsDialog"),
      _c("NotificationsSettingsDialog"),
      _c("UserDialog", {
        attrs: { modalName: "userSettingDialog", isExpanded: true }
      }),
      _c("UserAuditDialog", {
        attrs: { visible: _vm.userAuditLogVisible },
        on: {
          "update:visible": function($event) {
            _vm.userAuditLogVisible = $event
          }
        }
      }),
      _c("new-version-dialog", {
        attrs: { visible: _vm.showNewVersionDialog },
        on: {
          "update:visible": function($event) {
            _vm.showNewVersionDialog = $event
          }
        }
      }),
      _c("DraftsDialog", {
        attrs: { visible: _vm.draftsDialogVisible },
        on: {
          "update:visible": function($event) {
            _vm.draftsDialogVisible = $event
          }
        }
      }),
      _c("AdminUtilsDialog", {
        attrs: {
          visible: _vm.adminUtilsOpen,
          filePickerKey: _vm.filePickerKey
        },
        on: {
          "update:visible": function($event) {
            _vm.adminUtilsOpen = $event
          }
        }
      }),
      _c("Chat"),
      _c("PurchaseOrderDialog"),
      _c("ReceiveDialog"),
      _c("TechPickUpDialog"),
      _c("ReturnDialog"),
      _c("GenericEmailDialog", { attrs: { filePickerKey: _vm.filePickerKey } }),
      _c("GenericSmsDialog", { attrs: { filePickerKey: _vm.filePickerKey } }),
      _c("support-center", { attrs: { filePickerKey: _vm.filePickerKey } }),
      _c("VendorProfileDialog"),
      _c("QuickBooksAccountMappingDialog"),
      _c("QuickBooksSyncDialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }